"use client"

import { useState } from "react"

interface AlertVerificationModalProps {
  type: "email" | "mobile"
  userId: string | number
  onVerify: () => void
  onSkip: () => void
  onClose: () => void
  error?: string | null
}

export default function AlertVerificationModal({
  type,
  userId,
  onVerify,
  onSkip,
  onClose,
  error,
}: AlertVerificationModalProps) {
  const [verificationCode, setVerificationCode] = useState("")
  const [isVerifying, setIsVerifying] = useState(false)

  const handleVerifyClick = async () => {
    setIsVerifying(true)
    // Simulate verification process
    setTimeout(() => {
      setIsVerifying(false)
      onVerify()
    }, 1000)
  }

  return (
    <div className="fixed inset-0 bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4 shadow-xl">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold">Verify {type === "email" ? "Email" : "Mobile"}</h3>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700 transition-colors">
            <span className="text-xl">&times;</span>
          </button>
        </div>

        {error && (
          <div className="bg-red-50 p-3 rounded-lg mb-4">
            <p className="text-red-600 text-sm">⚠️ {error}</p>
          </div>
        )}

        <p className="text-gray-600 mb-4">
          Please enter the verification code sent to your {type === "email" ? "email" : "mobile"} to continue.
        </p>

        <div className="mb-4">
          <label htmlFor="verificationCode" className="block text-gray-700 text-sm font-bold mb-2">
            Verification Code
          </label>
          <input
            type="text"
            id="verificationCode"
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Enter code"
          />
        </div>

        <div className="flex justify-end space-x-2">
          <button
            onClick={onSkip}
            className="bg-gray-200 hover:bg-gray-300 text-gray-700 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Skip
          </button>
          <button
            onClick={handleVerifyClick}
            disabled={isVerifying}
            className={`bg-gradient-to-b from-[#BF1A1C] to-[#590C0D] hover:opacity-90 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${
              isVerifying ? "opacity-50 cursor-not-allowed" : ""
            }`}
          >
            {isVerifying ? "Verifying..." : "Verify"}
          </button>
        </div>
      </div>
    </div>
  )
}

