import React, { createContext, useContext, useState, useEffect, useCallback } from "react";
import Api from "../pages/loginSignin/Api";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [isPartner, setIsPartner] = useState(false);
  const [loading, setLoading] = useState(true);

  // Logout function
  const logout = useCallback(() => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("user_data");
    localStorage.removeItem("user_details");

    sessionStorage.clear();
    setIsAuthenticated(false);
    setUser(null);
    setIsPartner(false);
  }, []);

  // Function to refresh the token
  const refreshToken = useCallback(async () => {
    try {
      const refresh = localStorage.getItem("refresh_token");
      if (!refresh) return logout();

      const response = await Api.post("token/refresh/", { refresh });

      if (response.data.access) {
        localStorage.setItem("access_token", response.data.access);
        return response.data.access;
      } else {
        logout();
      }
    } catch (error) {
      console.error("Token refresh failed:", error);
      logout();
    }
  }, [logout]);

  // Initial authentication check
  useEffect(() => {
    const initializeAuth = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("access_token");
        const userData = localStorage.getItem("user_data");

        if (token && userData) {
          const parsedUser = JSON.parse(userData);
          setIsAuthenticated(true);
          setUser(parsedUser);
          setIsPartner(parsedUser?.is_partner || false);

          // Set interval to refresh token periodically (every 14 minutes)
          const refreshInterval = setInterval(refreshToken, 14 * 60 * 1000);
          return () => clearInterval(refreshInterval);
        } else {
          logout();
        }
      } catch (error) {
        console.error("Auth initialization error:", error);
        logout();
      } finally {
        setLoading(false);
      }
    };

    initializeAuth();
  }, [logout, refreshToken]);

  // Login function
  const login = (userData, tokens) => {
    if (tokens?.access) {
      localStorage.setItem("access_token", tokens.access);
    }
    if (tokens?.refresh) {
      localStorage.setItem("refresh_token", tokens.refresh);
    }
    localStorage.setItem("user_data", JSON.stringify(userData));

    setIsAuthenticated(true);
    setUser(userData);
    setIsPartner(userData?.is_partner || false);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, isPartner, loading, login, logout }}>
      {!loading && children} {/* Prevent UI flicker while checking auth */}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
