import { useCallback } from "react";

const useNavigateToEditor = () => {
  const navigateToEditor = useCallback(() => {
    window.open("https://designer.brandexperts.ae/", "_self");
  }, []);

  return { navigateToEditor };
};

export default useNavigateToEditor;
