import bannerPostPng from "../../assets/images/banner-posts.png";
import bannerPostWebp from "../../assets/images/banner-postswe.webp";
import arrowPng from "../../assets/images/arrow.png";
import React, { useState, useEffect } from "react";
import {
  Images,
  MoveRight,
  Newspaper,
  ShoppingCart,
} from "lucide-react";
import { motion } from "framer-motion";
import GradientButton from "../../components/GradientButton";
import { useNavigate } from "react-router-dom";
import ComingSoonModal from "../../components/ComingSoonModal";
import API from "../loginSignin/Api";
import { useQuery } from "@tanstack/react-query";
import useNavigateToEditor from "../../hooks/useNavigateToEditor";
import "../../styles/style.scss";

const HeroSection = () => {
  const navigate = useNavigate();
  const [isComingSoonModalOpen, setIsComingSoonModalOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const { navigateToEditor } = useNavigateToEditor();

  // Fetch banner images from API
  const {
    data: banners,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["banners"],
    queryFn: async () => {
      const response = await API.get("/dash/banners/");
      return response.data;
    },
  });

  // Default images to use as fallback
  const defaultImages = [
    "https://www.picmaker.com/assets/images/postermaker/poster_maker_ogimage.png",
    "https://fiverr-res.cloudinary.com/images/t_main1,q_auto,f_auto,q_auto,f_auto/gigs/323265237/original/5158009026e4637bb8dc93fea90065c1f5775797/design-product-advertisement-poster-and-social-media-ads.png",
    "https://assets.designhill.com/design-blog/wp-content/uploads/2022/11/Top-5-Poster-Template-Designs-For-2025.jpg",
  ];

  // Images to display - either from API or fallbacks
  const imagesToShow =
    banners && banners.length > 0
      ? banners.map((banner) => banner.image_url)
      : defaultImages;

  // Set up the image rotation effect
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === imagesToShow.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000); // Change image every 5 seconds

    return () => clearInterval(interval);
  }, [imagesToShow.length]);

  return (
    <motion.div
      className=" flex items-center justify-center bg-[#fdf5f5] px-2  sm:px-8 overflow-hidden"
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      viewport={{ once: true, amount: 0.3 }} // Animates when 30% of the section is visible
    >
      <section className="flex flex-col-reverse lg:flex-row items-center justify-between lg:py-1 w-full mx-auto max-w-7xl xl:max-w-screen-2xl">
        {/* Left Content */}
        <div className="w-full lg:w-1/2 xl:max-w-full flex flex-col items-center lg:items-start space-y-8 mb-14  heroSectionOne">
          <h2 className="text-4xl sm:text-4xl lg:text-6xl font-bold text-center lg:text-left">
            Create, Enhance & Showcase Your <br className="hidden sm:block" />
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#BF1A1C] to-[#590C0D]">
              Brand!
            </span>
          </h2>
          <p className="text-xl text-gray-600 text-center lg:text-left">
            Your Vision, Crafted to Perfection
          </p>
          {/* Responsive Buttons */}
          <div className="w-68 sm:w-full flex flex-col sm:flex-row justify-center lg:justify-start gap-4">
            <GradientButton
              text="Choose Product"
              Icon={MoveRight}
              onClick={() => navigate("/products")}
              className="w-full sm:w-auto"
            />
            <button
              onClick={navigateToEditor}
              className="font-semibold py-3 px-4 border border-[#BF1A1C] rounded-lg text-lg flex items-center justify-center cursor-pointer w-full sm:w-auto hover:bg-gray-200 sm:hover:bg-transparent hover:scale-105 transition-all duration-300 ease-in-out"
            >
              Start New Design
            </button>
          </div>
          <p className="text-md text-gray-500 text-center lg:text-left max-w-md">
            Emphasizes customization and quality in delivering user-designed
            products.
          </p>
          {/* Icons Row with Arrows */}
          <div className="flex flex-wrap justify-center lg:justify-start gap-4 sm:gap-6 w-full items-center">
            {[
              { icon: Newspaper, text: "Pick templates" },
              { icon: Images, text: "Customize your signs" },
              { icon: ShoppingCart, text: "Order your product" },
            ].map((item, index, array) => (
              <React.Fragment key={index}>
                <div className="flex flex-col items-center">
                  <div className="w-14 h-14 sm:w-16 sm:h-16 border-2 border-[#BF1A1C] rounded-full flex items-center justify-center">
                    <item.icon size={28} className="text-[#BF1A1C]" />
                  </div>
                  <p className="text-xs sm:text-sm mt-2 text-center">
                    {item.text}
                  </p>
                </div>
                {/* Add arrow image between icons, but not after the last icon */}
                {index < array.length - 1 && (
                  <img
                    src={arrowPng}
                    alt="Arrow"
                    className="w-8 sm:w-12 hidden sm:block" // Hide arrow on small screens
                  />
                )}
              </React.Fragment>
            ))}
          </div>{" "}
        </div>

        {/* Right Billboard with Fixed Sliding Images */}
        <div className="xl:w-1/2 relative hidden xl:block ">
          <picture className="">
            <source srcSet={bannerPostWebp} type="image/webp" />
            <source srcSet={bannerPostPng} type="image/png" />
            <img
              src={bannerPostPng}
              className="w-full  pt-10 "
              alt="hero section banner Logo"
            />
          </picture>

          {/* Improved Sliding Content Container */}
          <div className="absolute top-[12.5%] left-[-0.7%] w-[87%] h-[40%] ">
            {/* Adding a perspective container */}
            <div
              className="relative w-full h-full"
              style={{
                perspective: "500px",
                transformStyle: "preserve-3d",
              }}
            >
              {/* Image container with adjusted transform */}
              <div
                className="w-full h-full"
                style={{
                  transform: "rotateX(3deg) rotateY(-8deg) skewY(-8deg)",
                  transformOrigin: "center center",
                }}
              >
                {/* Render all images but only show the current one */}
                {imagesToShow.map((imageUrl, index) => (
                  <motion.img
                    key={index}
                    src={imageUrl}
                    alt={`Slide ${index + 1}`}
                    className="w-full h-full object-cover absolute top-0 left-0"
                    initial={{ opacity: 0 }}
                    animate={{
                      opacity: index === currentImageIndex ? 1 : 0,
                    }}
                    transition={{
                      duration: 0.5, // Faster transition
                      ease: "easeInOut",
                    }}
                    style={{
                      backfaceVisibility: "hidden",
                      display: "block", // Always keep it in the DOM
                      zIndex: index === currentImageIndex ? 2 : 1,
                    }}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <ComingSoonModal
        isOpen={isComingSoonModalOpen}
        onClose={() => setIsComingSoonModalOpen(false)}
      />
    </motion.div>
  );
};

export default HeroSection;
