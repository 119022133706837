"use client"

import { useForm } from "react-hook-form"
import { useMutation } from "@tanstack/react-query"
import { toast } from "react-hot-toast"
import Api from "../../../pages/loginSignin/Api"
import { useState, useEffect } from "react"
import { submitCartToBackend } from "../../../services/cartApi"
import { useNavigate } from "react-router-dom"
import { DraftingCompass, ShoppingBag, CheckCircle } from "lucide-react"

const COUNTRIES = [
  { code: "AE", name: "United Arab Emirates" },
  { code: "OM", name: "Oman" },
  { code: "BH", name: "Bahrain" },
  { code: "QA", name: "Qatar" },
  { code: "KW", name: "Kuwait" },
  { code: "SA", name: "Saudi Arabia" },
]

export default function ShippingForm({ onNext, initialData, onSave }) {
  const navigate = useNavigate()
  const [cart, setCart] = useState(() => JSON.parse(sessionStorage.getItem("cart")) || { cart_items: [] })
  const [hasSiteVisit, setHasSiteVisit] = useState(false)
  const [siteVisitAmount, setSiteVisitAmount] = useState(0)
  const [isLoadingSiteVisit, setIsLoadingSiteVisit] = useState(false)
  const [validationErrors, setValidationErrors] = useState({})

  useEffect(() => {
    const customer_id = sessionStorage.getItem("customer_id")
    const access_token = localStorage.getItem("access_token")

    // Check if site_visit is true in session
    const cartData = JSON.parse(sessionStorage.getItem("cart") || "{}")
    const hasSiteVisitInCart = cartData.site_visit === true
    setHasSiteVisit(hasSiteVisitInCart)

    if (!customer_id || !access_token) {
      toast.error("Please login to continue")
      navigate("/login", { state: { from: "/checkout" } })
    }

    // Fetch site visit amount if site_visit is true
    if (hasSiteVisitInCart) {
      fetchSiteVisitAmount(access_token)
    }
  }, [navigate])

  // Function to fetch site visit amount
  const fetchSiteVisitAmount = async (token) => {
    setIsLoadingSiteVisit(true)
    try {
      const response = await Api.get("dash/site-visit/", {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })

      if (response.data && typeof response.data.amount === "number") {
        setSiteVisitAmount(response.data.amount)
      } else {
        console.error("Invalid site visit amount data:", response.data)
      }
    } catch (error) {
      console.error("Failed to fetch site visit amount:", error)
      toast.error("Failed to fetch site visit fee. Please refresh the page.")
    } finally {
      setIsLoadingSiteVisit(false)
    }
  }

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      country: "AE",
      ...initialData,
    },
  })

  // Custom validation function
  const validateForm = (data) => {
    const errors = {}

    if (!data.company_name || data.company_name.trim() === "") {
      errors.company_name = "Company name is required"
    }

    if (!data.address_line1 || data.address_line1.trim() === "") {
      errors.address_line1 = "Address is required"
    }

    if (!data.country || data.country.trim() === "") {
      errors.country = "Country is required"
    }

    if (!data.city || data.city.trim() === "" || data.city.length < 3) {
      errors.city = "City must be at least 3 characters"
    }

    if (!data.state || data.state.trim() === "" || data.state.length < 2) {
      errors.state = "State/Emirate must be at least 2 characters"
    }

    if (!data.zip_code || data.zip_code.trim() === "") {
      errors.zip_code = "ZIP code is required"
    }

    return errors
  }

  const createAddressMutation = useMutation({
    mutationFn: async (formData) => {
      const customer_id = sessionStorage.getItem("customer_id")
      const access_token = localStorage.getItem("access_token")

      if (!customer_id || !access_token) {
        toast.error("Please login to continue")
        navigate("/login", { state: { from: "/checkout" } })
        throw new Error("Authentication required")
      }

      try {
        const payload = {
          customer_id: Number.parseInt(customer_id),
          company_name: formData.company_name,
          ext: formData.ext || "",
          address_line1: formData.address_line1,
          address_line2: formData.address_line2 || "",
          country: formData.country,
          city: formData.city,
          state: formData.state,
          zip_code: formData.zip_code,
        }

        const response = await Api.post("create-customer-address/", payload, {
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json",
          },
        })

        return response.data
      } catch (error) {
        console.error("Address creation error:", error)
        if (error.response?.status === 401) {
          toast.error("Session expired. Please login again")
          navigate("/login", { state: { from: "/checkout" } })
        }
        throw new Error(error.response?.data?.error || "Failed to save address")
      }
    },
  })

  const handleFormSubmit = async (data) => {
    try {
      const customerId = sessionStorage.getItem("customer_id")
      const accessToken = localStorage.getItem("access_token")

      if (!customerId || !accessToken) {
        toast.error("Please login to continue")
        navigate("/login", { state: { from: "/checkout" } })
        return
      }

      // Custom form validation
      const validationErrors = validateForm(data)
      if (Object.keys(validationErrors).length > 0) {
        setValidationErrors(validationErrors)
        toast.error("Please check all required fields")
        return
      }

      // Clear validation errors if validation passes
      setValidationErrors({})

      // Store billing details with correct Stripe field names
      const billingDetails = {
        name: data.company_name,
        email: sessionStorage.getItem("user_email") || "",
        phone: data.ext || "",
        address: {
          line1: data.address_line1,
          line2: data.address_line2 || "",
          city: data.city,
          state: data.state,
          postal_code: data.zip_code,
          country: data.country,
        },
      }

      sessionStorage.setItem("billing_details", JSON.stringify(billingDetails))

      // Step 1: Save the address
      const toastId = toast.loading("Saving address...")

      try {
        const addressResult = await createAddressMutation.mutateAsync(data)

        if (!addressResult?.address_details) {
          throw new Error("Invalid address response")
        }

        // Step 2: Process the cart
        toast.loading("Processing cart...", { id: toastId })
        const cartResponse = await submitCartToBackend()

        if (!cartResponse?.success && !cartResponse?.message) {
          throw new Error("Cart processing failed")
        }

        toast.success("Address saved successfully", {
          id: toastId,
        })
        onSave(addressResult.address_details)
        navigate("/payment")
      } catch (error) {
        toast.error(error.message || "Operation failed", { id: toastId })
        console.error("Operation error:", error)
      }
    } catch (error) {
      console.error("Form submission error:", error)
      toast.error("An unexpected error occurred. Please try again.")
    }
  }

  // Calculate order summary
  const subtotal = cart.cart_items.reduce((sum, item) => sum + Number.parseFloat(item.total), 0).toFixed(2)

  // Calculate final total including site visit amount if applicable
  const finalTotal = (Number.parseFloat(subtotal) + (hasSiteVisit ? Number.parseFloat(siteVisitAmount) : 0)).toFixed(2)

  return (
    <div className="mt-8 mb-48 max-w-7xl mx-auto px-4">
      <div className="flex flex-col lg:flex-row gap-8">
        {/* Shipping Form Section */}
        <div className="lg:w-2/3">
          <form onSubmit={handleSubmit(handleFormSubmit)} className="bg-white p-8 rounded-lg shadow">
            <h2 className="text-2xl font-semibold mb-6">Shipping Address</h2>

            <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
              {/* Company Name */}
              <div className="sm:col-span-2">
                <label className="block text-sm font-medium text-gray-700">Company Name *</label>
                <input
                  type="text"
                  {...register("company_name")}
                  className="mt-1 block w-full px-4 py-3 border-gray-300 rounded-md shadow-sm focus:ring-red-500 focus:border-red-500"
                  placeholder="Enter company name"
                />
                {validationErrors.company_name && (
                  <p className="mt-1 text-sm text-red-600">{validationErrors.company_name}</p>
                )}
              </div>

              {/* Address Lines */}
              <div className="sm:col-span-2">
                <label className="block text-sm font-medium text-gray-700">Address Line 1 *</label>
                <input
                  type="text"
                  {...register("address_line1")}
                  className="mt-1 block w-full px-4 py-3 border-gray-300 rounded-md shadow-sm focus:ring-red-500 focus:border-red-500"
                  placeholder="Street address"
                />
                {validationErrors.address_line1 && (
                  <p className="mt-1 text-sm text-red-600">{validationErrors.address_line1}</p>
                )}
              </div>

              <div className="sm:col-span-2">
                <label className="block text-sm font-medium text-gray-700">Address Line 2</label>
                <input
                  type="text"
                  {...register("address_line2")}
                  className="mt-1 block w-full px-4 py-3 border-gray-300 rounded-md shadow-sm focus:ring-red-500 focus:border-red-500"
                  placeholder="Apartment, suite, unit, building, floor, etc."
                />
              </div>

              {/* Extension */}
              <div>
                <label className="block text-sm font-medium text-gray-700">Extension/Suite</label>
                <input
                  type="text"
                  {...register("ext")}
                  className="mt-1 block w-full px-4 py-3 border-gray-300 rounded-md shadow-sm focus:ring-red-500 focus:border-red-500"
                  placeholder="Suite number"
                />
              </div>

              {/* Country */}
              <div>
                <label className="block text-sm font-medium text-gray-700">Country *</label>
                <select
                  {...register("country")}
                  className="mt-1 block w-full px-4 py-3 border-gray-300 rounded-md shadow-sm focus:ring-red-500 focus:border-red-500"
                >
                  {COUNTRIES.map(({ code, name }) => (
                    <option key={code} value={code}>
                      {name}
                    </option>
                  ))}
                </select>
                {validationErrors.country && <p className="mt-1 text-sm text-red-600">{validationErrors.country}</p>}
              </div>

              {/* State/Emirate */}
              <div>
                <label className="block text-sm font-medium text-gray-700">State/Emirate *</label>
                <input
                  type="text"
                  {...register("state")}
                  className="mt-1 block w-full px-4 py-3 border-gray-300 rounded-md shadow-sm focus:ring-red-500 focus:border-red-500"
                  placeholder="Enter state or emirate"
                />
                {validationErrors.state && <p className="mt-1 text-sm text-red-600">{validationErrors.state}</p>}
              </div>

              {/* City */}
              <div>
                <label className="block text-sm font-medium text-gray-700">City *</label>
                <input
                  type="text"
                  {...register("city")}
                  className="mt-1 block w-full px-4 py-3 border-gray-300 rounded-md shadow-sm focus:ring-red-500 focus:border-red-500"
                  placeholder="Enter city name"
                />
                {validationErrors.city && <p className="mt-1 text-sm text-red-600">{validationErrors.city}</p>}
              </div>

              {/* ZIP Code */}
              <div>
                <label className="block text-sm font-medium text-gray-700">ZIP Code *</label>
                <input
                  type="text"
                  {...register("zip_code")}
                  className="mt-1 block w-full px-4 py-3 border-gray-300 rounded-md shadow-sm focus:ring-red-500 focus:border-red-500"
                  placeholder="Enter ZIP code"
                />
                {validationErrors.zip_code && <p className="mt-1 text-sm text-red-600">{validationErrors.zip_code}</p>}
              </div>

              {/* Submit Button */}
              <div className="sm:col-span-2 mt-6">
                <button
                  type="submit"
                  disabled={isSubmitting || createAddressMutation.isLoading || Object.keys(validationErrors).length > 0}
                  className={`w-full px-6 py-4 text-lg rounded-lg transition-all duration-300 
                  ${
                    Object.keys(validationErrors).length > 0
                      ? "bg-gray-400 cursor-not-allowed"
                      : "bg-gradient-to-b from-[#BF1A1C] to-[#590C0D] text-white hover:shadow-lg"
                  }`}
                >
                  {isSubmitting || createAddressMutation.isLoading ? (
                    <span className="flex items-center justify-center">
                      <svg
                        className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        />
                      </svg>
                      Processing...
                    </span>
                  ) : Object.keys(validationErrors).length > 0 ? (
                    "Please fill all required fields"
                  ) : (
                    "Save & Continue to Payment"
                  )}
                </button>

                {/* Enhanced Error Display */}
                {Object.keys(validationErrors).length > 0 && (
                  <div className="mt-4 p-4 bg-red-50 rounded-lg border border-red-100">
                    <p className="text-red-600 font-medium mb-2">Please correct the following errors:</p>
                    <ul className="list-disc list-inside text-sm text-red-500">
                      {Object.entries(validationErrors).map(([field, message]) => (
                        <li key={field}>{message}</li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </form>
        </div>

        {/* Order Summary Section */}
        <div className="lg:w-1/3">
          <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6 sticky top-4">
            <h3 className="text-xl font-bold mb-6">Order Summary</h3>

            {/* Product Items Preview */}
            <div className="max-h-64 overflow-y-auto mb-6">
              {cart.cart_items.map((item) => (
                <div key={item.timestamp} className="flex items-center gap-3 py-3 border-b border-gray-100">
                  <div className="relative w-16 h-16">
                    {item.designerFee > 0 ? (
                      <div className="w-16 h-16 bg-gray-300 rounded-lg flex items-center justify-center">
                        <DraftingCompass size={24} className="text-gray-600" />
                      </div>
                    ) : (
                      <img
                        src={item.design_image || "/placeholder.svg"}
                        alt="Product"
                        className="w-16 h-16 object-cover rounded-lg"
                      />
                    )}
                  </div>
                  <div className="flex-1">
                    <h4 className="font-medium text-sm">{item.name}</h4>
                    <p className="text-xs text-gray-500">
                      Qty: {item.quantity} ×
                      {(
                        (Number.parseFloat(item.total) - Number.parseFloat(item.designerFee || 0)) /
                        item.quantity
                      ).toFixed(2)}{" "}
                      AED
                    </p>
                    {item.designerFee > 0 && (
                      <p className="text-xs text-gray-500">Designer fee: {item.designerFee} AED</p>
                    )}
                  </div>
                  <div className="text-right">
                    <p className="font-bold text-sm">{item.total} AED</p>
                  </div>
                </div>
              ))}
            </div>

            {/* Summary Calculations */}
            <div className="space-y-3">
              <div className="flex justify-between text-gray-600">
                <span>Subtotal ({cart.cart_items.length} items)</span>
                <span className="font-medium">{subtotal} AED</span>
              </div>

              {/* Site Visit Fee - Only show if site_visit is true */}
              {hasSiteVisit && (
                <div className="flex justify-between text-gray-600">
                  <span className="flex items-center gap-1">
                    <CheckCircle size={16} className="text-green-600" />
                    Site Visit Fee
                  </span>
                  <span className="font-medium">
                    {isLoadingSiteVisit ? "Loading..." : `${siteVisitAmount.toFixed(2)} AED`}
                  </span>
                </div>
              )}

              <div className="flex justify-between text-gray-600">
                <span>Shipping</span>
                <span>Calculated at checkout</span>
              </div>
              <div className="border-t pt-4 mt-4">
                <div className="flex justify-between text-lg font-bold">
                  <span>Total</span>
                  <span>{finalTotal} AED</span>
                </div>
              </div>
            </div>

            <div className="pt-4 mt-4 text-sm text-gray-600">
              <p className="flex items-center gap-2">
                <ShoppingBag size={16} className="text-red-600" />
                Continue to payment to complete your order
              </p>
            </div>
          </div>

          <button
            onClick={() => navigate("/cart")}
            className="mt-4 flex items-center gap-2 text-gray-600 hover:text-red-600 transition-colors"
          >
            ← Return to cart
          </button>
        </div>
      </div>
    </div>
  )
}

