"use client";
import { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useProduct } from "../hooks/useProducts";
import { useMutation } from "@tanstack/react-query";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import ProductSelectionModal from "./ProductSelectionModal";
import errorImg from "../assets/images/error.svg";
import DesignModal from "./DesignModal";
import { ArrowRight, Ruler } from "lucide-react";
import Amazon from "../assets/images/amazon-button.png";
import API from "../pages/loginSignin/Api";
import { toast } from "react-toastify";
import toolTipImg from "../assets/images/PatentImage.png";
import "../styles/style.scss";

export default function ProductDetail() {
  const { id } = useParams();
  const { data: product, isLoading, error } = useProduct(id);
  const [selectedImage, setSelectedImage] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [displayUnit, setDisplayUnit] = useState("cm");
  const [measurementSystem, setMeasurementSystem] = useState("metric");
  const [activeTab, setActiveTab] = useState("Overview");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showDesignModal, setShowDesignModal] = useState(false);
  const [productDetails, setProductDetails] = useState(null);
  const [displayWidth, setDisplayWidth] = useState("");
  const [displayHeight, setDisplayHeight] = useState("");
  const [standardSize, setStandardSize] = useState("");
  const [totalPrice, setTotalPrice] = useState(0);
  const [backendErrors, setBackendErrors] = useState({});
  const [originalWidth, setOriginalWidth] = useState("");
  const [originalHeight, setOriginalHeight] = useState("");
  const [originalUnit, setOriginalUnit] = useState("");
  const [convertedValues, setConvertedValues] = useState({});
  const [isSmart, setIsSmart] = useState(true);
  const [fulfillmentType, setFulfillmentType] = useState("delivery");
  const [deliveryOption, setDeliveryOption] = useState("");
  const [turnAroundTime, setTurnAroundTime] = useState("standard");
  const [distance, setDistance] = useState("upto60km");
  const [installationSpeed, setInstallationSpeed] = useState("");
  const [showStickyButton, setShowStickyButton] = useState(false);
  const navigate = useNavigate();
  // Added converted min/max limits state variables
  const [displayMinWidth, setDisplayMinWidth] = useState("");
  const [displayMaxWidth, setDisplayMaxWidth] = useState("");
  const [displayMinHeight, setDisplayMinHeight] = useState("");
  const [displayMaxHeight, setDisplayMaxHeight] = useState("");

  const [thicknessId, setThicknessId] = useState(null);
  const [turnaroundId, setTurnaroundId] = useState(null);
  const [deliveryId, setDeliveryId] = useState(null);
  const [installationTypeId, setInstallationTypeId] = useState(null);
  const [distanceId, setDistanceId] = useState(null);

  // Refs for scroll handling
  const formRef = useRef(null);
  const lastScrollTop = useRef(0);

  const measurementOptions = {
    imperial: [
      { value: "inches", label: "inch" },
      { value: "feet", label: "Ft" },
      { value: "yard", label: "yd" },
    ],
    metric: [
      { value: "mm", label: "mm" },
      { value: "cm", label: "cm" },
      { value: "meter", label: "mt" },
    ],
  };

  // Handle scroll for sticky button
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      const formTop = formRef.current?.offsetTop || 0;
      const formHeight = formRef.current?.offsetHeight || 0;
      const formBottom = formTop + formHeight;
      const windowHeight = window.innerHeight;

      // Show sticky button when scrolling through the form
      if (scrollTop > formTop && scrollTop < formBottom - windowHeight) {
        setShowStickyButton(true);
      } else {
        setShowStickyButton(false);
      }

      // Hide when scrolling up to the top or down to the bottom
      if (
        scrollTop < 100 ||
        scrollTop > document.body.scrollHeight - windowHeight - 100
      ) {
        setShowStickyButton(false);
      }

      lastScrollTop.current = scrollTop;
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const convertMeasurements = (width, height, fromUnit, toUnit) => {
    if (fromUnit === toUnit) {
      return { width, height };
    }

    // Convert to base unit (mm for metric, inches for imperial)
    let widthInBaseUnit, heightInBaseUnit;

    // Convert from original unit to base unit
    if (fromUnit === "mm") {
      widthInBaseUnit = Number.parseFloat(width);
      heightInBaseUnit = Number.parseFloat(height);
    } else if (fromUnit === "cm") {
      widthInBaseUnit = Number.parseFloat(width) * 10;
      heightInBaseUnit = Number.parseFloat(height) * 10;
    } else if (fromUnit === "meter") {
      widthInBaseUnit = Number.parseFloat(width) * 1000;
      heightInBaseUnit = Number.parseFloat(height) * 1000;
    } else if (fromUnit === "inches") {
      widthInBaseUnit = Number.parseFloat(width);
      heightInBaseUnit = Number.parseFloat(height);
    } else if (fromUnit === "feet") {
      widthInBaseUnit = Number.parseFloat(width) * 12;
      heightInBaseUnit = Number.parseFloat(height) * 12;
    } else if (fromUnit === "yard") {
      widthInBaseUnit = Number.parseFloat(width) * 36;
      heightInBaseUnit = Number.parseFloat(height) * 36;
    }

    // Convert from base unit to target unit
    let convertedWidth, convertedHeight;

    if (toUnit === "mm") {
      convertedWidth = widthInBaseUnit;
      convertedHeight = heightInBaseUnit;
    } else if (toUnit === "cm") {
      convertedWidth = widthInBaseUnit / 10;
      convertedHeight = heightInBaseUnit / 10;
    } else if (toUnit === "meter") {
      convertedWidth = widthInBaseUnit / 1000;
      convertedHeight = heightInBaseUnit / 1000;
    } else if (toUnit === "inches") {
      convertedWidth = widthInBaseUnit;
      convertedHeight = heightInBaseUnit;
    } else if (toUnit === "feet") {
      convertedWidth = widthInBaseUnit / 12;
      convertedHeight = heightInBaseUnit / 12;
    } else if (toUnit === "yard") {
      convertedWidth = widthInBaseUnit / 36;
      convertedHeight = heightInBaseUnit / 36;
    }

    // Handle cross-system conversions (metric to imperial or vice versa)
    const fromIsMetric = ["mm", "cm", "meter"].includes(fromUnit);
    const toIsMetric = ["mm", "cm", "meter"].includes(toUnit);

    if (fromIsMetric && !toIsMetric) {
      // Convert from metric base unit (mm) to imperial base unit (inches)
      const mmToInches = widthInBaseUnit / 25.4;
      const mmToInchesHeight = heightInBaseUnit / 25.4;

      // Then convert to the target imperial unit
      if (toUnit === "inches") {
        convertedWidth = mmToInches;
        convertedHeight = mmToInchesHeight;
      } else if (toUnit === "feet") {
        convertedWidth = mmToInches / 12;
        convertedHeight = mmToInchesHeight / 12;
      } else if (toUnit === "yard") {
        convertedWidth = mmToInches / 36;
        convertedHeight = mmToInchesHeight / 36;
      }
    } else if (!fromIsMetric && toIsMetric) {
      // Convert from imperial base unit (inches) to metric base unit (mm)
      const inchesToMm = widthInBaseUnit * 25.4;
      const inchesToMmHeight = heightInBaseUnit * 25.4;

      // Then convert to the target metric unit
      if (toUnit === "mm") {
        convertedWidth = inchesToMm;
        convertedHeight = inchesToMmHeight;
      } else if (toUnit === "cm") {
        convertedWidth = inchesToMm / 10;
        convertedHeight = inchesToMmHeight / 10;
      } else if (toUnit === "meter") {
        convertedWidth = inchesToMm / 1000;
        convertedHeight = inchesToMmHeight / 1000;
      }
    }

    // Round to 2 decimal places for better display
    return {
      width: convertedWidth?.toFixed(2),
      height: convertedHeight?.toFixed(2),
    };
  };

  const updateAllConvertedValues = (width, height, fromUnit) => {
    const allUnits = [
      ...measurementOptions.metric,
      ...measurementOptions.imperial,
    ].map((opt) => opt.value);
    const conversions = {};

    allUnits.forEach((toUnit) => {
      if (toUnit !== fromUnit) {
        const converted = convertMeasurements(width, height, fromUnit, toUnit);
        conversions[toUnit] = converted;
      }
    });

    setConvertedValues(conversions);
  };

  // New function to update min/max limits based on unit changes
  const updateMinMaxLimits = (fromUnit, toUnit) => {
    if (product) {
      // Convert min limits
      const convertedMin = convertMeasurements(
        product.min_width,
        product.min_height,
        fromUnit,
        toUnit
      );
      setDisplayMinWidth(convertedMin.width);
      setDisplayMinHeight(convertedMin.height);

      // Convert max limits
      const convertedMax = convertMeasurements(
        product.max_width,
        product.max_height,
        fromUnit,
        toUnit
      );
      setDisplayMaxWidth(convertedMax.width);
      setDisplayMaxHeight(convertedMax.height);
    }
  };

  useEffect(() => {
    if (product) {
      // Always start with metric system
      setMeasurementSystem("metric");
      setDisplayUnit("cm"); // Default metric unit

      // Store original values from backend
      setOriginalWidth(product.min_width);
      setOriginalHeight(product.min_height);
      setOriginalUnit(product.size);

      // Set default option IDs
      if (product.thickness_options && product.thickness_options.length > 0) {
        setThicknessId(product.thickness_options[0].id);
      }
      if (product.turnaround_options && product.turnaround_options.length > 0) {
        setTurnaroundId(product.turnaround_options[0].id);
      }
      if (product.delivery_options && product.delivery_options.length > 0) {
        // Find pickup option or use first option
        const pickupOption = product.delivery_options.find(
          (opt) => opt.name.toLowerCase() === ""
        );
        setDeliveryId(
          pickupOption ? pickupOption.id : product.delivery_options[0].id
        );
      }
      if (
        product.installation_options &&
        product.installation_options.length > 0
      ) {
        // Find 8 Business days option or use first option
        const defaultInstallation = product.installation_options.find((opt) =>
          opt.name.includes("")
        );
        setInstallationTypeId(
          defaultInstallation
            ? defaultInstallation.id
            : product.installation_options[0].id
        );
      }
      if (product.distance_options && product.distance_options.length > 0) {
        setDistanceId(product.distance_options[0].id);
      }

      // Convert product dimensions to metric (cm) for initial display
      const converted = convertMeasurements(
        product.min_width,
        product.min_height,
        product.size,
        "cm" // Convert to cm
      );

      setDisplayWidth(converted.width);
      setDisplayHeight(converted.height);

      // Also convert min/max limits to cm
      const convertedMin = convertMeasurements(
        product.min_width,
        product.min_height,
        product.size,
        "cm"
      );
      setDisplayMinWidth(convertedMin.width);
      setDisplayMinHeight(convertedMin.height);

      const convertedMax = convertMeasurements(
        product.max_width,
        product.max_height,
        product.size,
        "cm"
      );
      setDisplayMaxWidth(convertedMax.width);
      setDisplayMaxHeight(convertedMax.height);

      // Calculate initial price using converted cm values
      calculateInitialPrice(converted.width, converted.height, "cm");
    }
  }, [product]);

  const priceMutation = useMutation({
    mutationFn: async (priceData) => {
      console.log("Sending Data:", priceData);
      try {
        const response = await API.post("/dash/product-price/", priceData);
        console.log("Full Response:", response);
        return response.data;
      } catch (error) {
        throw new Error(error.message || "Price calculation failed");
      }
    },
    onSuccess: (data) => {
      console.log("Received Data:", data);
      setBackendErrors({});
      setTotalPrice(data.total_price);

      // Only update original values if this is the first load
      if (!originalUnit) {
        setOriginalWidth(data.width);
        setOriginalHeight(data.height);
        setOriginalUnit(data.unit);
      }

      // Update display dimensions with values returned from backend
      setDisplayWidth(data.width);
      setDisplayHeight(data.height);

      // Use unit returned from backend
      setDisplayUnit(data.unit);
    },
    onError: (error) => {
      setBackendErrors({ message: error.message });
      setTotalPrice(0);
      console.log(error);
    },
  });

  const calculateInitialPrice = (width, height, unit) => {
    priceMutation.mutate({
      product_id: product.id,
      width,
      height,
      unit,
      quantity: 1,
      thickness_id: thicknessId,
      turnaround_id: turnaroundId,
      delivery_id: fulfillmentType === "delivery" ? deliveryId : null,
      installation_type_id:
        fulfillmentType === "installation" ? installationTypeId : null,
      distance_id: fulfillmentType === "installation" ? distanceId : null,
    });
  };

  const handleMeasurementChange = (system) => {
    setMeasurementSystem(system);

    // Set default unit for the selected measurement system
    const newUnit = system === "imperial" ? "inches" : "cm";
    const oldUnit = displayUnit;

    // Convert values from current display unit to new unit
    const converted = convertMeasurements(
      displayWidth,
      displayHeight,
      oldUnit,
      newUnit
    );

    // Update min/max limits for the new unit
    updateMinMaxLimits(originalUnit, newUnit);

    // Update display values
    setDisplayWidth(converted.width);
    setDisplayHeight(converted.height);
    setDisplayUnit(newUnit);

    // Update converted values for all units
    updateAllConvertedValues(converted.width, converted.height, newUnit);

    // Let backend handle final validation and price calculation
    priceMutation.mutate({
      product_id: product.id,
      width: converted.width,
      height: converted.height,
      unit: newUnit,
      quantity,
      thickness_id: thicknessId,
      turnaround_id: turnaroundId,
      delivery_id: fulfillmentType === "delivery" ? deliveryId : null,
      installation_type_id:
        fulfillmentType === "installation" ? installationTypeId : null,
      distance_id: fulfillmentType === "installation" ? distanceId : null,
    });
  };

  const handleDimensionChange = (width, height, unit) => {
    // Update the current display values
    setDisplayWidth(width);
    setDisplayHeight(height);

    // Update all converted values
    updateAllConvertedValues(width, height, unit);

    // Send updated dimensions to backend for validation and price calculation
    priceMutation.mutate({
      product_id: product.id,
      width,
      height,
      unit,
      quantity,
      thickness_id: thicknessId,
      turnaround_id: turnaroundId,
      delivery_id: fulfillmentType === "delivery" ? deliveryId : null,
      installation_type_id:
        fulfillmentType === "installation" ? installationTypeId : null,
      distance_id: fulfillmentType === "installation" ? distanceId : null,
    });
  };

  const handleUnitChange = (newUnit) => {
    const oldUnit = displayUnit;

    // Convert from current display unit to new unit
    const converted = convertMeasurements(
      displayWidth,
      displayHeight,
      oldUnit,
      newUnit
    );

    // Update min/max limits for the new unit
    updateMinMaxLimits(originalUnit, newUnit);

    setDisplayWidth(converted.width);
    setDisplayHeight(converted.height);
    setDisplayUnit(newUnit);

    // Update all converted values based on the new unit
    updateAllConvertedValues(converted.width, converted.height, newUnit);

    // Recalculate price with the new unit
    priceMutation.mutate({
      product_id: product.id,
      width: converted.width,
      height: converted.height,
      unit: newUnit,
      quantity,
      thickness_id: thicknessId,
      turnaround_id: turnaroundId,
      delivery_id: fulfillmentType === "delivery" ? deliveryId : null,
      installation_type_id:
        fulfillmentType === "installation" ? installationTypeId : null,
      distance_id: fulfillmentType === "installation" ? distanceId : null,
    });
  };

  const handleStandardSizeChange = (e) => {
    const selected = e.target.value;
    setStandardSize(selected);

    if (selected === "") {
      // If "Custom Size" is selected, revert to product's minimum dimensions in current display unit
      const converted = convertMeasurements(
        product.min_width,
        product.min_height,
        originalUnit,
        displayUnit
      );

      setDisplayWidth(converted.width);
      setDisplayHeight(converted.height);

      // Calculate price with minimum dimensions
      priceMutation.mutate({
        product_id: product.id,
        width: converted.width,
        height: converted.height,
        unit: displayUnit,
        quantity,
        thickness_id: thicknessId,
        turnaround_id: turnaroundId,
        delivery_id: fulfillmentType === "delivery" ? deliveryId : null,
        installation_type_id:
          fulfillmentType === "installation" ? installationTypeId : null,
        distance_id: fulfillmentType === "installation" ? distanceId : null,
      });
      return;
    }

    // Find the selected standard size
    const size = product.standard_sizes?.find(
      (s) => s.standard_sizes === selected
    );
    if (!size) return;

    // Convert standard size to current display unit if needed
    const converted = convertMeasurements(
      size.width,
      size.height,
      size.unit,
      displayUnit
    );

    // Update dimensions from standard size
    setDisplayWidth(converted.width);
    setDisplayHeight(converted.height);

    // Calculate price with the standard size dimensions
    priceMutation.mutate({
      product_id: product.id,
      width: converted.width,
      height: converted.height,
      unit: displayUnit,
      quantity,
      thickness_id: thicknessId,
      turnaround_id: turnaroundId,
      delivery_id: fulfillmentType === "delivery" ? deliveryId : null,
      installation_type_id:
        fulfillmentType === "installation" ? installationTypeId : null,
      distance_id: fulfillmentType === "installation" ? distanceId : null,
    });
  };

  const getStandardSizeDisplayText = (size) => {
    // If the standard size unit matches the current display unit, no conversion needed
    if (size.unit === displayUnit) {
      return `${size.standard_sizes} (${size.unit})`;
    }

    // Convert the standard size dimensions to the current display unit
    const converted = convertMeasurements(
      size.width,
      size.height,
      size.unit,
      displayUnit
    );

    // Format the converted dimensions
    const formattedWidth = Number.parseFloat(converted.width).toFixed(2);
    const formattedHeight = Number.parseFloat(converted.height).toFixed(2);

    // Return the display text with both original and converted values
    return ` ${formattedWidth}×${formattedHeight} ${displayUnit}`;
  };

  const renderHtmlContent = (html) => {
    if (!html)
      return <p className="text-gray-500 italic">No content available</p>;

    const processedHtml = html
      .replace(/<img([^>]+)src="http:/g, '<img$1src="https:')
      .replace(/<img/g, '<img class="max-w-full h-auto rounded-lg my-4" ')
      .replace(/&amp;/g, "&");

    return (
      <div className="prose max-w-none">
        <div dangerouslySetInnerHTML={{ __html: processedHtml }} />
      </div>
    );
  };

  // Function to handle Smart Integration change
  const handleSmartIntegrationChange = (value) => {
    if (value === false) {
      const confirmChange = toast.success(
        "Are you sure? There is no additional charges for making it Smart."
      );
      if (confirmChange) {
        setIsSmart(false);
      }
    } else {
      setIsSmart(true);
    }
  };

  // Image slider navigation
  const nextImage = () => {
    const images = [
      product.image1,
      product.image2,
      product.image3,
      product.image4,
    ].filter(Boolean);
    setSelectedImage((prev) => (prev + 1) % images.length);
  };

  const prevImage = () => {
    const images = [
      product.image1,
      product.image2,
      product.image3,
      product.image4,
    ].filter(Boolean);
    setSelectedImage((prev) => (prev - 1 + images.length) % images.length);
  };

  if (isLoading)
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-red-500" />
      </div>
    );

  if (error)
    return (
      <div className="min-h-screen flex flex-col items-center justify-center p-4">
        <img
          src={errorImg || "/placeholder.svg"}
          alt="Error"
          className="w-32 h-32 mb-4 opacity-75"
        />
        <h2 className="text-2xl font-bold text-gray-800 mb-2">Loading Error</h2>
        <button
          onClick={() => window.location.reload()}
          className="px-6 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors"
        >
          Reload Page
        </button>
      </div>
    );

  return (
    product && (
      <div className="max-w-full  mx-auto px-4 sm:px-6 lg:px-8 py-4">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          <div className="space-y-6">
            <div className="relative aspect-[4/3] w-full overflow-hidden rounded-lg bg-gray-100">
              <img
                src={
                  [
                    product.image1,
                    product.image2,
                    product.image3,
                    product.image4,
                  ][selectedImage] || "/placeholder.svg"
                }
                alt={product.name}
                className="w-full h-full object-cover"
              />
            </div>
            <div className="grid grid-cols-4 gap-2">
              {[product.image1, product.image2, product.image3, product.image4]
                .filter(Boolean)
                .map((img, index) => (
                  <button
                    key={index}
                    onClick={() => setSelectedImage(index)}
                    className={`relative aspect-square overflow-hidden rounded-md ${
                      selectedImage === index
                        ? "ring-2 ring-red-500"
                        : "ring-1 ring-gray-200"
                    }`}
                  >
                    <img
                      src={img || "/placeholder.svg"}
                      alt={`Preview ${index + 1}`}
                      className="w-full h-full object-cover"
                    />
                  </button>
                ))}
            </div>
          </div>

          {/* Product detailed form */}
          <div
            className="space-y-2 p-4 rounded-lg bg-gray-50 overflow-hidden"
            ref={formRef}
          >
            <div>
              <div className="flex flex-wrap items-center justify-between gap-2 mb-2">
                <h1 className="text-xl sm:text-2xl font-bold">
                  {product.name}
                </h1>
                <div className="flex items-center gap-2 flex-wrap">
                  <button
                    onClick={() => setIsModalOpen(true)}
                    className="text-red-500 text-sm hover:text-red-600"
                  >
                    Change Product
                  </button>

                  {product.amazon_url && (
                    <a
                      href={product.amazon_url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-yellow-500 text-sm hover:text-yellow-600 cursor-pointer"
                    >
                      <img
                        src={Amazon || "/placeholder.svg"}
                        className="w-24 h-10 sm:w-28 sm:h-12 hover:scale-105 transition-transform"
                        alt="Amazon Link"
                      />
                    </a>
                  )}
                </div>
              </div>
              <p className="text-gray-600 text-sm">{product.description}</p>
            </div>

            <form
              onSubmit={(e) => {
                e.preventDefault();
                setShowDesignModal(true);
                setProductDetails({
                  amazon_url: product?.amazon_url,
                  allow_direct_add_to_cart: product?.allow_direct_add_to_cart,
                  id: product.id,
                  name: product.name,
                  customSize: {
                    width: displayWidth,
                    height: displayHeight,
                    unit: displayUnit,
                  },
                  quantity,
                  total: totalPrice,
                  design_image: product?.image1,
                  isSmart,
                });
              }}
            >
              <div className="bg-white p-2 sm:p-4 border border-gray-200 shadow-sm rounded-md">
                {/* Header without price */}
                <div className="pb-1">
                  <div className="flex items-center gap-3">
                    <Ruler className="text-red-500" size={22} />
                    <h3 className="font-semibold text-base">
                      Product Customization
                    </h3>
                  </div>
                </div>

                <div>
                  <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center">
                    <h4 className="font-bold text-gray-900 text-sm mb-2 sm:mb-0">
                      Step 1: <span className="text-gray-700">Define size</span>
                    </h4>
                    <div className="relative flex items-end gap-2">
                      {["metric", "imperial"].reverse()?.map((system) => (
                        <div key={system} className="relative text-sm">
                          <div
                            onClick={() => handleMeasurementChange(system)}
                            className={`
                              px-2 sm:px-4 py-2 transition-colors duration-300 bg-gray-100 cursor-pointer
                              ${
                                measurementSystem === system
                                  ? "border-1 bg-white border-b-white text-red-600 font-medium relative"
                                  : "text-black hover:text-gray-700"
                              }
                            `}
                          >
                            {system.charAt(0).toUpperCase() + system.slice(1)}
                          </div>
                          {measurementSystem === system && (
                            <div
                              className={`absolute left-0 w-full h-10 bg-white -bottom-[39px] ${
                                measurementSystem === "metric" && "border-r"
                              }`}
                            />
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                {/* Tab Content unit radio buttons */}
                <div className="w-full space-y-4 border border-gray-200 p-4 mb-2 overflow-x-auto">
                  <div className="flex flex-wrap justify-between">
                    <label className="text-xs font-medium text-gray-700 mb-2 sm:mb-0 hidden sm:block">
                      Select a standard size or customize
                    </label>
                    <div className="flex items-center space-x-2 sm:space-x-4 relative gap-2">
                      {measurementOptions[measurementSystem]?.map((opt) => (
                        <label
                          key={opt.value}
                          className="inline-flex items-center"
                        >
                          <input
                            type="radio"
                            name="unit"
                            value={opt.value}
                            checked={displayUnit === opt.value}
                            onChange={(e) => {
                              const newUnit = e.target.value;
                              setDisplayUnit(newUnit);
                              handleUnitChange(newUnit);
                            }}
                            className="form-radio h-4 w-4 text-red-600 accent-red-600"
                          />
                          <span className="ml-1 sm:ml-2 text-xs text-gray-700">
                            {opt.label}
                          </span>
                        </label>
                      ))}
                    </div>
                  </div>

                  <div className="space-y-2">
                    {/* Size and Thickness row */}
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                      <div className="flex flex-col sm:flex-row sm:items-center">
                        <label className="text-sm font-semibold text-gray-950 w-24 mb-1 sm:mb-0">
                          Size
                        </label>
                        <select
                          value={standardSize}
                          onChange={handleStandardSizeChange}
                          className="flex-1 h-10 p-2 border border-gray-300 rounded-md bg-white focus:ring-2 focus:ring-red-100 focus:border-red-500 text-sm"
                        >
                          {/* <option value="">Custom size</option> */}
                          {product.standard_sizes &&
                            product.standard_sizes.map((size, index) => (
                              <option key={index} value={size.standard_sizes}>
                                {getStandardSizeDisplayText(size)}
                              </option>
                            ))}
                        </select>
                      </div>

                      <div className="flex flex-col sm:flex-row sm:items-center">
                        <label className="text-sm font-semibold text-gray-950 w-24 mb-1 sm:mb-0">
                          Thickness
                        </label>
                        <select
                          className="flex-1 h-10 p-2 border border-gray-300 rounded-md bg-white focus:ring-2 focus:ring-red-100 focus:border-red-500 text-sm"
                          value={thicknessId}
                          onChange={(e) => {
                            const newThicknessId = Number(e.target.value);
                            setThicknessId(newThicknessId);
                            priceMutation.mutate({
                              product_id: product.id,
                              width: displayWidth,
                              height: displayHeight,
                              unit: displayUnit,
                              quantity,
                              thickness_id: newThicknessId,
                              turnaround_id: turnaroundId,
                              delivery_id:
                                fulfillmentType === "delivery"
                                  ? deliveryId
                                  : null,
                              installation_type_id:
                                fulfillmentType === "installation"
                                  ? installationTypeId
                                  : null,
                              distance_id:
                                fulfillmentType === "installation"
                                  ? distanceId
                                  : null,
                            });
                          }}
                        >
                          {product.thickness_options?.map((option) => (
                            <option key={option.id} value={option.id}>
                              {option.size}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    {/* Width and Height row */}
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                      <div className="flex flex-col sm:flex-row sm:items-center">
                        <label className="text-sm font-semibold text-gray-950 w-24 mb-1 sm:mb-0">
                          Width
                        </label>
                        <div className="flex-1">
                          <input
                            type="number"
                            value={displayWidth}
                            onChange={(e) => {
                              const newWidth = e.target.value;
                              handleDimensionChange(
                                newWidth,
                                displayHeight,
                                displayUnit
                              );
                            }}
                            className="w-full h-10 p-3 border border-gray-300 rounded-md bg-white focus:ring-2 focus:ring-red-100 focus:border-red-500 text-sm"
                            step="0.01"
                            min={displayMinWidth}
                            max={displayMaxWidth}
                          />
                          <div className="text-xs text-black mt-1 Min-Text whitespace-normal">
                            Min: {displayMinWidth} {displayUnit} | Max:{" "}
                            {displayMaxWidth
                              ? `${displayMaxWidth} ${displayUnit}`
                              : ""}
                          </div>
                        </div>
                      </div>

                      <div className="flex flex-col sm:flex-row sm:items-center">
                        <label className="text-sm font-semibold text-gray-950 w-24 mb-1 sm:mb-0">
                          Height
                        </label>
                        <div className="flex-1">
                          <input
                            type="number"
                            value={displayHeight}
                            onChange={(e) => {
                              const newHeight = e.target.value;
                              handleDimensionChange(
                                displayWidth,
                                newHeight,
                                displayUnit
                              );
                            }}
                            className="w-full h-10 p-3 border border-gray-300 rounded-md bg-white focus:ring-2 focus:ring-red-100 focus:border-red-500 text-sm"
                            step="0.01"
                            min={displayMinHeight}
                            max={displayMaxHeight}
                          />
                          <div className="text-xs text-black mt-1 Min-Text whitespace-normal">
                            Min: {displayMinHeight} {displayUnit} | Max:{" "}
                            {displayMaxHeight
                              ? `${displayMaxHeight} ${displayUnit}`
                              : ""}
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* error about unit min and max width and height from backend will show here  */}
                    {backendErrors.message && (
                      <div className="col-span-2 bg-red-50 text-red-600 p-3 rounded-md text-sm mb-4 flex items-start gap-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <circle cx="12" cy="12" r="10"></circle>
                          <line x1="12" y1="8" x2="12" y2="12"></line>
                          <line x1="12" y1="16" x2="12.01" y2="16"></line>
                        </svg>
                        {backendErrors.message}
                      </div>
                    )}
                  </div>
                </div>

                <div className="flex flex-col w-full gap-6">
                  {/* Row 1: Steps 2 and 3 */}
                  <div className="flex flex-col lg:flex-row w-full gap-9">
                    {/* Step 2: Quantity - Fixed 200px on desktop */}
                    <div className="w-full lg:w-[200px] shrink-0">
                      <h4 className="font-bold text-gray-900 mb-3 text-sm">
                        Step 2:{" "}
                        <span className="text-gray-700">Set quantity</span>
                      </h4>

                      <div className="flex items-center">
                        <div className="flex items-center border border-gray-300  overflow-hidden">
                          <button
                            type="button"
                            onClick={() => {
                              const newQuantity = Math.max(1, quantity - 1);
                              setQuantity(newQuantity);
                              priceMutation.mutate({
                                product_id: product.id,
                                width: displayWidth,
                                height: displayHeight,
                                unit: displayUnit,
                                quantity: newQuantity,
                                thickness_id: thicknessId,
                                turnaround_id: turnaroundId,
                                delivery_id:
                                  fulfillmentType === "delivery"
                                    ? deliveryId
                                    : null,
                                installation_type_id:
                                  fulfillmentType === "installation"
                                    ? installationTypeId
                                    : null,
                                distance_id:
                                  fulfillmentType === "installation"
                                    ? distanceId
                                    : null,
                              });
                            }}
                            className="px-6 py-3 font-bold text-black hover:bg-gray-100 border-r border-gray-300"
                          >
                            -
                          </button>
                          <input
                            type="number"
                            min="1"
                            value={quantity}
                            onChange={(e) => {
                              const newQuantity = Math.max(
                                1,
                                Number.parseInt(e.target.value) || 1
                              );
                              setQuantity(newQuantity);
                              priceMutation.mutate({
                                product_id: product.id,
                                width: displayWidth,
                                height: displayHeight,
                                unit: displayUnit,
                                quantity: newQuantity,
                                thickness_id: thicknessId,
                                turnaround_id: turnaroundId,
                                delivery_id:
                                  fulfillmentType === "delivery"
                                    ? deliveryId
                                    : null,
                                installation_type_id:
                                  fulfillmentType === "installation"
                                    ? installationTypeId
                                    : null,
                                distance_id:
                                  fulfillmentType === "installation"
                                    ? distanceId
                                    : null,
                              });
                            }}
                            className="w-22 p-3 text-center font-bold text-black border-0 focus:ring-0"
                          />
                          <button
                            type="button"
                            onClick={() => {
                              const newQuantity = quantity + 1;
                              setQuantity(newQuantity);
                              priceMutation.mutate({
                                product_id: product.id,
                                width: displayWidth,
                                height: displayHeight,
                                unit: displayUnit,
                                quantity: newQuantity,
                                thickness_id: thicknessId,
                                turnaround_id: turnaroundId,
                                delivery_id:
                                  fulfillmentType === "delivery"
                                    ? deliveryId
                                    : null,
                                installation_type_id:
                                  fulfillmentType === "installation"
                                    ? installationTypeId
                                    : null,
                                distance_id:
                                  fulfillmentType === "installation"
                                    ? distanceId
                                    : null,
                              });
                            }}
                            className="px-6 py-3 font-bold text-black hover:bg-gray-100 border-l border-gray-300"
                          >
                            +
                          </button>
                        </div>
                      </div>
                    </div>

                    {/* Step 3: Signage Type - Fixed 417px on desktop */}
                    <div className="w-full lg:w-[417px]">
                      <h4 className="font-bold text-sm text-black mb-3">
                        Step 3:{" "}
                        <span className="text-gray-700">Signage Type</span>
                      </h4>

                      <div className="w-full">
                        <div className="border p-4  flex justify-between items-center">
                          <label className="flex items-center gap-2">
                            <input
                              type="radio"
                              checked={isSmart}
                              onChange={() =>
                                handleSmartIntegrationChange(true)
                              }
                              className="form-radio h-4 w-4 accent-red-600"
                            />
                            <TooltipProvider>
                              <Tooltip>
                                <TooltipTrigger asChild>
                                  <label className="cursor-pointer relative text-xs whitespace-nowrap">
                                    <span className="absolute -top-2 -right-7 text-black text-xs font-bold px-1 rounded-full">
                                      PAF
                                    </span>
                                    Smart Signage{" "}
                                  </label>
                                </TooltipTrigger>
                                <TooltipContent className="max-w-2xs bg-white text-black shadow rounded [&>svg]:hidden [&>*>svg]:hidden">
                                  <div className="flex">
                                    <p>
                                      Enables you to use our patent pending
                                      system to set, track and manage various
                                      actions
                                    </p>
                                    <img
                                      src={toolTipImg || "/placeholder.svg"}
                                      alt="patent-image"
                                      className="w-12 h-12 object-cover"
                                    />
                                  </div>
                                </TooltipContent>
                              </Tooltip>
                            </TooltipProvider>
                          </label>

                          <label className="flex items-center gap-2">
                            <input
                              type="radio"
                              checked={!isSmart}
                              onChange={() =>
                                handleSmartIntegrationChange(false)
                              }
                              className="form-radio h-4 w-4 accent-red-600"
                            />
                            <span className="text-xs whitespace-nowrap">
                              Ordinary Signage
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Row 2: Steps 4 and 5 */}
                  <div className="flex flex-col lg:flex-row w-full gap-9 mb-8">
                    <div className="w-full lg:w-[200px] shrink-0">
                      <h4 className="font-bold text-black mb-2 text-sm">
                        Step 4:{" "}
                        <span className="text-gray-700">Turn around time</span>
                      </h4>
                      <div className="border p-4 ">
                        <div className="flex flex-col gap-2">
                          {product.turnaround_options?.map((option) => (
                            <label
                              key={option.id}
                              className="flex items-center gap-2 cursor-pointer"
                            >
                              <input
                                type="radio"
                                name="turnAroundTime"
                                value={option.id}
                                checked={turnaroundId === option.id}
                                onChange={() => {
                                  setTurnaroundId(option.id);
                                  priceMutation.mutate({
                                    product_id: product.id,
                                    width: displayWidth,
                                    height: displayHeight,
                                    unit: displayUnit,
                                    quantity,
                                    thickness_id: thicknessId,
                                    turnaround_id: option.id,
                                    delivery_id:
                                      fulfillmentType === "delivery"
                                        ? deliveryId
                                        : null,
                                    installation_type_id:
                                      fulfillmentType === "installation"
                                        ? installationTypeId
                                        : null,
                                    distance_id:
                                      fulfillmentType === "installation"
                                        ? distanceId
                                        : null,
                                  });
                                }}
                                className="form-radio h-4 w-4 accent-red-600"
                              />
                              <div>
                                <div className="font-medium text-xs">
                                  {option.name}
                                </div>
                                <div className="text-[9px] text-gray-500">
                                  {option.description}
                                </div>
                              </div>
                            </label>
                          ))}
                        </div>
                      </div>
                    </div>

                    {/* Step 5: Fulfillment type - Fixed 417px on desktop */}
                    <div className="w-full lg:w-[417px]">
                      <h4 className="font-bold text-black mb-2 text-sm">
                        Step 5:{" "}
                        <span className="text-gray-700">Fulfillment type</span>
                      </h4>

                      {/* Tab selection for Delivery or Installation */}
                      <div className="flex border-t border-l border-r  overflow-hidden">
                        <div
                          className={`flex-1 p-2 flex items-center justify-center cursor-pointer ${
                            fulfillmentType === "delivery"
                              ? "bg-white text-red-600"
                              : "bg-gray-100 text-gray-800 hover:bg-gray-50"
                          } border-r`}
                          onClick={() => {
                            setFulfillmentType("delivery");
                            // Reset installation values when switching to delivery
                            setInstallationTypeId(null);
                            setDistanceId(null);

                            // Recalculate price with updated values
                            priceMutation.mutate({
                              product_id: product.id,
                              width: displayWidth,
                              height: displayHeight,
                              unit: displayUnit,
                              quantity,
                              thickness_id: thicknessId,
                              turnaround_id: turnaroundId,
                              delivery_id: deliveryId,
                              installation_type_id: null,
                              distance_id: null,
                            });
                          }}
                        >
                          <input
                            type="radio"
                            id="delivery"
                            name="fulfillmentType"
                            value="delivery"
                            checked={fulfillmentType === "delivery"}
                            onChange={() => setFulfillmentType("delivery")}
                            className="h-4 w-4 text-red-600 mr-2 accent-red-600"
                          />
                          <label
                            htmlFor="delivery"
                            className="font-medium text-sm cursor-pointer"
                          >
                            Delivery
                          </label>
                        </div>

                        <div
                          className={`flex-1 p-2 flex items-center justify-center cursor-pointer ${
                            fulfillmentType === "installation"
                              ? "bg-white text-red-600"
                              : "bg-gray-100 text-gray-800 hover:bg-gray-50"
                          }`}
                          onClick={() => {
                            setFulfillmentType("installation");
                            // Reset delivery values when switching to installation
                            setDeliveryId(null);
                            setDeliveryOption("pickup");

                            // Recalculate price with updated values
                            priceMutation.mutate({
                              product_id: product.id,
                              width: displayWidth,
                              height: displayHeight,
                              unit: displayUnit,
                              quantity,
                              thickness_id: thicknessId,
                              turnaround_id: turnaroundId,
                              delivery_id: null,
                              installation_type_id: installationTypeId,
                              distance_id: distanceId,
                            });
                          }}
                        >
                          <input
                            type="radio"
                            id="installation"
                            name="fulfillmentType"
                            value="installation"
                            checked={fulfillmentType === "installation"}
                            onChange={() => setFulfillmentType("installation")}
                            className="h-4 w-4 text-red-600 mr-2 accent-red-600"
                          />
                          <label
                            htmlFor="installation"
                            className="font-medium text-sm cursor-pointer"
                          >
                            Installation
                          </label>
                        </div>
                      </div>

                      <div className="border border-t-0 p-4">
                        {fulfillmentType === "delivery" && (
                          <div className="grid grid-cols-2 gap-x-2 gap-y-6">
                            {product.delivery_options?.map((option) => (
                              <label
                                key={option.id}
                                className="flex items-center gap-2 cursor-pointer"
                              >
                                <input
                                  type="radio"
                                  name="deliveryOption"
                                  value={option.id}
                                  checked={deliveryId === option.id}
                                  onChange={() => {
                                    setDeliveryOption(
                                      option.name.toLowerCase()
                                    );
                                    setDeliveryId(option.id);
                                    priceMutation.mutate({
                                      product_id: product.id,
                                      width: displayWidth,
                                      height: displayHeight,
                                      unit: displayUnit,
                                      quantity,
                                      thickness_id: thicknessId,
                                      turnaround_id: turnaroundId,
                                      delivery_id: option.id,
                                      installation_type_id:
                                        fulfillmentType === "installation"
                                          ? installationTypeId
                                          : null,
                                      distance_id:
                                        fulfillmentType === "installation"
                                          ? distanceId
                                          : null,
                                    });
                                  }}
                                  className="h-4 w-4 text-red-600 accent-red-600"
                                />
                                <div>
                                  <span className="font-medium text-xs">
                                    {option.name}
                                  </span>
                                  {option.description && (
                                    <span className="text-xs text-gray-500 ml-1">
                                      {option.description}
                                    </span>
                                  )}
                                </div>
                              </label>
                            ))}
                          </div>
                        )}

                        {fulfillmentType === "installation" && (
                          <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-2 gap-y-2">
                            <div className="mb-2">
                              <select
                                value={distanceId}
                                onChange={(e) => {
                                  const newDistanceId = Number(e.target.value);
                                  setDistanceId(newDistanceId);
                                  setDistance(
                                    product.distance_options
                                      .find((opt) => opt.id === newDistanceId)
                                      ?.km.toLowerCase()
                                      .replace(/\s+/g, "") || ""
                                  );
                                  priceMutation.mutate({
                                    product_id: product.id,
                                    width: displayWidth,
                                    height: displayHeight,
                                    unit: displayUnit,
                                    quantity,
                                    thickness_id: thicknessId,
                                    turnaround_id: turnaroundId,
                                    delivery_id:
                                      fulfillmentType === "delivery"
                                        ? deliveryId
                                        : null,
                                    installation_type_id: installationTypeId,
                                    distance_id: newDistanceId,
                                  });
                                }}
                                className="border p-2 rounded w-full sm:w-36 text-xs"
                              >
                                <option value="Distance">Select Distance</option>

                                {product.distance_options?.map((option) => (
                                  <option key={option.id} value={option.id}>
                                    {option.km}
                                  </option>
                                ))}
                              </select>
                            </div>

                            {product.installation_options?.map((option) => (
                              <label
                                key={option.id}
                                className="flex items-center gap-2 cursor-pointer"
                              >
                                <input
                                  type="radio"
                                  name="installationSpeed"
                                  value={option.id}
                                  checked={installationTypeId === option.id}
                                  onChange={() => {
                                    setInstallationSpeed(
                                      option.name
                                        .toLowerCase()
                                        .replace(/\s+/g, "")
                                    );
                                    setInstallationTypeId(option.id);
                                    priceMutation.mutate({
                                      product_id: product.id,
                                      width: displayWidth,
                                      height: displayHeight,
                                      unit: displayUnit,
                                      quantity,
                                      thickness_id: thicknessId,
                                      turnaround_id: turnaroundId,
                                      delivery_id:
                                        fulfillmentType === "delivery"
                                          ? deliveryId
                                          : null,
                                      installation_type_id: option.id,
                                      distance_id: distanceId,
                                    });
                                  }}
                                  className="h-4 w-4 text-red-600 accent-red-600"
                                />
                                <div className="font-medium text-xs">
                                  {option.name}
                                </div>
                              </label>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {/* Price and action button section */}
                <div className="mt-2 lg:mt-0 pt-2 border-t border-gray-200">
                  <div className="flex flex-col md:flex-row items-center justify-between gap-4">
                    <div className="flex flex-col items-start">
                      <span className="text-sm text-gray-600">
                        Total Price:
                      </span>
                      <div className="text-2xl font-bold">
                        {priceMutation.isPending ? (
                          <div className="flex items-center gap-2">
                            <div className="animate-spin h-4 w-4 border-2 border-red-500 border-t-transparent rounded-full" />
                            <span>Calculating...</span>
                          </div>
                        ) : (
                          `AED ${totalPrice.toFixed(2)}`
                        )}
                      </div>
                    </div>

                    <button
                      type="submit"
                      disabled={!totalPrice || priceMutation.isPending}
                      className={`w-full md:w-auto px-8 py-3 rounded-lg transition-colors flex justify-center items-center gap-2 text-lg font-medium ${
                        !totalPrice || priceMutation.isPending
                          ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                          : "bg-gradient-to-r from-[#BF1A1C] to-[#590C0D] text-white hover:shadow-xl cursor-pointer transition-shadow transform hover:-translate-y-0.5"
                      }`}
                    >
                      {priceMutation.isPending ? (
                        <>Calculating Price...</>
                      ) : (
                        <>
                          Proceed
                          <ArrowRight size={20} />
                        </>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        {/* Sticky mobile price and proceed button */}
        {showStickyButton && (
          <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 p-3 flex justify-between items-center z-50 md:hidden shadow-lg">
            <div className="flex flex-col">
              <span className="text-xs text-gray-600">Total Price:</span>
              <span className="text-xl font-bold">{`AED ${totalPrice.toFixed(
                2
              )}`}</span>
            </div>
            <button
              onClick={() => {
                if (formRef.current) {
                  const form = formRef.current.querySelector("form");
                  if (form)
                    form.dispatchEvent(
                      new Event("submit", { cancelable: true })
                    );
                }
              }}
              disabled={!totalPrice || priceMutation.isPending}
              className={`px-6 py-2 rounded-lg flex items-center gap-1 text-base font-medium ${
                !totalPrice || priceMutation.isPending
                  ? "bg-gray-300 text-gray-500"
                  : "bg-gradient-to-r from-[#BF1A1C] to-[#590C0D] text-white"
              }`}
            >
              Proceed
              <ArrowRight size={16} />
            </button>
          </div>
        )}

        <div className="mt-16">
          <div className="border-b border-gray-200 overflow-x-auto">
            <nav className="flex gap-4 sm:gap-8 whitespace-nowrap">
              {["Overview", "Specification", "Installation"].map((tab) => (
                <button
                  key={tab}
                  onClick={() => setActiveTab(tab)}
                  className={`px-3 sm:px-4 py-4 font-medium ${
                    activeTab === tab
                      ? "border-b-2 border-red-500 text-red-500"
                      : "text-gray-500 hover:text-gray-700"
                  }`}
                >
                  {tab}
                </button>
              ))}
            </nav>
          </div>

          <div className="py-8">
            {activeTab === "Overview" &&
              renderHtmlContent(product.product_overview)}
            {activeTab === "Specification" &&
              renderHtmlContent(product.product_specifications)}
            {activeTab === "Installation" &&
              renderHtmlContent(product.installation)}
          </div>
        </div>

        <ProductSelectionModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onSelect={(product) => navigate(`/product/${product.id}`)}
        />

        <DesignModal
          isOpen={showDesignModal}
          onClose={() => setShowDesignModal(false)}
          productDetails={productDetails}
        />
      </div>
    )
  );
}
