"use client";

import { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { confirmPayment } from "../services/PaymentService";
import { Shield, CreditCard, CheckCircle, AlertCircle } from "lucide-react";
import MasterCard from "../assets/images/mastercard.jpg";
import Visa from "../assets/images/visa.jpg";
import Amex from "../assets/images/amex.png";

// Map of full country names to ISO codes
const COUNTRY_CODES = {
  "United Arab Emirates": "AE",
  Oman: "OM",
  Bahrain: "BH",
  Qatar: "QA",
  Kuwait: "KW",
  "Saudi Arabia": "SA",
};

export default function StripePaymentForm({
  onSuccess,
  orderTotal = "0.00",
  subtotal = "0.00",
  tax = "0.00",
  vatPercentage = 5,
  transactionId,
  customerDetails,
}) {
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState("initial");
  const [billingDetails, setBillingDetails] = useState(null);

  useEffect(() => {
    // Load billing details from session storage
    const storedBillingDetails = sessionStorage.getItem("billing_details");
    if (storedBillingDetails) {
      setBillingDetails(JSON.parse(storedBillingDetails));
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage(null);
    setPaymentStatus("processing");
    setIsProcessing(true);

    try {
      if (!stripe || !elements || !billingDetails) {
        throw new Error(
          "Payment system is not ready or billing details missing"
        );
      }

      // Ensure all required fields are present and correctly named
      const formattedBillingDetails = {
        name: billingDetails.name,
        email:
          billingDetails.email || sessionStorage.getItem("user_email") || "",
        phone: billingDetails.phone || "",
        address: {
          line1: billingDetails.address.line1,
          line2: billingDetails.address.line2 || "",
          city: billingDetails.address.city,
          state: billingDetails.address.state,
          postal_code:
            billingDetails.address.postal_code ||
            billingDetails.address.zip_code, // Handle both field names
          country: billingDetails.address.country,
        },
      };

      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          payment_method_data: {
            billing_details: formattedBillingDetails,
          },
          return_url: window.location.origin + "/payment-complete",
        },
        redirect: "if_required",
      });

      if (error) {
        console.error("Payment error:", error);
        setErrorMessage(error.message);
        setPaymentStatus("error");
        return;
      }

      if (paymentIntent.status === "succeeded") {
        setPaymentStatus("success");
        const confirmationResponse = await confirmPayment(paymentIntent.id);
        if (confirmationResponse.success) {
          // Clear billing details from session storage
          sessionStorage.removeItem("billing_details");
          onSuccess(); // Direct redirect to confirmation
        }
      }
    } catch (error) {
      console.error("Payment submission error:", error);
      setErrorMessage(error.message);
      setPaymentStatus("error");
    } finally {
      setIsProcessing(false);
    }
  };

  if (!billingDetails) {
    return (
      <div className="text-center p-6 bg-gray-50 rounded-lg shadow">
        <AlertCircle className="w-12 h-12 text-red-500 mx-auto mb-4" />
        <p className="text-gray-700 font-medium">
          Please complete your shipping information before proceeding to
          payment.
        </p>
      </div>
    );
  }

  return (
    <div className="w-full max-w-2xl mx-auto bg-white rounded-xl shadow-md overflow-hidden">
      {/* Security Banner */}
      <div className="bg-red-50 p-3 flex items-center justify-center space-x-2 border-b border-red-100">
        <Shield className="w-5 h-5 text-red-600" />
        <span className="text-sm font-medium text-red-700">
          Secure Checkout - Your data is protected
        </span>
      </div>

      <div className="p-6">
        {/* Payment Header */}
        <div className="mb-6 flex justify-between items-center">
          <div>
            <h2 className="text-xl font-bold text-gray-800">
              Complete Payment
            </h2>
            <p className="text-gray-600 text-sm">Fast and secure checkout</p>
          </div>
          <div className="flex space-x-2">
            <img src={Visa || "/placeholder.svg"} alt="Visa" className="h-8" />
            <img
              src={MasterCard || "/placeholder.svg"}
              alt="Mastercard"
              className="h-8"
            />
            <img src={Amex || "/placeholder.svg"} alt="Amex" className="h-8" />
          </div>
        </div>

        {/* Order Summary Card - Simplified */}
        <div className="bg-gray-50 rounded-lg p-4 mb-6">
          <h3 className="text-md font-medium text-gray-800 mb-3">
            Order Summary
          </h3>
          <div className="space-y-2 text-sm">
            <div className="flex justify-between">
              <span className="text-gray-600">Subtotal</span>
              <span>AED {subtotal}</span>
            </div>
            <div className="flex justify-between">
              <span className="text-gray-600">VAT ({vatPercentage}%)</span>
              <span>AED {tax}</span>
            </div>
            <div className="border-t border-gray-200 mt-3 pt-3">
              <div className="flex justify-between text-lg font-bold">
                <span>Total</span>
                <span className="text-red-600">AED {orderTotal}</span>
              </div>
            </div>
          </div>
        </div>

        {/* Payment Form */}
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="rounded-lg border border-gray-200 p-4">
            <div className="flex items-center mb-4">
              <CreditCard className="w-5 h-5 text-gray-600 mr-2" />
              <h3 className="font-medium text-gray-800">Payment Information</h3>
            </div>

            <PaymentElement
              options={{
                layout: {
                  type: "tabs",
                  defaultCollapsed: false,
                },
                fields: {
                  billingDetails: "never",
                },
                theme: "stripe",
              }}
            />

            {errorMessage && (
              <div className="mt-4 p-3 bg-red-50 border border-red-100 rounded-md">
                <div className="flex items-center">
                  <AlertCircle className="h-5 w-5 text-red-500 mr-2" />
                  <p className="text-sm text-red-600">{errorMessage}</p>
                </div>
              </div>
            )}
          </div>

          {/* Trust Indicators */}
          <div className="grid grid-cols-2 gap-4 mb-6">
            <div className="flex items-center p-3 bg-gray-50 rounded-lg">
              <Shield className="w-5 h-5 text-red-600 mr-2" />
              <span className="text-sm text-gray-700">Secure Encryption</span>
            </div>
            <div className="flex items-center p-3 bg-gray-50 rounded-lg">
              <CheckCircle className="w-5 h-5 text-red-600 mr-2" />
              <span className="text-sm text-gray-700">Verified by Stripe</span>
            </div>
          </div>

          <button
            type="submit"
            disabled={!stripe || isProcessing}
            className={`w-full flex items-center justify-center px-5 py-3 rounded-lg text-white text-base font-medium transition-all duration-200
              ${
                isProcessing
                  ? "bg-gray-400 cursor-not-allowed"
                  : "bg-gradient-to-r from-red-600 to-red-700 hover:from-red-700 hover:to-red-800 shadow hover:shadow-md"
              }`}
          >
            {isProcessing ? (
              <span className="flex items-center">
                <svg
                  className="animate-spin -ml-1 mr-2 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
                Processing...
              </span>
            ) : (
              <span className="flex items-center">
                <Shield className="w-5 h-5 mr-2" />
                Pay Securely • AED {orderTotal}
              </span>
            )}
          </button>
        </form>

        {/* Trust Footer */}
        <div className="mt-6 pt-4 border-t border-gray-100"></div>
        <div className="flex flex-col items-center justify-center text-center">
          <div className="flex items-center mb-2">
            <Shield className="w-4 h-4 text-red-600 mr-1" />
            <span className="text-xs font-medium text-gray-700">
              PCI DSS Compliant
            </span>
            <span className="mx-2 text-gray-300">•</span>
            <span className="text-xs font-medium text-gray-700">
              SSL Encrypted
            </span>
            <span className="mx-2 text-gray-300">•</span>
            <span className="text-xs font-medium text-gray-700">3D Secure</span>
          </div>
          <p className="text-xs text-gray-500">
            Your payment information is securely processed by Stripe, a leading
            payment provider.
          </p>
        </div>
      </div>
    </div>
  );
}
