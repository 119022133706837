import { Link, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getCategoryDetails } from "../services/categoryApi";
import { useRef, useEffect, useState } from "react";

const CategoryDropdown = ({
  category,
  position = "right",
  onProductClick,
  onClose,
  parentCategoryImage, // Still accept this prop as a fallback
}) => {
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  const containerRef = useRef(null);
  const [dropdownStyle, setDropdownStyle] = useState({});
  const [hoveredProduct, setHoveredProduct] = useState(null);
  const [hoveredCategory, setHoveredCategory] = useState(null);

  const {
    data: categoryDetails,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["categoryDetails", category?.id],
    queryFn: () => getCategoryDetails(category?.id),
    enabled: !!category?.id,
    staleTime: 300000, // Cache for 5 minutes
    retry: 2,
  });

  // Calculate position after render and when window resizes
  useEffect(() => {
    function calculatePosition() {
      if (!dropdownRef.current || !containerRef.current) return;

      const parentEl = containerRef.current.parentElement;
      if (!parentEl) return;

      const parentRect = parentEl.getBoundingClientRect();
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;
      const dropdownWidth = 900; // The width of our dropdown

      const spaceOnRight = viewportWidth - parentRect.right;

      let maxHeight;
      if (position === "left") {
        maxHeight = viewportHeight - parentRect.bottom - 20;
        setDropdownStyle({
          right: 0,
          left: "auto",
          top: "100%",
          maxHeight: `${maxHeight}px`,
        });
      } else if (position === "top") {
        maxHeight = viewportHeight - parentRect.bottom - 20;
        setDropdownStyle({
          left: 0,
          top: "100%",
          right: "auto",
          maxHeight: `${maxHeight}px`,
        });
      } else if (position === "right" && spaceOnRight < dropdownWidth) {
        maxHeight = viewportHeight - parentRect.top - 20;
        setDropdownStyle({
          right: "100%",
          left: "auto",
          top: 0,
          maxHeight: `${maxHeight}px`,
        });
      } else {
        maxHeight = viewportHeight - parentRect.top - 20;
        setDropdownStyle({
          left: "100%",
          top: 0,
          right: "auto",
          maxHeight: `${maxHeight}px`,
        });
      }
    }

    calculatePosition();
    window.addEventListener("resize", calculatePosition);

    return () => window.removeEventListener("resize", calculatePosition);
  }, [categoryDetails, position]);

  const handleProductMouseEnter = (product) => {
    setHoveredProduct(product);
    setHoveredCategory(null);
  };

  const handleProductMouseLeave = () => {
    setHoveredProduct(null);
  };

  const handleCategoryMouseEnter = (category) => {
    setHoveredCategory(category);
    setHoveredProduct(null);
  };

  const handleCategoryMouseLeave = () => {
    setHoveredCategory(null);
  };

  const handleProductClick = (productId) => {
    if (onClose) {
      onClose(); // Close dropdown first
    }

    setTimeout(() => {
      navigate(`/product/${productId}`);
    }, 50); // Slight delay ensures dropdown disappears before navigation
  };

  if (isLoading) {
    return (
      <div
        ref={containerRef}
        className="absolute z-50 bg-white shadow-lg rounded-lg p-4 min-w-[200px]"
      >
        <div className="animate-pulse">
          <div className="h-4 bg-gray-200 rounded w-3/4 mb-4"></div>
          <div className="h-4 bg-gray-200 rounded w-1/2 mb-2"></div>
          <div className="h-4 bg-gray-200 rounded w-2/3"></div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div
        ref={containerRef}
        className="absolute z-50 bg-white shadow-lg rounded-lg p-4"
      >
        <p className="text-red-500">Failed to load categories</p>
      </div>
    );
  }

  if (!categoryDetails?.categories?.length) {
    return (
      <div
        ref={containerRef}
        className="absolute z-50 bg-white shadow-lg rounded-lg p-4"
      >
        <p className="text-gray-500">No products available</p>
      </div>
    );
  }

  return (
    <div ref={containerRef} className="absolute z-50 h-[500px]  left-0 right-0">
      <div ref={dropdownRef} className="w-full z-50  ">
        <div className="h-2"></div>
        <div className="w-full bg-white shadow-lg rounded-lg p-6 border border-gray-100 ">
          <div className="flex ">
            {/* Categories Column */}
            <div className="w-2/3 pr-6 max-h-[400px] xxl:max-h-[550px] overflow-auto custom-scrollbar">
              <div className="grid grid-cols-2 gap-x-8">
                {categoryDetails.categories.map((category, index) => (
                  <div
                    key={category.id || category.category_id}
                    className={`${index > 1 ? "mt-8" : ""}`}
                  >
                    <Link
                      to=""
                      className="relative block text-gray-950 hover:text-red-600 font-medium border-b border-gray-200 pb-2 mb-3 group"
                      onClick={() => {
                        if (onClose) onClose();
                        document.activeElement.blur();
                      }}
                      onMouseEnter={() => handleCategoryMouseEnter(category)}
                      onMouseLeave={handleCategoryMouseLeave}
                    >
                      {category.name || category.category_name}
                      <span className="absolute bottom-0 left-0 w-0  bg-red-600 transition-all duration-300 group-hover:w-full"></span>
                    </Link>
                    {category.products?.length > 0 && (
                      <div className="flex flex-col space-y-2">
                        {category.products.map((product) => (
                          <Link
                            key={product.id || product.product_id}
                            to={`/product/${product.id || product.product_id}`}
                            className="flex items-center justify-between text-gray-950 hover:text-red-600 text-sm group/item"
                            onMouseEnter={() =>
                              handleProductMouseEnter(product)
                            }
                            onMouseLeave={handleProductMouseLeave}
                            onClick={(e) => {
                              // Prevent default link behavior temporarily
                              e.preventDefault();
                              // Call our handler to ensure dropdown closes
                              handleProductClick(
                                product.id || product.product_id
                              );
                              // Navigate programmatically after a slight delay to ensure dropdown closes
                              setTimeout(() => {
                                window.location.href = `/product/${
                                  product.id || product.product_id
                                }`;
                              }, 10);
                            }}
                          >
                            <div className="flex items-center gap-2">
                              <span>{product.name}</span>
                              {product.status && (
                                <span
                                  className={`px-2 py-0.5 text-xs rounded-full ${
                                    product.status === "Best Seller"
                                      ? "bg-orange-100 text-orange-600"
                                      : product.status.includes("%")
                                      ? "bg-red-100 text-red-600"
                                      : "bg-green-100 text-green-600"
                                  }`}
                                >
                                  {product.status}
                                </span>
                              )}
                            </div>
                          </Link>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>

            {/* Image Column */}
            <div className="w-1/3 border-l border-gray-100 pl-6 ">
              {hoveredProduct ? (
                <div className=" flex flex-col items-center justify-center">
                  {hoveredProduct?.images?.length > 0 && (
                    <div className="w-full h-48  mb-4 overflow-hidden rounded-lg bg-gray-50 flex items-center justify-center">
                      <img
                        src={hoveredProduct.images[0]}
                        alt={hoveredProduct.name}
                        className="w-full h-full object-contain"
                        onError={(e) => {
                          console.error(
                            "Product image failed to load:",
                            hoveredProduct.images[0]
                          );
                          e.target.src = "/placeholder.svg";
                        }}
                      />
                    </div>
                  )}
                  {hoveredProduct?.images?.length > 0 && (
                    <>
                      <h3 className="font-medium text-gray-900 mb-1">
                        {hoveredProduct.name}
                      </h3>
                      {hoveredProduct.price && (
                        <p className="text-red-600 font-medium">
                          {hoveredProduct.price} AED
                        </p>
                      )}
                      {hoveredProduct.description && (
                        <p className="text-sm text-gray-500 mt-2 line-clamp-3 overflow-hidden">
                          {hoveredProduct.description}
                        </p>
                      )}
                    </>
                  )}
                </div>
              ) : hoveredCategory ? (
                <div className=" flex flex-col items-center justify-center">
                  {hoveredCategory?.imageUrl && (
                    <div className="w-full h-48 mb-4 overflow-hidden rounded-lg  flex items-center justify-center">
                      <img
                        src={
                          hoveredCategory.imageUrl.startsWith("http")
                            ? hoveredCategory.imageUrl
                            : `https://dash.brandexperts.ae${hoveredCategory.imageUrl}`
                        }
                        alt={hoveredCategory.name}
                        className="w-full h-full object-contain"
                        onError={(e) => {
                          console.error(
                            "Category image failed to load:",
                            hoveredCategory.imageUrl
                          );
                          e.target.src = "/placeholder.svg";
                        }}
                      />
                    </div>
                  )}
                  {hoveredCategory?.imageUrl && (
                    <>
                      <h3 className="font-medium text-gray-900 mb-1">
                        {hoveredCategory.name || hoveredCategory.category_name}
                      </h3>
                      {hoveredCategory.description && (
                        <p className="text-sm text-gray-500 mt-2 line-clamp-3 overflow-hidden">
                          {hoveredCategory.description}
                        </p>
                      )}
                      {hoveredCategory.products?.length > 0 && (
                        <p className="text-xs text-gray-400 mt-1">
                          {hoveredCategory.products.length} products available
                        </p>
                      )}
                    </>
                  )}
                </div>
              ) : (
                <>
                  {categoryDetails?.parentCategoryImage && (
                    <div className="flex flex-col items-center justify-center">
                      {categoryDetails?.parentCategoryImage ? (
                        <img
                          src={
                            categoryDetails.parentCategoryImage.startsWith(
                              "http"
                            )
                              ? categoryDetails.parentCategoryImage
                              : `https://dash.brandexperts.ae${categoryDetails.parentCategoryImage}`
                          }
                          alt="Parent Category"
                          className="w-full h-48 object-contain mb-4"
                          onError={(e) => {
                            console.error(
                              "Parent image failed to load:",
                              categoryDetails.parentCategoryImage
                            );
                            e.target.src = "/placeholder.svg";
                          }}
                        />
                      ) : parentCategoryImage ? (
                        <img
                          src={
                            parentCategoryImage.startsWith("http")
                              ? parentCategoryImage
                              : `https://dash.brandexperts.ae${parentCategoryImage}`
                          }
                          alt="Parent Category (Fallback)"
                          className="w-full h-48 object-contain mb-4"
                          onError={(e) => {
                            console.error(
                              "Fallback image failed to load:",
                              parentCategoryImage
                            );
                            e.target.src = "/placeholder.svg";
                          }}
                        />
                      ) : (
                        <div className="w-full h-48 flex items-center justify-center bg-gray-100 mb-4">
                          <span className="text-gray-400">
                            No image available
                          </span>
                        </div>
                      )}
                      {/* Parent Category Name */}
                      {categoryDetails?.parentCategoryName && (
                        <h3 className="font-medium text-gray-900 mb-2">
                          {categoryDetails.parentCategoryName}
                        </h3>
                      )}
                      {/* Parent Category Description */}
                      {categoryDetails?.description && (
                        <p className="text-sm text-gray-950 text-center line-clamp-3 overflow-hidden">
                          {categoryDetails.description}
                        </p>
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryDropdown;