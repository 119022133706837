"use client"

import { useEffect, useState } from "react"
import { CheckCircle } from "lucide-react"
import { Link } from "react-router-dom"
import { useQueryClient } from "@tanstack/react-query"
import AlertVerificationModal from "../../../components/AlertVerificationModal.tsx"
import { toast } from "react-hot-toast"
import axios from "axios"

export default function OrderConfirmation() {
  const queryClient = useQueryClient()
  const orderNumber = Math.random().toString(36).substr(2, 9).toUpperCase()
  const [showVerification, setShowVerification] = useState(false)
  const [verificationType, setVerificationType] = useState(null)
  const [userDetails, setUserDetails] = useState(null)
  const [delayPassed, setDelayPassed] = useState(false)
  const [verificationError, setVerificationError] = useState(null)
  const [showEmailPrompt, setShowEmailPrompt] = useState(false)
  const [email, setEmail] = useState("")
  const [isSubmittingEmail, setIsSubmittingEmail] = useState(false)
  const [otpSent, setOtpSent] = useState(false)
  const [otp, setOtp] = useState("")
  const [isVerifyingOtp, setIsVerifyingOtp] = useState(false)

  useEffect(() => {
    try {
      const details = localStorage.getItem("user_details")
      if (details) {
        const userData = JSON.parse(details)
        setUserDetails(userData)

        // First check verification status
        if (!userData?.verified_email && userData?.email && userData.email.trim() !== "") {
          setVerificationType("email")
          setShowVerification(true)
        } else if (!userData?.verified_mobile && userData?.mobile) {
          setVerificationType("mobile")
          setShowVerification(true)
        }
        // Then check if email is null or empty
        else if (!userData?.email || userData.email.trim() === "") {
          // Delay showing the email prompt to ensure order confirmation is seen first
          setTimeout(() => {
            setShowEmailPrompt(true)
          }, 2000)
        }
      }
    } catch (error) {
      console.error("Error loading user details:", error)
    }
  }, [])

  useEffect(() => {
    if (showVerification) {
      const timer = setTimeout(() => {
        setDelayPassed(true)
      }, 2000)

      return () => clearTimeout(timer)
    }
  }, [showVerification])

  const handleVerificationComplete = () => {
    try {
      // Reset error state
      setVerificationError(null)

      // Update local storage with new verification status
      const updatedDetails = {
        ...userDetails,
        [verificationType === "email" ? "verified_email" : "verified_mobile"]: true,
      }

      localStorage.setItem("user_details", JSON.stringify(updatedDetails))
      setUserDetails(updatedDetails)
      setShowVerification(false)

      // Invalidate any relevant queries
      queryClient.invalidateQueries({ queryKey: ["userDetails"] })
    } catch (error) {
      console.error("Error updating verification status:", error)
      setVerificationError("Failed to update verification status. Please try again.")
    }
  }

  const handleSendEmailOtp = async () => {
    if (!email || !email.trim()) {
      toast.error("Please enter a valid email address")
      return
    }

    setIsSubmittingEmail(true)
    setVerificationError(null)

    try {
      const response = await axios.post("https://dash.brandexperts.ae/auth/send-email-otp/", {
        user_id: userDetails?.user_id,
        email: email.trim(),
      })

      if (response.data.success) {
        toast.success(response.data.message || "OTP sent to your email")
        setOtpSent(true)
      } else {
        setVerificationError(response.data.message || "Failed to send OTP")
        toast.error(response.data.message || "Failed to send OTP")
      }
    } catch (error) {
      console.error("Error sending email OTP:", error)
      const errorMessage = error.response?.data?.message || "Failed to send OTP. Please try again."
      setVerificationError(errorMessage)
      toast.error(errorMessage)
    } finally {
      setIsSubmittingEmail(false)
    }
  }

  const handleVerifyEmailOtp = async () => {
    if (!otp || otp.length !== 6) {
      toast.error("Please enter a valid 6-digit OTP")
      return
    }

    setIsVerifyingOtp(true)
    setVerificationError(null)

    try {
      const response = await axios.post("https://dash.brandexperts.ae/auth/verify-email/", {
        user_id: userDetails?.user_id,
        otp: otp,
      })

      if (response.data.success) {
        toast.success(response.data.message || "Email verified successfully")

        // Update user details in localStorage
        const updatedDetails = {
          ...userDetails,
          email: email,
          verified_email: true,
        }

        localStorage.setItem("user_details", JSON.stringify(updatedDetails))
        setUserDetails(updatedDetails)

        // Close the email prompt
        setShowEmailPrompt(false)

        // Invalidate any relevant queries
        queryClient.invalidateQueries({ queryKey: ["userDetails"] })
      } else {
        setVerificationError(response.data.message || "Verification failed")
        toast.error(response.data.message || "Verification failed")
      }
    } catch (error) {
      console.error("Error verifying email OTP:", error)
      const errorMessage = error.response?.data?.message || "Failed to verify OTP. Please try again."
      setVerificationError(errorMessage)
      toast.error(errorMessage)
    } finally {
      setIsVerifyingOtp(false)
    }
  }

  return (
    <>
      <div className="max-w-2xl mx-auto text-center pt-25 pb-28">
        <div className="bg-white rounded-lg shadow p-8">
          <div className="flex justify-center">
            <CheckCircle className="h-16 w-16 text-green-500" />
          </div>

          <h2 className="mt-6 text-2xl font-semibold text-gray-900">Thank you for your order!</h2>

          <p className="mt-2 text-gray-600">Your order has been placed and will be processed soon.</p>

          <div className="mt-8 space-y-4">
            <p className="text-sm text-gray-600">Order confirmation has been sent to your email.</p>
            <p className="text-sm text-gray-600">Order number: #{orderNumber}</p>
          </div>

          <div className="mt-8 space-x-4">
            <Link
              to="/orders"
              className="inline-block bg-gradient-to-b from-[#BF1A1C] to-[#590C0D] text-white px-6 py-3 rounded-lg"
            >
              View Orders
            </Link>
            <Link to="/products" className="inline-block px-6 py-3 border border-gray-300 rounded-lg">
              Continue Shopping
            </Link>
          </div>
        </div>
      </div>

      {showVerification && delayPassed && (
        <AlertVerificationModal
          type={verificationType}
          userId={userDetails?.user_id}
          onVerify={handleVerificationComplete}
          onSkip={() => {
            setVerificationError(null)
            setShowVerification(false)
          }}
          onClose={() => {
            setVerificationError(null)
            setShowVerification(false)
          }}
          error={verificationError}
        />
      )}

      {showEmailPrompt && (
        <div
          className="fixed inset-0 bg-opacity-50 flex items-center justify-center z-50"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4 shadow-xl">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-semibold">
                {otpSent ? "Verify Email" : "Would you like email notifications?"}
              </h3>
              <button
                onClick={() => setShowEmailPrompt(false)}
                className="text-gray-500 hover:text-gray-700 transition-colors"
              >
                <span className="text-xl">&times;</span>
              </button>
            </div>

            {!otpSent ? (
              <div className="space-y-4">
                <p className="text-gray-600 mb-4">
                  Would you like to receive order updates and notifications via email?
                </p>

                {verificationError && (
                  <div className="bg-red-50 p-3 rounded-lg">
                    <p className="text-red-600 text-sm">⚠️ {verificationError}</p>
                  </div>
                )}

                <div className="space-y-4">
                  <div className="flex flex-col">
                    <label htmlFor="email" className="text-sm text-gray-600 mb-1">
                      Email Address
                    </label>
                    <input
                      type="email"
                      id="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-red-500 focus:border-red-500"
                      placeholder="Enter your email address"
                    />
                  </div>
                </div>

                <div className="flex gap-3">
                  <button
                    onClick={handleSendEmailOtp}
                    disabled={isSubmittingEmail || !email}
                    className="flex-1 bg-gradient-to-b from-[#BF1A1C] to-[#590C0D] text-white py-2 rounded-lg hover:opacity-90 disabled:opacity-50 transition-colors"
                  >
                    {isSubmittingEmail ? "Sending..." : "Verify"}
                  </button>
                  <button
                    onClick={() => setShowEmailPrompt(false)}
                    className="flex-1 border border-gray-300 py-2 rounded-lg hover:bg-gray-50 transition-colors"
                  >
                    Skip
                  </button>
                </div>
              </div>
            ) : (
              <div className="space-y-4">
                <p className="text-gray-600 mb-4">Enter the verification code sent to {email}</p>

                {verificationError && (
                  <div className="bg-red-50 p-3 rounded-lg">
                    <p className="text-red-600 text-sm">⚠️ {verificationError}</p>
                  </div>
                )}

                <div className="space-y-4">
                  <input
                    type="text"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value.replace(/\D/g, ""))}
                    className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-red-500 focus:border-red-500 text-center text-lg"
                    placeholder="Enter 6-digit code"
                    maxLength={6}
                  />
                </div>

                <div className="flex gap-3">
                  <button
                    onClick={handleVerifyEmailOtp}
                    disabled={isVerifyingOtp || otp.length !== 6}
                    className="flex-1 bg-gradient-to-b from-[#BF1A1C] to-[#590C0D] text-white py-2 rounded-lg hover:opacity-90 disabled:opacity-50 transition-colors"
                  >
                    {isVerifyingOtp ? "Verifying..." : "Confirm"}
                  </button>
                  <button
                    onClick={() => {
                      setOtpSent(false)
                      setOtp("")
                    }}
                    className="flex-1 border border-gray-300 py-2 rounded-lg hover:bg-gray-50 transition-colors"
                  >
                    Back
                  </button>
                </div>

                <button
                  onClick={handleSendEmailOtp}
                  disabled={isSubmittingEmail}
                  className="text-sm text-red-600 hover:text-red-700 w-full text-center disabled:opacity-50"
                >
                  {isSubmittingEmail ? "Sending..." : "Resend code"}
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  )
}

