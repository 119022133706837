const PrivacyAndPolicies = () => {
    return (
      <div className="container mx-auto px-4 my-4">
        <h1 className="text-lg font-bold mb-4">Privacy Policy</h1>
        <p className="mb-4">
          This Privacy Statement applies to the data and information collected by
          BrandExperts through the website and how that information is used.
        </p>
  
        <h2 className="text-lg font-semibold mt-6">
          Collection and Use of Personal Information
        </h2>
        <p className="mt-2">BrandExperts collects the following information:</p>
        <ul className="list-disc ml-6 mt-2">
          <li>
            Contact information (name, email address, mailing address, phone
            number)
          </li>
          <li>Billing information (credit card number, billing address)</li>
          <li>Order information (uploaded files, order history)</li>
        </ul>
        <p className="mt-4">
          BrandExperts gathers information about your computer, including:
        </p>
        <ul className="list-disc ml-6 mt-2">
          <li>IP address</li>
          <li>Referring/Exit pages</li>
        </ul>
  
        <h2 className="text-lg font-semibold mt-6">Use of Information</h2>
        <p className="mt-2">This information is used to:</p>
        <ul className="list-disc ml-6 mt-2">
          <li>Fulfill your order</li>
          <li>
            Send you order confirmations, shipping notifications, artwork upload
            notifications
          </li>
          <li>Reply to customer service-related requests</li>
          <li>Oversee your account</li>
          <li>Email marketing and newsletters</li>
          <li>Enhance website and marketing campaigns</li>
        </ul>
  
        <h2 className="text-lg font-semibold mt-6">Sharing of Information</h2>
        <p className="mt-2">
          BrandExperts does not sell personal information to third parties.
        </p>
  
        <h2 className="text-lg font-semibold mt-6">Cookies and Tracking</h2>
        <p className="mt-2">
          Cookies may be used to track preferences and profile information. These
          cookies do not collect personal information. They are used to enhance
          website browsing and collect general usage-related statistics.
        </p>
  
        <h2 className="text-lg font-semibold mt-6">Web Beacons</h2>
        <p className="mt-2">
          BrandExperts uses a third party to compile information about how our
          visitors use the website. This helps us understand how our site is used
          and allows us to enhance website use.
        </p>
  
        <h2 className="text-lg font-semibold mt-6">Account Information</h2>
        <p className="mt-2">
          If your account information is incorrect or needs to be changed, you can
          do so by logging in to your BrandExperts account online. Go to Your
          Account &gt; Account Info.
        </p>
  
        <h2 className="text-lg font-semibold mt-6">
          Notification of Changes to Privacy Policy
        </h2>
        <p className="mt-2">
          This policy may be updated and changed at any time without notice.
          Please check the policy periodically for any changes.
        </p>
  
        <h2 className="text-lg font-semibold mt-6">Questions or Concerns?</h2>
        <p className="mt-2">Customer Support Team</p>
        <p>
          Email:{" "}
          <a href="mailto:hello@brandexperts.ae" className="text-blue-500">
            hello@brandexperts.ae
          </a>
        </p>
        <p>Phone: +971-06-531-4088</p>
      </div>
    );
  };
  
  export default PrivacyAndPolicies;