import { useState, useEffect } from "react";
import { UploadCloud } from "lucide-react";
import toast from "react-hot-toast";
import register from "../../assets/images/Illustration-min.png";
import API from "../loginSignin/Api";
import { useNavigate } from "react-router-dom";
import WarrantyClaimModal from "../../components/WarrantyClaimModal";
import { uploadInvoice } from "../../services/warrantyStorageService";
import { motion } from 'framer-motion';

// Extracted WarrantyForm component outside of Warranty
const WarrantyForm = ({
  activeTab,
  formData,
  selectedValue,
  customValue,
  handleInputChange,
  handleValueChange,
  handleFileChange,
  handleSubmit,
  loading,
  claimData,
  handleClaimChange,
  handleClaimSubmit,
  verificationStatus,
  verifiedWarranty,
  priceRanges,
  selectedPriceRange,
  handlePriceRangeChange,
  warrantyPlans,
  isWarrantyValid, // Add this prop
  validateWarranty, // Add this prop
  isValidating, // Add this prop
  warrantyDetails // Add this new prop to receive warranty details
}) => {
  if (activeTab === "register") {
    return (
      <div className="grid grid-cols-1 lg:grid-cols-5 gap-4 lg:gap-8 ">
        <div className="lg:col-span-3 space-y-4">
          <h2 className="text-xl font-semibold">Extend Your AMC Coverage</h2>
          <p className="text-gray-600 text-sm">
          Protect your purchase with our comprehensive AMC plans
          </p>

          <form onSubmit={handleSubmit} className="space-y-4">
            {/* Registration form content - unchanged */}
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm mb-1">
                  Name <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="full_name"
                  value={formData.full_name}
                  onChange={handleInputChange}
                  placeholder="Enter your Full Name"
                  className="w-full py-1.5 px-3 text-sm border border-gray-200 rounded-md focus:outline-none focus:border-red-500"
                  required
                />
              </div>
              <div>
                <label className="block text-sm mb-1">
                  Email <span className="text-red-500">*</span>
                </label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  placeholder="your.email@gmail.com"
                  className="w-full py-1.5 px-3 text-sm border border-gray-200 rounded-md focus:outline-none focus:border-red-500"
                  required
                />
              </div>
              <div>
                <label className="block text-sm mb-1">
                  Phone <span className="text-red-500">*</span>
                </label>
                <input
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  placeholder="Enter Phone number"
                  className="w-full py-1.5 px-3 text-sm border border-gray-200 rounded-md focus:outline-none focus:border-red-500"
                  required
                />
              </div>
              <div>
                <label className="block text-sm mb-1">
                  Invoice Number <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="product_name"
                  value={formData.product_name}
                  onChange={handleInputChange}
                  placeholder="Enter Invoice Number"
                  className="w-full py-1.5 px-3 text-sm border border-gray-200 rounded-md focus:outline-none focus:border-red-500"
                  required
                />
              </div>
              <div>
                <label className="block text-sm mb-1">
                  Invoice date <span className="text-red-500">*</span>
                </label>
                <input
                  type="date"
                  name="invoice_date"
                  value={formData.invoice_date}
                  onChange={handleInputChange}
                  placeholder="Select date"
                  className="w-full py-1.5 px-3 text-sm border border-gray-200 rounded-md focus:outline-none focus:border-red-500"
                  required
                />
              </div>
              <div className="space-y-2">
                <label className="block text-sm mb-1">
                  Invoice Value (AED) <span className="text-red-500">*</span>
                </label>
                <select
                  value={selectedPriceRange}
                  onChange={handlePriceRangeChange}
                  className="w-full py-1.5 px-3 text-sm border border-gray-200 rounded-md focus:outline-none focus:border-red-500"
                  required
                >
                  <option value="">Select Price Range</option>
                  {priceRanges.map((range) => (
                    <option key={range.price_range} value={range.price_range}>
                      {range.price_range}
                    </option>
                  ))}
                </select>
              </div>
              {selectedPriceRange && warrantyPlans && (
                <div className="space-y-2">
                  <label className="block text-sm">Select warranty plan</label>
                  <div className="space-y-2">
                    <label className="flex items-center space-x-2">
                      <input
                        type="radio"
                        name="warranty_plan_amount"
                        value={warrantyPlans.year1}
                        onChange={handleInputChange}
                        className="text-red-500"
                      />
                      <span className="text-sm">
                        {warrantyPlans.year1} AED - 1 year
                      </span>
                    </label>
                    <label className="flex items-center space-x-2">
                      <input
                        type="radio"
                        name="warranty_plan_amount"
                        value={warrantyPlans.year2}
                        onChange={handleInputChange}
                        className="text-red-500"
                      />
                      <span className="text-sm">
                        {warrantyPlans.year2} AED - 2 years
                      </span>
                    </label>
                    <label className="flex items-center space-x-2">
                      <input
                        type="radio"
                        name="warranty_plan_amount"
                        value={warrantyPlans.year5}
                        onChange={handleInputChange}
                        className="text-red-500"
                      />
                      <span className="text-sm">
                        {warrantyPlans.year5} AED - 5 years
                      </span>
                    </label>
                  </div>
                </div>
              )}
            </div>

            <div>
              <label className="block text-sm mb-1">
                Upload Invoice <span className="text-red-500">*</span>
              </label>
              <div className="border border-gray-200 rounded-md py-1.5 px-3 flex flex-wrap items-center gap-2">
                <input
                  type="file"
                  id="invoiceUpload"
                  accept=".pdf,.jpg,.jpeg,.png"
                  className="hidden"
                  onChange={handleFileChange}
                />
                <button
                  type="button"
                  onClick={() =>
                    document.getElementById("invoiceUpload").click()
                  }
                  className="flex items-center text-sm text-gray-500 hover:text-gray-700"
                >
                  <UploadCloud className="w-4 h-4 mr-2" />
                  Choose file
                </button>
                <span id="fileName" className="text-sm text-gray-500 break-all">
                  No file chosen
                </span>
                <input
                  type="hidden"
                  name="invoice_file"
                  value={formData.invoice_file || ""}
                  required
                />
              </div>
            </div>

            <div className="flex justify-center">
              <button
                type="submit"
                disabled={loading}
                className="w-full sm:w-58 bg-gradient-to-b from-[#BF1A1C] to-[#590C0D] hover:shadow-xl text-white font-bold py-2.5 px-4 rounded-md transition-all duration-300 cursor-pointer hover:scale-[0.98] active:scale-95 disabled:opacity-70 disabled:cursor-not-allowed"
              >
                {loading ? "Processing..." : "Proceed to payment"}
              </button>
            </div>
          </form>
        </div>

        <div className="hidden lg:flex lg:col-span-2 items-center justify-center">
          <img
            src={register}
            alt="Warranty Registration"
            className="w-full max-w-md h-auto"
          />
        </div>
      </div>
    );
  }

  
  else {
    return (
      <div className="grid grid-cols-1 lg:grid-cols-5 gap-4 lg:gap-8">
        <div className="lg:col-span-3 space-y-4">
          <h2 className="text-xl font-semibold">Register Service Request</h2>
  
          <form onSubmit={handleClaimSubmit} className="space-y-4">
            <div>
              <label className="block text-sm mb-1">
                AMC Registration Number{" "}
                <span className="text-red-500">*</span>
              </label>
              <div className="relative">
                <input
                  type="text"
                  name="warranty_number"
                  value={claimData.warranty_number}
                  onChange={(e) => handleClaimChange(e)}
                  placeholder="Enter your AMC Registration Number"
                  className="w-full py-1.5 px-3 text-sm border border-gray-300 rounded-md focus:outline-none focus:border-red-500"
                  required
                />
              </div>
            </div>
            
            {!isWarrantyValid && (
              <div className="p-4 bg-blue-50 border border-blue-200 text-gray-700 rounded-lg mb-5 animate-fadeIn">
                <div className="flex items-start">
                  <svg
                    className="w-5 h-5 text-blue-500 mt-0.5 mr-2 flex-shrink-0"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fillRule="evenodd"
                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2h-1V9z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <div>
                    <p className="font-medium text-blue-800">
                      Please enter your AMC Registration number and click the Validate button to proceed.
                    </p>
                    <ul className="mt-1 text-sm text-gray-500 list-disc list-inside space-y-1 ">
                      <li>Once validated, you can submit your claim with a
                      description of the issue.</li>
                      <li>If you don't remember your AMC Registration number, please check your <span className="underline ">email inbox</span> or spam folder.</li>
                    </ul>
                  </div>
                </div>
              </div>
            )}
            
            {/* Add warranty verification message */}
            {isWarrantyValid && warrantyDetails && (
              <div className="p-4 bg-green-50 border border-green-200 text-gray-700 rounded-lg mb-5 animate-fadeIn">
                <div className="flex items-start">
                  <svg 
                    className="w-5 h-5 text-green-500 mt-0.5 mr-2 flex-shrink-0" 
                    fill="currentColor" 
                    viewBox="0 0 20 20"
                  >
                    <path 
                      fillRule="evenodd" 
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" 
                      clipRule="evenodd" 
                    />
                  </svg>
                  <div>
                    <p className="font-medium text-green-800 mb-2">
                      WARRANTY NUMBER VERIFICATION
                    </p>
                    <p className="text-sm text-gray-600 mb-3">
                      {warrantyDetails.message}
                    </p>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 mt-2 bg-white p-3 rounded-md border border-green-100">
                      <div>
                        <p className="text-xs text-gray-500">Customer Name</p>
                        <p className="text-sm font-medium">{warrantyDetails.warranty_details.full_name}</p>
                      </div>
                      <div>
                        <p className="text-xs text-gray-500">Invoice Number</p>
                        <p className="text-sm font-medium">{warrantyDetails.warranty_details.invoice_number}</p>
                      </div>
                      <div>
                        <p className="text-xs text-gray-500">Warranty Plan</p>
                        <p className="text-sm font-medium">{warrantyDetails.warranty_details.warranty_plan.duration_years} year(s) (AED {warrantyDetails.warranty_details.warranty_plan_amount})</p>
                      </div>
                      <div>
                        <p className="text-xs text-gray-500">Expiration Date</p>
                        <p className="text-sm font-medium">{warrantyDetails.warranty_details.expiration_date}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
  
            {!isWarrantyValid ? (
              <div className="flex justify-center mt-6">
                <button
                  type="button"
                  onClick={() => validateWarranty(claimData.warranty_number)}
                  disabled={loading || isValidating || !claimData.warranty_number}
                  className="w-full sm:w-56 bg-gradient-to-b from-[#BF1A1C] to-[#590C0D] hover:shadow-xl text-white font-bold py-3 px-6 cursor-pointer rounded-lg transition-all duration-300 transform hover:translate-y-[-1px] active:translate-y-[1px] active:shadow-md disabled:opacity-70 disabled:cursor-not-allowed disabled:hover:translate-y-0 disabled:active:translate-y-0"
                >
                  {isValidating ? (
                    <span className="flex items-center justify-center">
                      <span className="w-5 h-5 mr-2 border-2 border-white border-t-transparent rounded-full animate-spin"></span>
                      Validating...
                    </span>
                  ) : (
                    "Validate AMC"
                  )}
                </button>
              </div>
            ) : (
              <div className="space-y-5 animate-fadeIn">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1.5">
                    Claim message <span className="text-red-500">*</span>
                  </label>
                  <textarea
                    name="description"
                    value={claimData.description}
                    onChange={handleClaimChange}
                    disabled={loading}
                    placeholder="Please describe the issue you are experiencing in detail..."
                    className="w-full py-2.5 px-4 text-sm border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-red-200 focus:border-red-400 transition-colors duration-200 disabled:bg-gray-50 disabled:opacity-70 disabled:cursor-not-allowed"
                    rows="6"
                    required
                  ></textarea>
                  <p className="mt-1 text-xs text-gray-500">
                    Include when the issue started, specific symptoms, and any
                    troubleshooting steps you've already taken.
                  </p>
                </div>
  
                <div className="flex justify-center mt-6">
                  <button
                    type="submit"
                    disabled={loading}
                    className="w-full sm:w-56 bg-gradient-to-b from-[#BF1A1C] to-[#590C0D] hover:shadow-xl text-white font-bold py-3 px-6 cursor-pointer rounded-lg transition-all duration-300 transform hover:translate-y-[-1px] active:translate-y-[1px] active:shadow-md disabled:opacity-70 disabled:cursor-not-allowed disabled:hover:translate-y-0 disabled:active:translate-y-0"
                  >
                    {loading ? (
                      <span className="flex items-center justify-center">
                        <span className="w-5 h-5 mr-2 border-2 border-white border-t-transparent rounded-full animate-spin"></span>
                        Processing...
                      </span>
                    ) : (
                      "Claim Warranty"
                    )}
                  </button>
                </div>
              </div>
            )}
          </form>
        </div>
  
        <div className="hidden lg:flex lg:col-span-2 items-center justify-center">
          <img
            src={register}
            alt="Warranty Claim"
            className="w-full max-w-md h-auto"
          />
        </div>
      </div>
    );
  }
};



const Warranty = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("register");
  const [selectedValue, setSelectedValue] = useState("");
  const [customValue, setCustomValue] = useState("");
  const [formData, setFormData] = useState({
    full_name: "",
    email: "",
    phone: "",
    product_name: "",
    invoice_date: "",
    invoice_value: "",
    invoice_file: "",
    warranty_plan: "",
  });
  const [loading, setLoading] = useState(false);
  const [claimData, setClaimData] = useState({
    warranty_number: "",
    description: "",
  });
  const [showClaimModal, setShowClaimModal] = useState(false);
  const [claimDetails, setClaimDetails] = useState(null);
  const [priceRanges, setPriceRanges] = useState([]);
  const [warrantyPlans, setWarrantyPlans] = useState({});
  const [selectedPriceRange, setSelectedPriceRange] = useState("");

  // Add these new states for warranty validation
  const [isWarrantyValid, setIsWarrantyValid] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [validationTimeout, setValidationTimeout] = useState(null);
  const [warrantyDetails, setWarrantyDetails] = useState(null); // Store the warranty details from API
  

  // Fetch price ranges on component mount
  useEffect(() => {
    fetchPriceRanges();
  }, []);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    if (tab === "claim") {
      // Reset warranty validation when switching to claim tab
      setIsWarrantyValid(false);
      setWarrantyDetails(null);
      setClaimData({ warranty_number: "", description: "" });
    }
  };
  
  // Fetch price ranges from API
  const fetchPriceRanges = async () => {
    try {
      const response = await API.get("/dash/price-ranges/");
      setPriceRanges(response.data.price_ranges);
    } catch (error) {
      console.error("Error fetching price ranges:", error);
      toast.error("Failed to fetch price ranges");
    }
  };

  // Fetch warranty plans when price range changes
  const fetchWarrantyPlans = async (priceRange) => {
    try {
      const response = await API.get(
        `/dash/get-warranty-by-price-range/${priceRange}/`
      );
      setWarrantyPlans(response.data);
    } catch (error) {
      console.error("Error fetching warranty plans:", error);
      toast.error("Failed to fetch warranty plans");
    }
  };

  // Validate warranty number
  const validateWarranty = (warrantyNumber) => {
    // Clear any existing timeout
    if (validationTimeout) {
      clearTimeout(validationTimeout);
    }
    // If the warranty number is empty, reset validation state
    if (!warrantyNumber || !warrantyNumber.trim()) {
      console.warn("Warranty number is empty or invalid");
      setIsWarrantyValid(false);
      setIsValidating(false);
      setWarrantyDetails(null); // Clear warranty details
      return;
    }
    // Set validating state to show loading indicator
    setIsValidating(true);

    // Set a timeout to prevent too many API calls while typing
    const timeoutId = setTimeout(async () => {
      try {
        console.log(`Validating warranty number: ${warrantyNumber}`);

        // Make POST API call with warranty number in request body
        const response = await API.post(
          "/validate-warranty/",
          { warranty_number: warrantyNumber },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        // console.log("Validation response:", response.data);

        // Update validation state based on API response
        if (response.data && response.data.is_valid === true) {
          setIsWarrantyValid(true);
          setWarrantyDetails(response.data); // Store the full warranty details
          toast.success(response.data.message || "Warranty number verified");
        } else {
          setIsWarrantyValid(false);
          setWarrantyDetails(null); // Clear warranty details
          toast.error(response.data.message || "Invalid warranty number");
        }
      } catch (error) {
        console.error("Error validating warranty:", error);
        setIsWarrantyValid(false);
        setWarrantyDetails(null); // Clear warranty details
        toast.error("Failed to validate warranty number");
      } finally {
        setIsValidating(false);
      }
    }, 800); // Delay API call by 800ms while user is typing

    setValidationTimeout(timeoutId);
  };

  const handlePriceRangeChange = async (e) => {
    const selectedRange = e.target.value;
    setSelectedPriceRange(selectedRange);
    if (selectedRange) {
      await fetchWarrantyPlans(selectedRange);
    }
    setFormData((prev) => ({
      ...prev,
      price_range: selectedRange,
      warranty_plan_amount: "", // Reset warranty plan when price range changes
    }));
  };

  const handleValueChange = (e) => {
    const value = e.target.value;
    setSelectedValue(value);
    if (value !== "custom") {
      setCustomValue("");
      const numericValue = value.split("-")[1] || value.split("-")[0];
      setFormData((prev) => ({
        ...prev,
        invoice_value: numericValue,
      }));
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;

    if (type === "radio") {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
      return;
    }

    if (name === "invoice_value") {
      setCustomValue(value);
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
      return;
    }

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        setLoading(true);
        document.getElementById("fileName").textContent = file.name;

        // Upload to Bunny.net storage
        const uploadResult = await uploadInvoice(file);

        // Update form data with the CDN URL
        setFormData((prev) => ({
          ...prev,
          invoice_file: uploadResult.cdnUrl,
        }));

        toast.success("Invoice uploaded successfully");
      } catch (error) {
        console.error("Upload error:", error);
        toast.error("Failed to upload invoice");
        document.getElementById("fileName").textContent = "No file chosen";
      } finally {
        setLoading(false);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Validate required fields
    if (
      !formData.full_name ||
      !formData.email ||
      !formData.phone ||
      !formData.product_name ||
      !formData.invoice_date ||
      !selectedPriceRange ||
      !formData.invoice_file ||
      !formData.warranty_plan_amount
    ) {
      toast.error("Please fill out all required fields.");
      setLoading(false);
      return;
    }

    // Validate email format
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      toast.error("Please enter a valid email address.");
      setLoading(false);
      return;
    }

    // Validate phone number
    if (!/^\d+$/.test(formData.phone)) {
      toast.error("Please enter a valid phone number (only digits allowed).");
      setLoading(false);
      return;
    }

    // Validate warranty plan amount (ensure it's a valid number)
    const warrantyPlanAmount = formData.warranty_plan_amount;
    if (isNaN(Number(warrantyPlanAmount))) {
      toast.error("Invalid warranty plan amount.");
      setLoading(false);
      return;
    }

    try {
      const submitData = {
        full_name: formData.full_name,
        email: formData.email,
        phone: formData.phone,
        invoice_number: formData.product_name, // Assuming product_name is invoice_number
        invoice_date: formData.invoice_date,
        price_range: selectedPriceRange,
        invoice_file: formData.invoice_file,
        warranty_plan_amount: String(warrantyPlanAmount), // Keep as string
      };

      console.log("Submitting data:", submitData);

      const response = await API.post("/register-warranty/", submitData);

      if (response.data.message === "Proceed to payment") {
        toast.success("Proceeding to payment...");
        navigate("/warranty-payment", {
          state: {
            client_secret: response.data.client_secret,
            customer_id: response.data.customer_id,
            warranty_id: response.data.warranty_id,
            metadata: {
              name: formData.full_name,
              email: formData.email,
              warranty_plan_amount: response.data.metadata.warranty_plan_amount,
            },
          },
        });
      } else {
        toast.error("Unexpected response from server");
      }
    } catch (error) {
      if (error.response) {
        console.error("API Error Response:", error.response.data);
        alert(JSON.stringify(error.response.data, null, 2)); // Show full error
        toast.error("Server error. Please try again.");
      } else if (error.request) {
        console.error("API No Response:", error.request);
        alert("No response from server.");
        toast.error("No response from server.");
      } else {
        console.error("API Request Error:", error.message);
        alert("Request error: " + error.message);
        toast.error("An unexpected error occurred.");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleClaimChange = (e) => {
    const { name, value } = e.target;
    setClaimData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleClaimSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await API.post("create_claim_warranty/", claimData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.data) {
        // Store claim details and show modal
        setClaimDetails(response.data);
        setShowClaimModal(true);

        // Show success message
        toast.success("Warranty claim submitted successfully");

        // Reset form
        setClaimData({ warranty_number: "", description: "" });

        // Reset warranty validation state
        setIsWarrantyValid(false);
        setWarrantyDetails(null); // Clear warranty details
      }
    } catch (error) {
      console.error("Claim submission error:", error);
      const errorMessage =
        error.response?.data?.message ||
        error.response?.data?.error ||
        "Failed to submit warranty claim. Please try again.";
      toast.error(errorMessage, {
        duration: 5000,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
<div className="max-w-5xl mx-auto py-6 sm:py-8 px-4 mb-48">
      <div className="flex w-full sm:w-fit mx-auto border border-red-100 rounded-full p-1 mb-8 relative">
        {/* Animated background pill */}
        <motion.div
          className="absolute inset-y-1 rounded-full bg-gradient-to-b from-[#BF1A1C] to-[#590C0D]"
          initial={false}
          animate={{
            x: activeTab === "register" ? 0 : "100%",
            width: "49.5%",
          }}
          transition={{
            type: "spring",
            stiffness: 300,
            damping: 30,
            duration: 0.3
          }}
          style={{
            width: "calc(50% - 4px)",
            left: "2px",
            transformOrigin: "left center",
          }}
        />
        
        {/* Register button */}
        <motion.button
          className={`flex-1 sm:flex-none px-6 py-3 rounded-full text-sm z-10 relative ${
            activeTab === "register" ? "text-white" : "text-gray-700"
          }`}
          onClick={() => handleTabChange("register")}
          whileHover={{ scale: activeTab !== "register" ? 1.05 : 1 }}
          whileTap={{ scale: 0.95 }}
        >
          Extend AMC Coverage
        </motion.button>
        
        {/* Claim button */}
        <motion.button
          className={`flex-1 sm:flex-none px-4 py-2 rounded-full text-sm z-10 relative ${
            activeTab === "claim" ? "text-white" : "text-gray-700"
          }`}
          onClick={() => handleTabChange("claim")}
          whileHover={{ scale: activeTab !== "claim" ? 1.05 : 1 }}
          whileTap={{ scale: 0.95 }}
        >
          Register Service Request
        </motion.button>
      </div>
      
      <div className="bg-white rounded-lg shadow-xl border border-gray-200 p-4 sm:p-6">
        <WarrantyForm
          activeTab={activeTab}
          formData={formData}
          selectedValue={selectedValue}
          customValue={customValue}
          handleInputChange={handleInputChange}
          handleValueChange={handleValueChange}
          handleFileChange={handleFileChange}
          handleSubmit={handleSubmit}
          loading={loading}
          claimData={claimData}
          handleClaimChange={handleClaimChange}
          handleClaimSubmit={handleClaimSubmit}
          priceRanges={priceRanges}
          selectedPriceRange={selectedPriceRange}
          handlePriceRangeChange={handlePriceRangeChange}
          warrantyPlans={warrantyPlans}
          isWarrantyValid={isWarrantyValid}
          validateWarranty={validateWarranty}
          isValidating={isValidating}
          warrantyDetails={warrantyDetails} // Pass the warranty details to the form
        />
      </div>

      {showClaimModal && claimDetails && (
        <WarrantyClaimModal
          claimDetails={claimDetails}
          onClose={() => setShowClaimModal(false)}
        />
      )}
    </div>
  );
};

export default Warranty;
