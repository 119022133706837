import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { Eye, EyeOff } from 'lucide-react';
import { useNavigate } from "react-router-dom";
import { useModal } from "../context/ModalContext";
import { useAuth } from "../context/AuthContext";
import Api from "../pages/loginSignin/Api";
import toast from "react-hot-toast";

export default function ModalLogin() {
  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { closeModal, openModal } = useModal();
  const { login } = useAuth();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const loginMutation = useMutation({
    mutationFn: async (data) => {
      try {
        const response = await Api.post(`/login/`, {
          username: data.email,
          password: data.password,
          
        });
        console.log(response.data);
        return response.data;
      } catch (error) {
        throw new Error(error.response?.data?.error || "Login failed");
      }
      
    },
    onSuccess: (data) => {
      // Store tokens
      localStorage.setItem("access_token", data.access_token);
      localStorage.setItem("refresh_token", data.refresh_token);
      
      // Store IDs in sessionStorage for consistency with main login
      sessionStorage.setItem("user_id", data.user_id);
      sessionStorage.setItem("customer_id", data.customer_id);
      
      // Store complete user details
      const userDetails = {
        ...data.user_details,
        user_id: data.user_id,
        customer_id: data.customer_id,
      };
      localStorage.setItem("user_details", JSON.stringify(userDetails));
      
      // Create user object for context
      const userData = {
        id: data.user_id,
        customer_id: data.customer_id,
        name: data.user_details.first_name || data.user_details.username || data.user_details.email.split('@')[0],
        email: data.user_details.email,
        mobile: data.user_details.mobile,
        is_partner: data.user_details.is_partner || false
      };
      
      // Use consistent user data for auth context
      login(userData);
      
      toast.success("Successfully logged in!");
      
      // Close modal and navigate
      closeModal();
      
      // Add a small delay before navigation for state to update
      setTimeout(() => {
        navigate("/checkout");
      }, 100);
      
      // Reset submission state
      setIsSubmitting(false);
    },
    onError: (error) => {
      toast.error(error.message || "Login failed");
      // Reset submission state on error
      setIsSubmitting(false);
    },
  });

  const onSubmit = (data) => {
    setIsSubmitting(true);
    loginMutation.mutate(data);
  };

  return (
    <div className="space-y-4">
      <div className="text-center">
        <h2 className="text-2xl font-bold">Welcome Back</h2>
        <p className="text-sm text-gray-500">Please sign in to continue</p>
      </div>

      <form
        className="space-y-4"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div>
          <label
            htmlFor="modal-email"
            className="block text-sm font-medium text-gray-700"
          >
            Email or WhatsApp Number
          </label>
          <div className="mt-1">
            <input
              id="modal-email"
              {...register("email", {
                required: "Email or number is required",
                pattern: {
                  value: /^(\+?[0-9]{7,15}|[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,})$/i,
                  message: "Invalid email or number"
                }
              })}
              type="text"
              autoComplete="email"
              disabled={isSubmitting || loginMutation.isLoading}
              className="appearance-none block w-full px-3 py-2 border border-red-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
            />
            {errors.email && (
              <p className="mt-2 text-sm text-red-600">{errors.email.message}</p>
            )}
          </div>
        </div>

        <div>
          <label
            htmlFor="modal-password"
            className="block text-sm font-medium text-gray-700"
          >
            Password
          </label>
          <div className="mt-1 relative">
            <input
              id="modal-password"
              {...register("password", {
                required: "Password is required",
                minLength: {
                  value: 6,
                  message: "Password must be at least 6 characters long"
                }
              })}
              type={showPassword ? "text" : "password"}
              autoComplete="current-password"
              disabled={isSubmitting || loginMutation.isLoading}
              className="appearance-none block w-full px-3 py-2 border border-red-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
            />
            <button
              type="button"
              className="absolute inset-y-0 right-0 pr-3 flex items-center"
              onClick={() => setShowPassword(!showPassword)}
              disabled={isSubmitting || loginMutation.isLoading}
            >
              {showPassword ? (
                <Eye className="h-5 w-5 text-gray-400" />
              ) : (
                <EyeOff className="h-5 w-5 text-gray-400" />
              )}
            </button>
            {errors.password && (
              <p className="mt-2 text-sm text-red-600">
                {errors.password.message}
              </p>
            )}
          </div>
        </div>

        <div className="flex justify-center">
          <button
            type="submit"
            disabled={isSubmitting || loginMutation.isLoading}
            className="w-full bg-gradient-to-b from-[#BF1A1C] to-[#590C0D] hover:shadow-xl text-white font-bold py-2.5 px-4 rounded-md transition-all duration-300 cursor-pointer hover:scale-[0.98] active:scale-95 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {isSubmitting || loginMutation.isLoading ? (
              <span className="flex items-center justify-center">
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
                Logging in...
              </span>
            ) : (
              "Sign In"
            )}
          </button>
        </div>
      </form>

      {loginMutation.isError && (
        <div className="rounded bg-red-100 p-2 text-sm text-red-600">
          {loginMutation.error?.message || 
            "Login failed. Please check your credentials and try again."}
        </div>
      )}

      <p className="text-center text-sm text-gray-600">
        Don't have an account?{" "}
        <button
          onClick={() => {
            closeModal();
            setTimeout(() => {
              openModal("register");
            }, 100);
          }}
          className="font-medium text-red-600 hover:text-red-500"
          disabled={isSubmitting || loginMutation.isLoading}
        >
          Sign up
        </button>
      </p>
    </div>
  );
}
