export const CHECKOUT_STEPS = [
  {
    id: "cart",
    name: "Cart",
  },
  {
    id: "shipping",
    name: "Shipping",
  },
  {
    id: "payment",
    name: "Payment",
  },
];
