"use client"

import React from "react"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { Loader2 } from "lucide-react"

interface EmailVerificationPromptProps {
  onClose: () => void
}

export default function EmailVerificationPrompt({ onClose }: EmailVerificationPromptProps) {
  const [showModal, setShowModal] = useState(false)
  const [email, setEmail] = useState("")
  const [otp, setOtp] = useState("")
  const [otpSent, setOtpSent] = useState(false)
  const [isSubmittingEmail, setIsSubmittingEmail] = useState(false)
  const [isVerifyingOtp, setIsVerifyingOtp] = useState(false)
  const [verificationError, setVerificationError] = useState<string | null>(null)
  const [userId, setUserId] = useState<string | null>(null)

  useEffect(() => {
    // Get user details from localStorage
    try {
      const userDetails = localStorage.getItem("user_details")
      if (userDetails) {
        const userData = JSON.parse(userDetails)
        setUserId(userData.user_id)

        // Show modal after 2 seconds if email is empty
        if (!userData.email || userData.email.trim() === "") {
          const timer = setTimeout(() => {
            setShowModal(true)
          }, 2000)

          return () => clearTimeout(timer)
        }
      }
    } catch (error) {
      console.error("Error loading user details:", error)
    }
  }, [])

  const handleSendEmailOtp = async () => {
    if (!email || !email.trim()) {
      toast.error("Please enter a valid email address")
      return
    }

    setIsSubmittingEmail(true)
    setVerificationError(null)

    try {
      const response = await fetch("https://dash.brandexperts.ae/auth/send-email-otp/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: userId,
          email: email.trim(),
        }),
      })

      const data = await response.json()

      if (data.success) {
        toast.success(data.message || "OTP sent to your email")
        setOtpSent(true)
      } else {
        setVerificationError(data.message || "Failed to send OTP")
        toast.error(data.message || "Failed to send OTP")
      }
    } catch (error) {
      console.error("Error sending email OTP:", error)
      const errorMessage = "Failed to send OTP. Please try again."
      setVerificationError(errorMessage)
      toast.error(errorMessage)
    } finally {
      setIsSubmittingEmail(false)
    }
  }

  const handleVerifyEmailOtp = async () => {
    if (!otp || otp.length !== 6) {
      toast.error("Please enter a valid 6-digit OTP")
      return
    }

    setIsVerifyingOtp(true)
    setVerificationError(null)

    try {
      const response = await fetch("https://dash.brandexperts.ae/auth/verify-email/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: userId,
          otp: otp,
        }),
      })

      const data = await response.json()

      if (data.success) {
        toast.success(data.message || "Email verified successfully")

        // Update user details in localStorage
        try {
          const userDetailsStr = localStorage.getItem("user_details")
          if (userDetailsStr) {
            const userDetails = JSON.parse(userDetailsStr)
            const updatedDetails = {
              ...userDetails,
              email: email,
              verified_email: true,
            }
            localStorage.setItem("user_details", JSON.stringify(updatedDetails))

            // Also update user_data if it exists
            const userDataStr = localStorage.getItem("user_data")
            if (userDataStr) {
              const userData = JSON.parse(userDataStr)
              const updatedUserData = {
                ...userData,
                email: email,
              }
              localStorage.setItem("user_data", JSON.stringify(updatedUserData))
            }
          }
        } catch (error) {
          console.error("Error updating user details:", error)
        }

        // Close the modal
        setShowModal(false)
        onClose()
      } else {
        setVerificationError(data.message || "Verification failed")
        toast.error(data.message || "Verification failed")
      }
    } catch (error) {
      console.error("Error verifying email OTP:", error)
      const errorMessage = "Failed to verify OTP. Please try again."
      setVerificationError(errorMessage)
      toast.error(errorMessage)
    } finally {
      setIsVerifyingOtp(false)
    }
  }

  if (!showModal) return null

  return (
    <div
      className="fixed inset-0 bg-opacity-50 flex items-center justify-center z-50"
      style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
    >
      <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4 shadow-xl">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold">{otpSent ? "Verify Email" : "Would you like email notifications?"}</h3>
          <button
            onClick={() => {
              setShowModal(false)
              onClose()
            }}
            className="text-gray-500 hover:text-gray-700 transition-colors"
          >
            <span className="text-xl">&times;</span>
          </button>
        </div>

        {!otpSent ? (
          <div className="space-y-4">
            <p className="text-gray-600 mb-4">Would you like to receive order updates and notifications via email?</p>

            {verificationError && (
              <div className="bg-red-50 p-3 rounded-lg">
                <p className="text-red-600 text-sm">⚠️ {verificationError}</p>
              </div>
            )}

            <div className="space-y-4">
              <div className="flex flex-col">
                <label htmlFor="email" className="text-sm text-gray-600 mb-1">
                  Email Address
                </label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-red-500 focus:border-red-500"
                  placeholder="Enter your email address"
                />
              </div>
            </div>

            <div className="flex gap-3">
              <button
                onClick={handleSendEmailOtp}
                disabled={isSubmittingEmail || !email}
                className="flex-1 bg-gradient-to-b from-[#BF1A1C] to-[#590C0D] text-white py-2 rounded-lg hover:opacity-90 disabled:opacity-50 transition-colors"
              >
                {isSubmittingEmail ? "Sending..." : "Verify"}
              </button>
              <button
                onClick={() => {
                  setShowModal(false)
                  onClose()
                }}
                className="flex-1 border border-gray-300 py-2 rounded-lg hover:bg-gray-50 transition-colors"
              >
                Skip
              </button>
            </div>
          </div>
        ) : (
          <div className="space-y-4">
            <p className="text-gray-600 mb-4">Enter the verification code sent to {email}</p>

            {verificationError && (
              <div className="bg-red-50 p-3 rounded-lg">
                <p className="text-red-600 text-sm">⚠️ {verificationError}</p>
              </div>
            )}

            <div className="space-y-4">
              <input
                type="text"
                value={otp}
                onChange={(e) => setOtp(e.target.value.replace(/\D/g, ""))}
                className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-red-500 focus:border-red-500 text-center text-lg"
                placeholder="Enter 6-digit code"
                maxLength={6}
              />
            </div>

            <div className="flex gap-3">
              <button
                onClick={handleVerifyEmailOtp}
                disabled={isVerifyingOtp || otp.length !== 6}
                className="flex-1 bg-gradient-to-b from-[#BF1A1C] to-[#590C0D] text-white py-2 rounded-lg hover:opacity-90 disabled:opacity-50 transition-colors"
              >
                {isVerifyingOtp ? (
                  <div className="flex items-center justify-center">
                    <Loader2 className="animate-spin mr-2 h-4 w-4" />
                    Verifying...
                  </div>
                ) : (
                  "Confirm"
                )}
              </button>
              <button
                onClick={() => {
                  setOtpSent(false)
                  setOtp("")
                }}
                className="flex-1 border border-gray-300 py-2 rounded-lg hover:bg-gray-50 transition-colors"
              >
                Back
              </button>
            </div>

            <button
              onClick={handleSendEmailOtp}
              disabled={isSubmittingEmail}
              className="text-sm text-red-600 hover:text-red-700 w-full text-center disabled:opacity-50"
            >
              {isSubmittingEmail ? (
                <div className="flex items-center justify-center">
                  <Loader2 className="animate-spin mr-2 h-3 w-3" />
                  Sending...
                </div>
              ) : (
                "Resend code"
              )}
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

