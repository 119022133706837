"use client"
import { useState, useEffect } from "react"
import { useForm } from "react-hook-form"
import { useMutation } from "@tanstack/react-query"
import toast from "react-hot-toast"
import { Eye, EyeOff, X } from "lucide-react"
import Api from "./Api"
import LoadingSpinner from "../../components/Spinner"

const ForgotPasswordModal = ({ isOpen, onClose, initialIdentifier }) => {
  const [currentStep, setCurrentStep] = useState(1)
  const [sessionToken, setSessionToken] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [identifier, setIdentifier] = useState("")
  const [identifierType, setIdentifierType] = useState("")

  // Identifier form
  const {
    register: registerIdentifier,
    handleSubmit: handleSubmitIdentifier,
    formState: { errors: identifierErrors },
  } = useForm({
    defaultValues: {
      identifier: initialIdentifier || "",
    },
  })

  // OTP form
  const {
    register: registerOtp,
    handleSubmit: handleSubmitOtp,
    formState: { errors: otpErrors },
  } = useForm({
    defaultValues: {
      otp: "",
    },
  })

  // Password reset form
  const {
    register: registerPassword,
    handleSubmit: handleSubmitPassword,
    formState: { errors: passwordErrors },
    watch,
  } = useForm({
    defaultValues: {
      newPassword: "",
      confirmPassword: "",
    },
  })

  // Watch the password field for validation
  const newPassword = watch("newPassword")

  // Check if there's an identifier in session storage on component mount
  useEffect(() => {
    if (isOpen) {
      // First check if initialIdentifier prop is provided
      let identifierToUse = initialIdentifier

      // If no initialIdentifier was provided, check session storage
      if (!identifierToUse) {
        const sessionIdentifier = sessionStorage.getItem("identifier")
        if (sessionIdentifier) {
          identifierToUse = sessionIdentifier
        }
      }

      // If we have an identifier (from prop or session storage), use it
      if (identifierToUse) {
        setIdentifier(identifierToUse)

        // Determine if it's an email or phone number
        const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
        setIdentifierType(emailPattern.test(identifierToUse) ? "email" : "mobile")

        // Automatically send OTP and skip to step 2
        sendOtpMutation.mutate({ identifier: identifierToUse })
        setCurrentStep(2)
      }
    }
  }, [isOpen, initialIdentifier])

  // Send OTP mutation
  const sendOtpMutation = useMutation({
    mutationFn: async (data) => {
      setIsLoading(true)
      try {
        const response = await Api.post("/send-otp/", {
          identifier: data.identifier,
        })
        return response.data
      } catch (error) {
        throw new Error(error.response?.data?.error || "Failed to send OTP")
      } finally {
        setIsLoading(false)
      }
    },
    onSuccess: (data) => {
      setSessionToken(data.session_token)
      setIdentifier(data.identifier)
      setIdentifierType(data.identifier_type)
      toast.success("OTP sent successfully")
      setCurrentStep(2)
    },
    onError: (error) => {
      toast.error(error.message)
    },
  })

  // Verify OTP mutation
  const verifyOtpMutation = useMutation({
    mutationFn: async (data) => {
      setIsLoading(true)
      try {
        const response = await Api.post("/verify-otp2/", {
          session_token: sessionToken,
          otp: data.otp,
        })
        return response.data
      } catch (error) {
        throw new Error(error.response?.data?.error || "Failed to verify OTP")
      } finally {
        setIsLoading(false)
      }
    },
    onSuccess: (data) => {
      setSessionToken(data.reset_token)
      toast.success("OTP verified successfully")
      setCurrentStep(3)
    },
    onError: (error) => {
      toast.error(error.message)
    },
  })

  // Reset password mutation
  const resetPasswordMutation = useMutation({
    mutationFn: async (data) => {
      setIsLoading(true)
      try {
        const response = await Api.post("/reset-password/", {
          reset_token: sessionToken,
          new_password: data.newPassword,
          confirm_password: data.confirmPassword,
        })
        return response.data
      } catch (error) {
        throw new Error(error.response?.data?.error || "Failed to reset password")
      } finally {
        setIsLoading(false)
      }
    },
    onSuccess: () => {
      toast.success("Password reset successfully")
      setTimeout(() => {
        onClose()
      }, 2000)
    },
    onError: (error) => {
      toast.error(error.message)
    },
  })

  // Form submit handlers
  const onIdentifierSubmit = (data) => {
    sendOtpMutation.mutate(data)
  }

  const onOtpSubmit = (data) => {
    verifyOtpMutation.mutate(data)
  }

  const onPasswordSubmit = (data) => {
    resetPasswordMutation.mutate(data)
  }

  // Resend OTP handler
  const handleResendOtp = () => {
    sendOtpMutation.mutate({ identifier })
  }

  if (!isOpen) return null

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      style={{ backgroundColor: "rgba(0, 0, 0, 0.60)" }}
    >
      {isLoading && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.80)" }}
        >
          <LoadingSpinner />
        </div>
      )}

      <div className="bg-white rounded-lg p-6 w-full max-w-md relative">
        <button onClick={onClose} className="absolute top-4 right-4 text-gray-500 hover:text-gray-700">
          <X className="h-5 w-5" />
        </button>

        <h2 className="text-xl font-bold mb-4 text-center">
          {currentStep === 1 && "Forgot Password"}
          {currentStep === 2 && "Verify OTP"}
          {currentStep === 3 && "Reset Password"}
        </h2>

        {/* Step 1: Identifier Form (only shown if no identifier is found) */}
        {currentStep === 1 && (
          <form onSubmit={handleSubmitIdentifier(onIdentifierSubmit)} className="space-y-4">
            <p className="text-sm text-gray-600 mb-4">Enter your email or phone number to reset your password.</p>
            <div>
              <label htmlFor="identifier" className="block text-sm font-medium text-gray-700 mb-1">
                Email or Phone Number
              </label>
              <input
                id="identifier"
                type="text"
                className={`w-full px-3 py-2 border ${
                  identifierErrors.identifier ? "border-red-300" : "border-gray-300"
                } rounded-md shadow-sm focus:outline-none focus:ring-red-500 focus:border-red-500`}
                placeholder="Enter email or phone number"
                {...registerIdentifier("identifier", {
                  required: "Email or phone number is required",
                  validate: (value) => {
                    // Basic validation for email or phone number
                    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
                    const phonePattern = /^[0-9]{10}$/

                    if (emailPattern.test(value) || phonePattern.test(value)) {
                      return true
                    }
                    return "Please enter a valid email or phone number"
                  },
                })}
              />
              {identifierErrors.identifier && (
                <p className="mt-1 text-sm text-red-600">{identifierErrors.identifier.message}</p>
              )}
            </div>
            <button
              type="submit"
              className="w-full bg-gradient-to-b from-[#BF1A1C] to-[#590C0D] text-white font-bold py-2 px-4 rounded-md transition-all duration-300 hover:shadow-lg"
            >
              Send OTP
            </button>
          </form>
        )}

        {/* Step 2: OTP Verification */}
        {currentStep === 2 && (
          <form onSubmit={handleSubmitOtp(onOtpSubmit)} className="space-y-4">
            <p className="text-sm text-gray-600 mb-4">
              We've sent a 6-digit code to your {identifierType === "mobile" ? "phone number" : "email"} ({identifier}).
              Enter the code below to verify.
            </p>
            <div>
              <label htmlFor="otp" className="block text-sm font-medium text-gray-700 mb-1">
                Enter OTP
              </label>
              <input
                id="otp"
                type="text"
                className={`w-full px-3 py-2 border ${
                  otpErrors.otp ? "border-red-300" : "border-gray-300"
                } rounded-md shadow-sm focus:outline-none focus:ring-red-500 focus:border-red-500`}
                placeholder="Enter 6-digit OTP"
                {...registerOtp("otp", {
                  required: "OTP is required",
                  minLength: {
                    value: 6,
                    message: "Please enter the 6-digit OTP",
                  },
                })}
              />
              {otpErrors.otp && <p className="mt-1 text-sm text-red-600">{otpErrors.otp.message}</p>}
            </div>
            <div className="flex flex-col sm:flex-row sm:justify-between space-y-2 sm:space-y-0">
              <button type="button" onClick={handleResendOtp} className="text-sm text-red-600 hover:text-red-500">
                Resend OTP
              </button>
            </div>
            <button
              type="submit"
              className="w-full bg-gradient-to-b from-[#BF1A1C] to-[#590C0D] text-white font-bold py-2 px-4 rounded-md transition-all duration-300 hover:shadow-lg"
            >
              Verify OTP
            </button>
          </form>
        )}

        {/* Step 3: Reset Password */}
        {currentStep === 3 && (
          <form onSubmit={handleSubmitPassword(onPasswordSubmit)} className="space-y-4">
            <p className="text-sm text-gray-600 mb-4">Create a new password for your account.</p>
            <div>
              <label htmlFor="newPassword" className="block text-sm font-medium text-gray-700 mb-1">
                New Password
              </label>
              <div className="relative">
                <input
                  id="newPassword"
                  type={showPassword ? "text" : "password"}
                  className={`w-full px-3 py-2 border ${
                    passwordErrors.newPassword ? "border-red-300" : "border-gray-300"
                  } rounded-md shadow-sm focus:outline-none focus:ring-red-500 focus:border-red-500`}
                  placeholder="Enter new password"
                  {...registerPassword("newPassword", {
                    required: "New password is required",
                    minLength: {
                      value: 6,
                      message: "Password must be at least 6 characters long",
                    },
                  })}
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 pr-3 flex items-center"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <Eye className="h-5 w-5 text-gray-400" />
                  ) : (
                    <EyeOff className="h-5 w-5 text-gray-400" />
                  )}
                </button>
              </div>
              {passwordErrors.newPassword && (
                <p className="mt-1 text-sm text-red-600">{passwordErrors.newPassword.message}</p>
              )}
            </div>
            <div>
              <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700 mb-1">
                Confirm Password
              </label>
              <div className="relative">
                <input
                  id="confirmPassword"
                  type={showConfirmPassword ? "text" : "password"}
                  className={`w-full px-3 py-2 border ${
                    passwordErrors.confirmPassword ? "border-red-300" : "border-gray-300"
                  } rounded-md shadow-sm focus:outline-none focus:ring-red-500 focus:border-red-500`}
                  placeholder="Confirm new password"
                  {...registerPassword("confirmPassword", {
                    required: "Please confirm your password",
                    validate: (value) => value === newPassword || "Passwords do not match",
                  })}
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 pr-3 flex items-center"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  {showConfirmPassword ? (
                    <Eye className="h-5 w-5 text-gray-400" />
                  ) : (
                    <EyeOff className="h-5 w-5 text-gray-400" />
                  )}
                </button>
              </div>
              {passwordErrors.confirmPassword && (
                <p className="mt-1 text-sm text-red-600">{passwordErrors.confirmPassword.message}</p>
              )}
            </div>
            <button
              type="submit"
              className="w-full bg-gradient-to-b from-[#BF1A1C] to-[#590C0D] text-white font-bold py-2 px-4 rounded-md transition-all duration-300 hover:shadow-lg"
            >
              Reset Password
            </button>
          </form>
        )}
      </div>
    </div>
  )
}

export default ForgotPasswordModal

