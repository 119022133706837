//this is component that related to the security of users if a user is already logged in they cant navigate to login/register route 
//it will redirect to the home 
import { Navigate } from "react-router-dom";
import { useAuth } from "./AuthContext";

// This component is for routes that should only be accessible to non-authenticated users
const AuthRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();
  
  // If user is already authenticated, redirect to home
  if (isAuthenticated) {
    return <Navigate to="/" replace />;
  }
  
  return children;
};

export default AuthRoute;