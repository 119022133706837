import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Download } from "lucide-react";
import toast from "react-hot-toast";
import API from "../loginSignin/Api";

const WarrantyClaim = () => {
  const { warrantyNumber } = useParams();
  const navigate = useNavigate();
  const [claimDetails, setClaimDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [downloadingForm, setDownloadingForm] = useState(false);
  const [downloadingInvoice, setDownloadingInvoice] = useState(false);

  useEffect(() => {
    const storedData = sessionStorage.getItem("claimDetails");

    if (storedData) {
      try {
        const parsedData = JSON.parse(storedData);
        setClaimDetails(parsedData);
        setLoading(false);
        sessionStorage.removeItem("claimDetails");
      } catch (error) {
        console.error("Error parsing stored claim details:", error);
        fetchClaimDetails();
      }
    } else {
      fetchClaimDetails();
    }
  }, [warrantyNumber]);

  const fetchClaimDetails = async () => {
    try {
      const response = await API.get(`create_claim_warranty/${warrantyNumber}`);

      if (response.data) {
        setClaimDetails(response.data);
      } else {
        throw new Error("No data received");
      }
    } catch (error) {
      console.error("Fetch claim details error:", error);
      toast.error("Failed to fetch claim details");
      setTimeout(() => navigate("/warranty"), 3000);
    } finally {
      setLoading(false);
    }
  };

  const downloadInvoice = async () => {
    if (downloadingInvoice) return;
    
    try {
      setDownloadingInvoice(true);
      const invoiceUrl = claimDetails.warranty_details.invoice_file;
      const loadingToast = toast.loading("Downloading invoice...");

      const response = await fetch(invoiceUrl);
      if (!response.ok) throw new Error("Download failed");

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      const filename = invoiceUrl.split("/").pop() || "invoice";

      link.href = url;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      toast.dismiss(loadingToast);
      toast.success("Invoice downloaded successfully");
    } catch (error) {
      console.error("Download error:", error);
      toast.error("Failed to download invoice. Please try again.");
    } finally {
      setDownloadingInvoice(false);
    }
  };

  const downloadWarrantyForm = async () => {
    if (downloadingForm) return;

    try {
      setDownloadingForm(true);
      const loadingToast = toast.loading("Downloading warranty form...");

      const response = await API.get(`download_warranty_form/${warrantyNumber}`, {
        responseType: 'blob'
      });

      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      
      link.href = url;
      link.download = `warranty_form_${warrantyNumber}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      toast.dismiss(loadingToast);
      toast.success("Warranty form downloaded successfully");
    } catch (error) {
      console.error("Form download error:", error);
      toast.error("Failed to download warranty form");
    } finally {
      setDownloadingForm(false);
    }
  };

  const getFileType = (url) => {
    const extension = url.split(".").pop().toLowerCase();
    return ["pdf"].includes(extension) ? "pdf" : "image";
  };

  if (loading) {
    return <div className="p-8 text-center">Loading...</div>;
  }

  if (!claimDetails) {
    return <div className="p-8 text-center">Claim not found</div>;
  }

  return (
    <div className="max-w-4xl mx-auto py-8 px-4">
      <div className="bg-white rounded-lg shadow-xl border border-gray-200 p-6">
        <div className="space-y-6">
          <div className="border-b pb-4 flex justify-between items-center">
            <div>
              <h2 className="text-2xl font-semibold">Warranty Claim Details</h2>
              <p className="text-sm text-gray-500">
                Warranty Number: {claimDetails.warranty_details.warranty_number}
              </p>
            </div>
            <button
              onClick={downloadWarrantyForm}
              disabled={downloadingForm}
              className="flex items-center gap-2 bg-gradient-to-b from-[#BF1A1C] to-[#590C0D] text-white px-4 py-2 rounded-md hover:shadow-lg transition-all disabled:opacity-50"
            >
              <Download className="w-4 h-4" />
              {downloadingForm ? "Downloading..." : "Download Form"}
            </button>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="space-y-4">
              <h3 className="font-semibold">Claim Information</h3>
              <div className="space-y-2">
                <p>
                  <span className="text-gray-600">Status:</span>{" "}
                  <span className={`font-medium ${
                    claimDetails.claim_details.status === 'Pending' ? 'text-yellow-600' :
                    claimDetails.claim_details.status === 'Approved' ? 'text-green-600' :
                    claimDetails.claim_details.status === 'Rejected' ? 'text-red-600' :
                    'text-gray-900'
                  }`}>
                    {claimDetails.claim_details.status}
                  </span>
                </p>
                <p>
                  <span className="text-gray-600">Claimed At:</span>{" "}
                  {claimDetails.claim_details.claimed_at}
                </p>
                <p>
                  <span className="text-gray-600">Description:</span>{" "}
                  {claimDetails.claim_details.description}
                </p>
              </div>
            </div>

            <div className="space-y-4">
              <h3 className="font-semibold">Warranty Details</h3>
              <div className="space-y-2">
                <p>
                  <span className="text-gray-600">Full Name:</span>{" "}
                  {claimDetails.warranty_details.full_name}
                </p>
                <p>
                  <span className="text-gray-600">Email:</span>{" "}
                  {claimDetails.warranty_details.email}
                </p>
                <p>
                  <span className="text-gray-600">Phone:</span>{" "}
                  {claimDetails.warranty_details.phone}
                </p>
                <p>
                  <span className="text-gray-600">Product:</span>{" "}
                  {claimDetails.warranty_details.product_name}
                </p>
                <p>
                  <span className="text-gray-600">Invoice Date:</span>{" "}
                  {claimDetails.warranty_details.invoice_date}
                </p>
                <p>
                  <span className="text-gray-600">Invoice Value:</span>{" "}
                  {claimDetails.warranty_details.invoice_value} AED
                </p>
                <p>
                  <span className="text-gray-600">Warranty Plan:</span>{" "}
                  {claimDetails.warranty_details.warranty_plan}
                </p>
              </div>
            </div>
          </div>

          {claimDetails.warranty_details.invoice_file && (
            <div className="mt-6 space-y-4">
              <div className="flex justify-between items-center">
                <h3 className="font-semibold">Invoice Preview</h3>
                <button
                  onClick={downloadInvoice}
                  disabled={downloadingInvoice}
                  className="flex items-center gap-2 bg-gradient-to-b from-[#BF1A1C] to-[#590C0D] text-white px-4 py-2 rounded-md hover:shadow-lg transition-all disabled:opacity-50"
                >
                  <Download className="w-4 h-4" />
                  {downloadingInvoice ? "Downloading..." : "Download Invoice"}
                </button>
              </div>
              <div className="border rounded-lg overflow-hidden">
                {getFileType(claimDetails.warranty_details.invoice_file) === "pdf" ? (
                  <iframe
                    src={claimDetails.warranty_details.invoice_file}
                    className="w-full h-[500px]"
                    title="Invoice Preview"
                  />
                ) : (
                  <img
                    src={claimDetails.warranty_details.invoice_file}
                    alt="Invoice"
                    className="w-full h-auto"
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default WarrantyClaim;