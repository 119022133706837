"use client"
import { useState, useEffect } from "react"
import { useForm } from "react-hook-form"
import { useMutation } from "@tanstack/react-query"
import toast, { Toaster } from "react-hot-toast"
import { Eye, EyeOff, Loader2 } from "lucide-react"
import { Link, useNavigate } from "react-router-dom"
import Api from "./Api"
import LoadingSpinner from "../../components/Spinner"
import { useAuth } from "../../context/AuthContext"
import ForgotPasswordModal from "./ForgotPasswordModal"
import LoginSvg from "../../assets/svg/loginImgeanim.svg"


const Login = ({ isModal = false }) => {
  const { login } = useAuth()
  const [showPassword, setShowPassword] = useState(false)
  const [buttonState, setButtonState] = useState("idle")
  const [forgotPasswordModalOpen, setForgotPasswordModalOpen] = useState(false)
  const [isFocused, setIsFocused] = useState({ identifier: false, password: false, otp: false })
  const navigate = useNavigate()

  // Multi-step login states
  const [loginStep, setLoginStep] = useState("identifier") // identifier, method, password, otp
  const [loginMethod, setLoginMethod] = useState("") // password or otp
  const [sessionData, setSessionData] = useState({
    identifier: "",
    countryCode: "+91", // Default country code
  })

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      identifier: "",
      password: "",
      otp: "",
      rememberMe: false,
    },
  })

  const identifierValue = watch("identifier")

  // Check if the identifier is a phone number
  const isPhoneNumber = (value) => {
    return /^\+?[0-9]+$/.test(value)
  }

  // Format phone number with country code if needed
  const formatIdentifier = (value) => {
    if (isPhoneNumber(value)) {
      // If it already has a country code (starts with +), return as is
      if (value.startsWith("+")) return value
      // Otherwise, add the country code
      return `${sessionData.countryCode}${value.replace(/^0+/, "")}`
    }
    // If it's an email, return as is
    return value
  }

  // Initiate auth mutation
  const initiateAuthMutation = useMutation({
    mutationFn: async (data) => {
      const formattedIdentifier = formatIdentifier(data.identifier)
      try {
        const response = await Api.post(`/auth/initiate/`, {
          identifier: formattedIdentifier,
        })
        return response.data
      } catch (error) {
        throw new Error(error.response?.data?.error || "Authentication failed")
      }
    },
    onSuccess: (data) => {
      // Store the identifier returned from the API
      setSessionData({
        ...sessionData,
        identifier: data.identifier,
      })

      // Store in session storage
      sessionStorage.setItem("identifier", data.identifier)

      // Move to method selection step
      setLoginStep("method")
      setButtonState("idle")
    },
    onError: (error) => {
      setButtonState("idle")
      toast.error(error.message)
    },
  })

  // Login with password mutation
  const loginMutation = useMutation({
    mutationFn: async (data) => {
      try {
        const response = await Api.post(`/login/`, {
          identifier: sessionData.identifier,
          password: data.password,
        })
        return response.data
      } catch (error) {
        throw new Error(error.response?.data?.error || "Login failed")
      }
    },
    onSuccess: (data) => {
      handleLoginSuccess(data)
    },
    onError: (error) => {
      setButtonState("idle")
      toast.error(error.message)
    },
  })

  // Send OTP mutation
  const sendOtpMutation = useMutation({
    mutationFn: async () => {
      try {
        const response = await Api.post(`https://dash.brandexperts.ae/auth/login-with-otp/`, {
          identifier: sessionData.identifier,
        })
        return response.data
      } catch (error) {
        throw new Error(error.response?.data?.error || "Failed to send OTP")
      }
    },
    onSuccess: (data) => {
      setButtonState("idle")
      setLoginStep("otp")
      toast.success(data.message || "OTP sent successfully")
    },
    onError: (error) => {
      setButtonState("idle")
      toast.error(error.message)
    },
  })

  // OTP verification mutation
  const verifyOtpMutation = useMutation({
    mutationFn: async (data) => {
      try {
        const response = await Api.post(`https://dash.brandexperts.ae/auth/otp-verify/`, {
          identifier: sessionData.identifier,
          otp: data.otp,
        })
        return response.data
      } catch (error) {
        throw new Error(error.response?.data?.error || "OTP verification failed")
      }
    },
    onSuccess: (data) => {
      handleLoginSuccess(data)
    },
    onError: (error) => {
      setButtonState("idle")
      toast.error(error.message)
    },
  })

  // Common function to handle successful login
  const handleLoginSuccess = (data) => {
    setButtonState("success")
    localStorage.setItem("access_token", data.access_token)
    localStorage.setItem("refresh_token", data.refresh_token)
    sessionStorage.setItem("user_id", data.user_id)
    sessionStorage.setItem("customer_id", data.user_details.customer_id)
    const userDetails = {
      ...data.user_details,
      user_id: data.user_id,
      customer_id: data.user_details.customer_id,
    }
    localStorage.setItem("user_details", JSON.stringify(userDetails))
    const userData = {
      id: data.user_id,
      customer_id: data.user_details.customer_id,
      name: data.user_details.first_name || data.user_details.username,
      email: data.user_details.email,
      mobile: data.user_details.mobile,
      is_partner: data.user_details.is_partner || false,
    }
    login(userData)
    toast.success("Successfully logged in!")
    setTimeout(() => {
      if (isModal) {
        navigate("/checkout")
      } else {
        navigate("/")
      }
    }, 2000)
  }

  const onSubmit = (data) => {
    // Set loading state immediately when form is submitted
    setButtonState("loading")

    if (loginStep === "identifier") {
      initiateAuthMutation.mutate(data)
    } else if (loginStep === "password") {
      loginMutation.mutate(data)
    } else if (loginStep === "otp") {
      verifyOtpMutation.mutate(data)
    }
  }

  const handleMethodSelection = (method) => {
    setLoginMethod(method)

    if (method === "password") {
      setLoginStep("password")
    } else if (method === "otp") {
      setButtonState("loading")
      sendOtpMutation.mutate()
    }
  }

  const handleForgotPasswordClick = (e) => {
    e.preventDefault()
    setForgotPasswordModalOpen(true)
  }

  const handleCloseForgotPasswordModal = () => {
    setForgotPasswordModalOpen(false)
  }

  const handleCountryCodeChange = (e) => {
    setSessionData({
      ...sessionData,
      countryCode: e.target.value,
    })
  }

  const handleResendOtp = () => {
    sendOtpMutation.mutate()
  }

  const isButtonDisabled = buttonState !== "idle"

  useEffect(() => {
    // Check if there are initial values and set the focused state accordingly
    const initialIdentifier = watch("identifier")
    const initialPassword = watch("password")
    const initialOtp = watch("otp")

    setIsFocused({
      identifier: !!initialIdentifier,
      password: !!initialPassword,
      otp: !!initialOtp,
    })
  }, [watch])

  return (
    <>
      <Toaster position="top-right" />
      {buttonState === "loading" && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
          <LoadingSpinner />
        </div>
      )}
      <ForgotPasswordModal isOpen={forgotPasswordModalOpen} onClose={handleCloseForgotPasswordModal} />

      <div className="grid lg:grid-cols-2">
        {/* Left Column - Form */}
        <div className="flex flex-col justify-center px-8 py-16 lg:px-12">
          <div className="w-full max-w-md mx-auto space-y-8">
            <div className="space-y-2">
              <h1 className="text-3xl font-bold tracking-tight text-zinc-900">Welcome back</h1>
              <p className="text-gray-400">
                {loginStep === "identifier"
                  ? "Please enter your email or WhatsApp number"
                  : loginStep === "method"
                    ? "Choose how you want to login"
                    : loginStep === "password"
                      ? "Please enter your password to continue"
                      : "Please enter the OTP sent to your device"}
              </p>
            </div>

            <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
              <div className="space-y-4">

                {/* Step 1: Identifier Input */}
                {loginStep === "identifier" && (
                  <div className="space-y-4">
                    {/* Show country code selector if input looks like a phone number */}
                    {isPhoneNumber(identifierValue) && (
                      <div className="relative">
                        <select
                          className="w-full px-4 py-3 border border-gray-300 rounded-lg bg-transparent focus:outline-none focus:ring-2 focus:ring-[#BF1A1C] focus:border-transparent transition-all"
                          value={sessionData.countryCode}
                          onChange={handleCountryCodeChange}
                        >
                          <option value="+91">India (+91)</option>
                          <option value="+971">UAE (+971)</option>
                          <option value="+1">USA (+1)</option>
                          <option value="+44">UK (+44)</option>
                          {/* Add more country codes as needed */}
                        </select>
                        <label className="absolute left-4 text-xs text-[#BF1A1C] -top-2.5 bg-white px-1">
                          Country Code
                        </label>
                      </div>
                    )}

                    <div className="relative">
                      <input
                        id="identifier"
                        type="text"
                        autoComplete="email"
                        onFocus={() => setIsFocused((prev) => ({ ...prev, identifier: true }))}
                        onBlur={(e) => setIsFocused((prev) => ({ ...prev, identifier: !!e.target.value }))}
                        className={`w-full px-4 py-3 border ${
                          errors.identifier ? "border-red-500" : "border-gray-300"
                        } rounded-lg bg-transparent focus:outline-none focus:ring-2 focus:ring-[#BF1A1C] focus:border-transparent transition-all`}
                        placeholder=""
                        {...register("identifier", {
                          required: "Email or WhatsApp number is required",
                          pattern: {
                            value: /^(\+?[0-9]{7,15}|[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,})$/i,
                            message: "Enter a valid email or WhatsApp number",
                          },
                        })}
                      />
                      <label
                        htmlFor="identifier"
                        className={`absolute left-4 transition-all duration-200 pointer-events-none ${
                          isFocused.identifier ? "text-xs text-[#BF1A1C] -top-2.5 bg-white px-1" : "text-gray-500 top-3"
                        }`}
                      >
                        Email or WhatsApp number
                      </label>
                      {errors.identifier && <p className="mt-1.5 text-sm text-red-500">{errors.identifier.message}</p>}
                    </div>
                  </div>
                )}

                {/* Step 2: Method Selection */}
                {loginStep === "method" && (
                  <div className="space-y-4">
                    <p className="text-sm text-gray-500">Continue with {sessionData.identifier}</p>

                    <div className="grid grid-cols-1 gap-4">
                      <button
                        type="button"
                        onClick={() => handleMethodSelection("password")}
                        className="w-full py-3 px-4 flex items-center justify-center rounded-lg border border-gray-300 hover:border-[#BF1A1C] hover:bg-gray-50 transition-all"
                      >
                        Continue with Password
                      </button>

                      <button
                        type="button"
                        onClick={() => handleMethodSelection("otp")}
                        className="w-full py-3 px-4 flex items-center justify-center rounded-lg border border-gray-300 hover:border-[#BF1A1C] hover:bg-gray-50 transition-all"
                      >
                        Login with OTP
                      </button>
                    </div>
                  </div>
                )}

                {/* Step 3: Password Input */}
                {loginStep === "password" && (
                  <div className="relative">
                    <input
                      id="password"
                      type={showPassword ? "text" : "password"}
                      autoComplete="current-password"
                      onFocus={() => setIsFocused((prev) => ({ ...prev, password: true }))}
                      onBlur={(e) => setIsFocused((prev) => ({ ...prev, password: !!e.target.value }))}
                      className={`w-full px-4 py-3 border ${
                        errors.password ? "border-red-500" : "border-gray-300"
                      } rounded-lg bg-transparent focus:outline-none focus:ring-2 focus:ring-[#BF1A1C] focus:border-transparent transition-all`}
                      placeholder=""
                      {...register("password", {
                        required: "Password is required",
                        minLength: {
                          value: 6,
                          message: "Password must be at least 6 characters",
                        },
                      })}
                    />
                    <label
                      htmlFor="password"
                      className={`absolute left-4 transition-all duration-200 pointer-events-none ${
                        isFocused.password ? "text-xs text-[#BF1A1C] -top-2.5 bg-white px-1" : "text-gray-500 top-3"
                      }`}
                    >
                      Password
                    </label>
                    <button
                      type="button"
                      onClick={() => setShowPassword(!showPassword)}
                      className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-700 transition-colors"
                    >
                      {showPassword ? <Eye className="h-5 w-5" /> : <EyeOff className="h-5 w-5" />}
                    </button>
                    {errors.password && <p className="mt-1.5 text-sm text-red-500">{errors.password.message}</p>}
                  </div>
                )}

                {/* Step 4: OTP Input */}
                {loginStep === "otp" && (
                  <div className="space-y-4">
                    <div className="relative">
                      <input
                        id="otp"
                        type="text"
                        onFocus={() => setIsFocused((prev) => ({ ...prev, otp: true }))}
                        onBlur={(e) => setIsFocused((prev) => ({ ...prev, otp: !!e.target.value }))}
                        className={`w-full px-4 py-3 border ${
                          errors.otp ? "border-red-500" : "border-gray-300"
                        } rounded-lg bg-transparent focus:outline-none focus:ring-2 focus:ring-[#BF1A1C] focus:border-transparent transition-all`}
                        placeholder=""
                        {...register("otp", {
                          required: "OTP is required",
                          pattern: {
                            value: /^[0-9]{6}$/,
                            message: "Enter a valid 6-digit OTP",
                          },
                        })}
                      />
                      <label
                        htmlFor="otp"
                        className={`absolute left-4 transition-all duration-200 pointer-events-none ${
                          isFocused.otp ? "text-xs text-[#BF1A1C] -top-2.5 bg-white px-1" : "text-gray-500 top-3"
                        }`}
                      >
                        One-Time Password (OTP)
                      </label>
                      {errors.otp && <p className="mt-1.5 text-sm text-red-500">{errors.otp.message}</p>}
                    </div>

                    <div className="flex justify-between items-center">
                      <p className="text-sm text-gray-500">OTP sent to {sessionData.identifier}</p>
                      <button
                        type="button"
                        onClick={handleResendOtp}
                        disabled={sendOtpMutation.isLoading}
                        className="text-sm font-medium text-[#BF1A1C] hover:text-[#8a1315] transition-colors"
                      >
                        {sendOtpMutation.isLoading ? "Sending..." : "Resend OTP"}
                      </button>
                    </div>
                  </div>
                )}
              </div>

              {/* Remember Me and Forgot Password (only show on password step) */}
              {loginStep === "password" && (
                <div className="flex items-center justify-between">
                  <label className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      {...register("rememberMe")}
                      className="w-4 h-4 rounded border-gray-700 bg-gray-800/50 text-[#FF5D3A] focus:ring-[#FF5D3A]"
                    />
                    <span className="text-sm text-gray-500">Remember me</span>
                  </label>

                  <button
                    type="button"
                    onClick={handleForgotPasswordClick}
                    className="text-sm font-medium text-[#BF1A1C] transition-colors"
                  >
                    Forgot password?
                  </button>
                </div>
              )}

              {/* Back Button (only show on method, password or OTP step) */}
              {(loginStep === "method" || loginStep === "password" || loginStep === "otp") && (
                <button
                  type="button"
                  className="text-sm text-gray-500 hover:text-gray-700"
                  onClick={() => {
                    if (loginStep === "method") {
                      setLoginStep("identifier")
                    } else if (loginStep === "password" || loginStep === "otp") {
                      setLoginStep("method")
                    }
                  }}
                >
                  ← Back
                </button>
              )}

              {/* Submit Button */}
              {loginStep === "identifier" && (
                <button
                  type="submit"
                  disabled={isButtonDisabled}
                  className={`w-full py-3 px-4 flex items-center justify-center rounded-lg text-white font-medium transition-all duration-200 cursor-pointer ${
                    isButtonDisabled
                      ? "bg-[#f89091]/70 cursor-not-allowed"
                      : "bg-gradient-to-b from-[#BF1A1C] to-[#590C0D] hover:bg-gradient-to-b hover:from-[#BF1A1C] hover:to-[#590C0D] hover:shadow-2xl hover:scale-[1.01] active:scale-[0.98] transition-duration-300"
                  }`}
                >
                  {buttonState === "loading" ? (
                    <>
                      <Loader2 className="w-5 h-5 mr-2 animate-spin" />
                      Checking...
                    </>
                  ) : (
                    "Continue"
                  )}
                </button>
              )}

              {/* Submit Button for Password and OTP steps */}
              {(loginStep === "password" || loginStep === "otp") && (
                <button
                  type="submit"
                  disabled={isButtonDisabled}
                  className={`w-full py-3 px-4 flex items-center justify-center rounded-lg text-white font-medium transition-all duration-200 cursor-pointer ${
                    isButtonDisabled
                      ? "bg-[#f89091]/70 cursor-not-allowed"
                      : "bg-gradient-to-b from-[#BF1A1C] to-[#590C0D] hover:bg-gradient-to-b hover:from-[#BF1A1C] hover:to-[#590C0D] hover:shadow-2xl hover:scale-[1.01] active:scale-[0.98] transition-duration-300"
                  }`}
                >
                  {buttonState === "loading" ? (
                    <>
                      <Loader2 className="w-5 h-5 mr-2 animate-spin" />
                      {loginStep === "password" ? "Signing in..." : "Verifying..."}
                    </>
                  ) : loginStep === "password" ? (
                    "Sign in"
                  ) : (
                    "Verify OTP"
                  )}
                </button>
              )}

              <p className="text-center text-sm text-gray-400">
                Don't have an account?{" "}
                <Link to="/login" className="font-medium text-[#f22e32] hover:text-[#cf6161] transition-colors">
                  Sign up
                </Link>
              </p>
            </form>
          </div>
        </div>

        {/* Right Column - Image */}
        <div className="hidden lg:flex items-center justify-center p-12">
          <img
            src={LoginSvg || "/placeholder.svg"}
            alt="Login illustration"
            className="max-w-md w-full h-auto"
            id="vibrate-2"
          />
        </div>
      </div>
    </>
  )
}

export default Login

