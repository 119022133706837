"use client"

import { useState, useEffect } from "react"
import { MoveRight } from "lucide-react"
import { motion } from "framer-motion"
import { useNavigate } from "react-router-dom"
import ProductSelectionModal from "../../components/ProductSelectionModal"
import craftWebp from "../../assets/images/craft-min.webp"
import craftPng from "../../assets/images/craft-min.png"
import API from "../loginSignin/Api"
import { useMutation } from "@tanstack/react-query"

const Hsection2 = () => {
  const navigate = useNavigate() // Initialize router

  // State variables
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [quantity, setQuantity] = useState(1)
  const [standardSize, setStandardSize] = useState("")
  const [measurementSystem, setMeasurementSystem] = useState(null)
  const [displayUnit, setDisplayUnit] = useState("cm")
  const [displayWidth, setDisplayWidth] = useState("")
  const [displayHeight, setDisplayHeight] = useState("")
  const [price, setPrice] = useState(0)
  const [isProductModalOpen, setProductModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [backendErrors, setBackendErrors] = useState({})
  // eslint-disable-next-line no-unused-vars
  const [isMeasurementOpen, setIsMeasurementOpen] = useState(false)
  const [isCustomSizeOpen, setIsCustomSizeOpen] = useState(false)
  const [convertedStandardSizes, setConvertedStandardSizes] = useState([])

  // Measurement options based on system
  const measurementOptions = {
    imperial: [
      { value: "inches", label: "Inches" },
      { value: "feet", label: "Feet" },
      { value: "yard", label: "Yards" },
    ],
    metric: [
      { value: "mm", label: "Millimeters" },
      { value: "cm", label: "Centimeters" },
      { value: "meter", label: "Meters" },
    ],
  }

  // Add conversion factors for different units
  const conversionFactors = {
    // Metric conversions
    mm_to_cm: 0.1,
    mm_to_meter: 0.001,
    cm_to_mm: 10,
    cm_to_meter: 0.01,
    meter_to_mm: 1000,
    meter_to_cm: 100,
    // Imperial conversions
    inches_to_feet: 1 / 12,
    inches_to_yard: 1 / 36,
    feet_to_inches: 12,
    feet_to_yard: 1 / 3,
    yard_to_inches: 36,
    yard_to_feet: 3,
    // Metric to imperial (approx)
    mm_to_inches: 0.0394,
    cm_to_inches: 0.3937,
    meter_to_inches: 39.37,
    // Imperial to metric (approx)
    inches_to_mm: 25.4,
    feet_to_cm: 30.48,
    yard_to_meter: 0.9144,
  }

  // Function to convert dimensions between different units
  const convertDimension = (value, fromUnit, toUnit) => {
    if (fromUnit === toUnit) return value

    const conversionKey = `${fromUnit}_to_${toUnit}`
    const factor = conversionFactors[conversionKey]

    if (factor) {
      return (Number.parseFloat(value) * factor).toFixed(2)
    }

    // If direct conversion not found, convert through an intermediate unit
    if (fromUnit.includes("m") && toUnit.includes("inch")) {
      // Convert metric to inches first
      const inchesValue = convertDimension(value, fromUnit, "inches")
      return convertDimension(inchesValue, "inches", toUnit)
    } else if (fromUnit.includes("inch") && toUnit.includes("m")) {
      // Convert imperial to mm first
      const mmValue = convertDimension(value, fromUnit, "mm")
      return convertDimension(mmValue, "mm", toUnit)
    }

    return value // Return original if no conversion path found
  }

  // Fetch the first product on component mount
  useEffect(() => {
    const fetchFirstProduct = async () => {
      setIsLoading(true)
      try {
        const response = await API.get("/dash/products")
        const products = response.data.products

        if (products.length > 0) {
          const firstProduct = products[0]
          setSelectedProduct({
            id: firstProduct.id,
            name: firstProduct.name,
            image: firstProduct.image1,
            standardSizes: firstProduct.standard_sizes || [],
            minWidth: firstProduct.min_width,
            maxWidth: firstProduct.max_width,
            minHeight: firstProduct.min_height,
            maxHeight: firstProduct.max_height,
            allow_direct_add_to_cart: firstProduct.allow_direct_add_to_cart,
            amazon_url: firstProduct.amazon_url,
          })

          // Always set to metric system and cm unit
          setMeasurementSystem("metric")
          setDisplayUnit("cm")

          // If standard sizes exist, select the first one
          if (firstProduct.standard_sizes && firstProduct.standard_sizes.length > 0) {
            const firstSize = firstProduct.standard_sizes[0]
            setStandardSize(firstSize.standard_sizes)

            // Update converted standard sizes
            updateConvertedStandardSizes(firstProduct.standard_sizes, "cm")

            // Find the converted size for display
            const dimensions = firstSize.standard_sizes.split("x")
            const width = convertDimension(dimensions[0], firstSize.unit, "cm")
            const height = convertDimension(dimensions[1], firstSize.unit, "cm")

            setDisplayWidth(width)
            setDisplayHeight(height)

            // Calculate price with the first standard size
            calculatePrice(firstProduct.id, width, height, "cm", 1)
          } else {
            // Fallback to minimum dimensions if no standard sizes
            setDisplayWidth(firstProduct.min_width)
            setDisplayHeight(firstProduct.min_height)

            // Calculate initial price
            calculatePrice(firstProduct.id, firstProduct.min_width, firstProduct.min_height, "cm", 1)
          }
        }
      } catch (error) {
        console.error("Failed to fetch products:", error)
      } finally {
        setIsLoading(false)
      }
    }

    fetchFirstProduct()
  }, [])

  // Update converted standard sizes when unit changes
  const updateConvertedStandardSizes = (sizes, targetUnit) => {
    if (!sizes || sizes.length === 0) return

    const converted = sizes.map((size) => {
      // Parse dimensions from standard_sizes string (e.g., "10x15")
      const originalDimensions = size.standard_sizes.split("x")
      const originalWidth = Number.parseFloat(originalDimensions[0])
      const originalHeight = Number.parseFloat(originalDimensions[1])

      // Convert dimensions to target unit
      const convertedWidth = convertDimension(originalWidth, size.unit, targetUnit)

      const convertedHeight = convertDimension(originalHeight, size.unit, targetUnit)

      return {
        ...size,
        convertedWidth,
        convertedHeight,
        displayText: `${convertedWidth}x${convertedHeight}`,
        displayUnit: targetUnit,
      }
    })

    setConvertedStandardSizes(converted)
  }

  // Price calculation mutation
  const priceMutation = useMutation({
    mutationFn: async (priceData) => {
      const response = await API.post("/dash/product-price/", priceData)
      return response.data
    },
    onSuccess: (data) => {
      setBackendErrors({})
      setPrice(data.total_price)

      // Update dimensions with values returned from backend
      setDisplayWidth(data.width)
      setDisplayHeight(data.height)

      // Use unit returned from backend
      setDisplayUnit(data.unit)

      // Update converted standard sizes based on new unit
      if (selectedProduct?.standardSizes) {
        updateConvertedStandardSizes(selectedProduct.standardSizes, data.unit)
      }
    },
    onError: (error) => {
      setBackendErrors({
        message: error.response?.data?.error || "Price calculation failed",
      })
      setPrice(0)
    },
  })

  // Calculate price function
  const calculatePrice = (productId, width, height, unit, qty) => {
    priceMutation.mutate({
      product_id: productId,
      width,
      height,
      unit,
      quantity: qty,
    })
  }

  // Handle measurement system change
  const handleMeasurementChange = (system) => {
    setMeasurementSystem(system)

    // Set default unit for the selected measurement system
    const newUnit = system === "imperial" ? "inches" : "cm"

    setDisplayUnit(newUnit)

    // Update converted standard sizes
    if (selectedProduct?.standardSizes) {
      updateConvertedStandardSizes(selectedProduct.standardSizes, newUnit)
    }

    if (selectedProduct) {
      // Convert current dimensions to the new unit before calculating price
      const convertedWidth = convertDimension(displayWidth, displayUnit, newUnit)
      const convertedHeight = convertDimension(displayHeight, displayUnit, newUnit)

      calculatePrice(selectedProduct.id, convertedWidth, convertedHeight, newUnit, quantity)
    }
  }

  // Handle unit change
  const handleUnitChange = (newUnit) => {
    setDisplayUnit(newUnit)

    // Update converted standard sizes
    if (selectedProduct?.standardSizes) {
      updateConvertedStandardSizes(selectedProduct.standardSizes, newUnit)
    }

    if (selectedProduct) {
      calculatePrice(selectedProduct.id, displayWidth, displayHeight, newUnit, quantity)
    }
  }

  // Handle dimension change
  const handleDimensionChange = (width, height) => {
    if (selectedProduct) {
      calculatePrice(selectedProduct.id, width, height, displayUnit, quantity)
    }
  }

  // Handle standard size change
  const handleStandardSizeChange = (e) => {
    const selected = e.target.value
    setStandardSize(selected)

    // Find the selected standard size
    const size = selectedProduct.standardSizes?.find((s) => s.standard_sizes === selected)
    if (!size) return

    // Find the converted size for display
    const convertedSize = convertedStandardSizes.find((s) => s.standard_sizes === selected)

    // Update dimensions using converted values if available
    if (convertedSize) {
      setDisplayWidth(convertedSize.convertedWidth)
      setDisplayHeight(convertedSize.convertedHeight)

      calculatePrice(
        selectedProduct.id,
        convertedSize.convertedWidth,
        convertedSize.convertedHeight,
        displayUnit,
        quantity,
      )
    } else {
      // Fallback to original behavior
      setDisplayWidth(size.width)
      setDisplayHeight(size.height)

      calculatePrice(selectedProduct.id, size.width, size.height, displayUnit, quantity)
    }
  }

  // Handle product selection from modal
  const handleProductChange = async (newProduct) => {
    setIsLoading(true)
    try {
      setSelectedProduct({
        id: newProduct.id,
        name: newProduct.name,
        image: newProduct.image1,
        standardSizes: newProduct.standard_sizes || [],
        minWidth: newProduct.min_width,
        maxWidth: newProduct.max_width,
        minHeight: newProduct.min_height,
        maxHeight: newProduct.max_height,
        allow_direct_add_to_cart: newProduct.allow_direct_add_to_cart,
        amazon_url: newProduct.amazon_url,
      })

      // Always use metric system and cm unit
      setMeasurementSystem("metric")
      setDisplayUnit("cm")

      // If standard sizes exist, select the first one
      if (newProduct.standard_sizes && newProduct.standard_sizes.length > 0) {
        const firstSize = newProduct.standard_sizes[0]
        setStandardSize(firstSize.standard_sizes)

        // Update converted standard sizes
        updateConvertedStandardSizes(newProduct.standard_sizes, "cm")

        // Find the converted size for display
        const dimensions = firstSize.standard_sizes.split("x")
        const width = convertDimension(dimensions[0], firstSize.unit, "cm")
        const height = convertDimension(dimensions[1], firstSize.unit, "cm")

        setDisplayWidth(width)
        setDisplayHeight(height)

        // Calculate price with the first standard size
        calculatePrice(newProduct.id, width, height, "cm", 1)
      } else {
        // Fallback to minimum dimensions if no standard sizes
        setStandardSize("")
        setDisplayWidth(newProduct.min_width)
        setDisplayHeight(newProduct.min_height)

        // Calculate initial price
        calculatePrice(newProduct.id, newProduct.min_width, newProduct.min_height, "cm", 1)
      }
    } finally {
      setIsLoading(false)
    }
  }

  // Handle quantity change
  const handleQuantityChange = (newQuantity) => {
    setQuantity(newQuantity)

    if (selectedProduct) {
      calculatePrice(selectedProduct.id, displayWidth, displayHeight, displayUnit, newQuantity)
    }
  }

  // Function to handle proceed button click - Navigate to product details page
  const handleProceed = () => {
    if (selectedProduct && selectedProduct.id) {
      // Navigate to product detail page with the product ID
      navigate(`/product/${selectedProduct.id}`)
    }
  }

  // Loading state
  if (isLoading) {
    return (
      <div className="flex justify-center items-center min-h-[50vh]">
        <div className="border-b-2 border-red-500 border-t-2 h-12 rounded-full w-12 animate-spin"></div>
      </div>
    )
  }

  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8 }}
      viewport={{ once: true, amount: 0.3 }}
    >
      <section className="flex flex-col justify-between gap-8 items-center lg:flex-row lg:py-12 max-w-full min-h-[50vh] mx-auto overflow-hidden px-8 py-8">
        {/* Product Card */}
        <div className="bg-white border-1 border-gray-300 p-6 rounded-lg shadow-2xl w-full lg:w-1/2">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-2xl font-bold">{selectedProduct ? selectedProduct.name : "Select a Product"}</h2>
            <button onClick={() => setProductModalOpen(true)} className="text-red-500 font-medium hover:text-red-600">
              {selectedProduct ? "Change" : "Select"} &gt;
            </button>
          </div>

          {selectedProduct && (
            <div className="grid grid-cols-1 gap-6 lg:grid-cols-2">
              {/* Left side - Image */}
              <div>
                <div className="rounded-lg aspect-square overflow-hidden relative">
                  <img
                    src={selectedProduct.image || "/placeholder.svg"}
                    alt={selectedProduct.name}
                    className="h-full w-full object-cover"
                  />
                </div>
                <div className="mt-4">
                  <label className="text-gray-600">Price Total:</label>
                  {priceMutation.isPending ? (
                    <div className="flex text-gray-500 items-center">
                      <div className="border-2 border-red-500 border-t-transparent h-4 rounded-full w-4 animate-spin mr-2" />
                      Calculating...
                    </div>
                  ) : backendErrors.message ? (
                    <div className="text-red-500 text-sm">{backendErrors.message}</div>
                  ) : (
                    <div className="text-2xl font-bold">{price.toFixed(2)} AED</div>
                  )}
                </div>
              </div>

              {/* Right side - Form */}
              <div className="space-y-4">
                {/* Step 1: Choose size - only if standard sizes exist */}
                {selectedProduct.standardSizes && selectedProduct.standardSizes.length > 0 && (
                  <div className="mb-4">
                    <label className="text-sm block font-medium mb-2">Select a size</label>
                    <select
                      value={standardSize}
                      onChange={handleStandardSizeChange}
                      className="border border-red-400 p-3 rounded-md w-full focus:outline-none focus:ring-1 focus:ring-red-500"
                    >
                      {/* Use converted standard sizes in the dropdown */}
                      {selectedProduct.standardSizes.map((size, index) => (
                        <option key={index} value={size.standard_sizes}>
                          {convertedStandardSizes[index]?.displayText || size.standard_sizes} ({displayUnit})
                        </option>
                      ))}
                    </select>
                  </div>
                )}

                {/* Measurement System */}
                <div className="bg-gray-50 p-4 rounded-lg">
                  <div className="mt-4 space-y-4">
                    {/* Measurement System Selection */}
                    <div className="grid grid-cols-2 gap-2">
                      {["metric", "imperial"].map((system) => (
                        <button
                          key={system}
                          type="button"
                          onClick={() => handleMeasurementChange(system)}
                          className={`px-3 py-2 text-sm rounded-md border ${
                            measurementSystem === system
                              ? "bg-red-50 border-red-500 text-red-700 font-medium"
                              : "border-gray-300 hover:bg-gray-50"
                          }`}
                        >
                          {system.charAt(0).toUpperCase() + system.slice(1)}
                        </button>
                      ))}
                    </div>

                    {/* Unit Selection */}
                    <div>
                      <label className="text-sm block font-medium mb-2">Unit</label>
                      <select
                        value={displayUnit}
                        onChange={(e) => {
                          const newUnit = e.target.value
                          setDisplayUnit(newUnit)
                          handleUnitChange(newUnit)
                        }}
                        className="border border-red-400 p-2 rounded-md w-full focus:outline-none focus:ring-1 focus:ring-red-500"
                      >
                        {measurementOptions[measurementSystem]?.map((opt) => (
                          <option key={opt.value} value={opt.value}>
                            {opt.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

             
                {/* Quantity
                <div>
                  <label className="block font-medium mb-2">Quantity</label>
                  <div className="flex rounded-md w-1/2 items-center">
                    <button
                      type="button"
                      onClick={() => {
                        const newQuantity = Math.max(1, quantity - 1)
                        handleQuantityChange(newQuantity)
                      }}
                      className="hover:bg-gray-100 px-4 py-2"
                    >
                      -
                    </button>
                    <input
                      type="number"
                      min="1"
                      value={quantity}
                      onChange={(e) => {
                        const newQuantity = Math.max(1, Number.parseInt(e.target.value) || 1)
                        handleQuantityChange(newQuantity)
                      }}
                      className="border-0 p-2 text-center w-16 focus:ring-0"
                    />
                    <button
                      type="button"
                      onClick={() => {
                        const newQuantity = quantity + 1
                        handleQuantityChange(newQuantity)
                      }}
                      className="hover:bg-gray-100 px-4 py-2"
                    >
                      +
                    </button>
                  </div>
                </div> */}
                
                {/* Proceed Button */}
                <div className="w-full mt-20">
                  <button
                    onClick={handleProceed}
                    disabled={!price || priceMutation.isPending}
                    className={`w-full px-6 py-3 text-white rounded-lg transition-all duration-200 flex items-center justify-center gap-2 ${
                      !price || priceMutation.isPending
                        ? "bg-gray-300 cursor-not-allowed"
                        : "bg-gradient-to-r from-red-600 to-red-800 hover:from-red-700 hover:to-red-900"
                    }`}
                  >
                    <span>{priceMutation.isPending ? "Calculating..." : "Proceed"}</span>
                    {!priceMutation.isPending && <MoveRight className="h-5 w-5" />}
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Right Content */}
        <div className="text-center w-full lg:text-left lg:w-1/2">
          <h1 className="text-3xl font-bold lg:text-5xl mb-4">Craft Your Style, Ship Your Smile!</h1>
          <p className="text-gray-600 text-lg mb-4">
            Create stunning custom signage in your preferred measurements. Perfect for both personal and professional
            applications.
          </p>
          <div className="flex justify-center lg:justify-end">
            <picture>
              <source srcSet={craftWebp} type="image/webp" />
              <img
                src={craftPng || "/placeholder.svg"}
                alt="craft-Img"
                className="w-78 hidden pointer-events-none sm:block"
                loading="lazy"
              />
            </picture>
          </div>
        </div>

        {/* Product Selection Modal */}
        <ProductSelectionModal
          isOpen={isProductModalOpen}
          onClose={() => setProductModalOpen(false)}
          onSelect={handleProductChange}
        />
      </section>
    </motion.div>
  )
}

export default Hsection2