"use client"

import { useState, useEffect } from "react"
import { useNavigate, Link } from "react-router-dom"
import {
  User,
  Mail,
  Phone,
  LogOut,
  Bell,
  Home,
  Menu,
  Handshake,
  Package,
  Clock,
  AlertCircle,
  Truck,
  ChevronRight,
  Calendar,
  Trash2,
  ShoppingBag,
  ArrowUpRight,
} from "lucide-react"

import { Button } from "@/components/ui/button"
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "@/components/ui/card"
import { Badge } from "@/components/ui/badge"
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet"
import { Skeleton } from "@/components/ui/skeleton"
import { Separator } from "@/components/ui/separator"
import { useQuery } from "@tanstack/react-query"
import { toast } from "react-toastify"
import API from "../loginSignin/Api"

// Status configuration for order status badges
const statusConfig = {
  ordered: {
    bg: "bg-amber-100",
    text: "text-amber-800",
    border: "border-amber-200",
    icon: Clock,
    label: "Order Placed",
  },
  delivered: {
    bg: "bg-green-100",
    text: "text-green-800",
    border: "border-green-200",
    icon: Package,
    label: "Delivered",
  },
  cancelled: {
    bg: "bg-red-100",
    text: "text-red-800",
    border: "border-red-200",
    icon: AlertCircle,
    label: "Cancelled",
  },
  shipped: {
    bg: "bg-blue-100",
    text: "text-blue-800",
    border: "border-blue-200",
    icon: Truck,
    label: "Shipped",
  },
}

// Format date helper function
const formatDate = (dateString) => {
  if (!dateString) return "Not available"
  return new Date(dateString).toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  })
}

// Format time helper function
const formatTime = (dateString) => {
  if (!dateString) return ""
  const date = new Date(dateString)
  return date.toLocaleTimeString(undefined, {
    hour: "2-digit",
    minute: "2-digit",
  })
}

// Format currency helper function
const formatCurrency = (amount) => {
  return Number.parseFloat(amount).toFixed(2) + " AED"
}

export default function Dashboard() {
  const [userDetails, setUserDetails] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [activeTab, setActiveTab] = useState("dashboard")
  const [accessToken, setAccessToken] = useState(null)
  const [savedRFQs, setSavedRFQs] = useState([])
  const [loadingRFQs, setLoadingRFQs] = useState(false)
  const navigate = useNavigate()

  // Get user details and access token from local storage
  useEffect(() => {
    try {
      const userData = localStorage.getItem("user_details")
      const token = localStorage.getItem("access_token")

      if (userData && token) {
        const parsedUserData = JSON.parse(userData)
        setUserDetails(parsedUserData)
        setAccessToken(token)

        // Store the correct customer ID
        // The API expects customer_id, not id
        sessionStorage.setItem("customer_id", parsedUserData.customer_id)

        // Log for debugging
        console.log("User data:", parsedUserData)
        console.log("Customer ID:", parsedUserData.customer_id)
      } else {
        setError("User details or access token not found in local storage")
      }
    } catch (err) {
      setError("Error reading from local storage: " + err.message)
    } finally {
      setLoading(false)
    }
  }, [])

  // Load saved RFQs when the Estimates tab is active
  useEffect(() => {
    if (activeTab === "Estimates") {
      loadSavedRFQs()
    }
  }, [activeTab])

  // Load saved RFQs from localStorage
  const loadSavedRFQs = () => {
    setLoadingRFQs(true)
    try {
      const rfqs = JSON.parse(localStorage.getItem("savedRFQs") || "[]")
      // Sort by most recent first
      rfqs.sort((a, b) => new Date(b.saved_date) - new Date(a.saved_date))
      setSavedRFQs(rfqs)
    } catch (error) {
      console.error("Error loading saved RFQs:", error)
      toast.error("Failed to load saved RFQs")
      setSavedRFQs([])
    } finally {
      setLoadingRFQs(false)
    }
  }

  // Handle loading an RFQ into the cart
  const handleLoadRFQ = (rfq) => {
    try {
      // Create a cart object from the saved RFQ
      const cart = {
        cart_items: rfq.cart_items,
        site_visit: rfq.site_visit,
      }

      // Save to sessionStorage
      sessionStorage.setItem("cart", JSON.stringify(cart))

      // Notify user
      toast.success(`RFQ "${rfq.name}" loaded successfully`)

      // Trigger cart update event
      window.dispatchEvent(new Event("cartUpdated"))

      // Redirect to cart page
      navigate("/cart")
    } catch (error) {
      console.error("Error loading RFQ:", error)
      toast.error("Failed to load RFQ. Please try again.")
    }
  }

  // Handle deleting an RFQ
  const handleDeleteRFQ = (index, name) => {
    try {
      // Create a copy of the current saved RFQs
      const updatedRFQs = [...savedRFQs]

      // Remove the selected RFQ
      updatedRFQs.splice(index, 1)

      // Update state
      setSavedRFQs(updatedRFQs)

      // Update localStorage
      localStorage.setItem("savedRFQs", JSON.stringify(updatedRFQs))

      // Notify user
      toast.success(`RFQ "${name}" deleted successfully`)
    } catch (error) {
      console.error("Error deleting RFQ:", error)
      toast.error("Failed to delete RFQ. Please try again.")
    }
  }

  // Fetch orders for the current user
  const { data: orders, isLoading: ordersLoading } = useQuery({
    queryKey: ["orders", userDetails?.customer_id],
    queryFn: async () => {
      if (!userDetails?.customer_id) return []

      // Log for debugging
      console.log("Fetching orders for customer ID:", userDetails.customer_id)

      const response = await API.get(`/orders/${userDetails.customer_id}/`)

      // Log the response to see what's coming back
      console.log("Orders API response:", response.data)

      return response.data
    },
    enabled: !!userDetails?.customer_id,
  })

  // Get recent orders (up to 4)
  const recentOrders = orders?.slice(0, 4) || []

  const handleLogout = () => {
    localStorage.removeItem("user_details")
    localStorage.removeItem("access_token")
    sessionStorage.removeItem("customer_id")
    navigate("/login")
  }

  const getTimeOfDay = () => {
    const hour = new Date().getHours()
    if (hour < 12) return "morning"
    if (hour < 18) return "afternoon"
    return "evening"
  }

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="space-y-4 w-[400px]">
          <Skeleton className="h-12 w-full" />
          <Skeleton className="h-40 w-full" />
          <Skeleton className="h-40 w-full" />
        </div>
      </div>
    )
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Card className="w-[400px]">
          <CardHeader>
            <CardTitle className="text-red-500">Error</CardTitle>
          </CardHeader>
          <CardContent>
            <p>{error}</p>
          </CardContent>
          <CardFooter>
            <Button onClick={() => navigate("/login")}>Back to Login</Button>
          </CardFooter>
        </Card>
      </div>
    )
  }

  const getButtonStyle = (tabName) => {
    return activeTab === tabName ? "w-full justify-start bg-red-50 text-red-500 font-medium" : "w-full justify-start"
  }

  return (
    <div className="min-h-screen bg-background">
      {/* Mobile Navigation */}
      <Sheet>
        <SheetTrigger asChild>
          <Button variant="outline" size="icon" className="md:hidden fixed top-4 left-4 z-50">
            <Menu className="h-5 w-5" />
          </Button>
        </SheetTrigger>
        <SheetContent side="left" className="w-64">
          <div className="flex items-center mb-6">
            <User className="h-6 w-6 mr-2 text-primary" />
            <h2 className="text-xl font-bold">Brand Experts</h2>
          </div>
          <nav className="space-y-2">
            <Button variant="ghost" className="w-full justify-start" onClick={() => setActiveTab("dashboard")}>
              <Home className="mr-2 h-5 w-5" />
              Dashboard
            </Button>
            <Button variant="ghost" className="w-full justify-start" onClick={() => setActiveTab("My-Orders")}>
              <Package className="mr-2 h-5 w-5" />
              My Orders
            </Button>
            <Button variant="ghost" className="w-full justify-start" onClick={() => setActiveTab("Estimates")}>
              <Handshake className="mr-2 h-5 w-5" />
              Estimates
            </Button>
          </nav>
          <div className="absolute bottom-4 w-full pr-6">
            <Button variant="destructive" className="w-full" onClick={handleLogout}>
              <LogOut className="mr-2 h-5 w-5" />
              Logout
            </Button>
          </div>
        </SheetContent>
      </Sheet>

      <div className="flex">
        {/* Desktop Sidebar */}
        <div className="hidden md:flex w-64 flex-col sticky inset-y-0 border-r bg-card">
          <div className="flex-1 py-6 px-4 space-y-2">
            <Button variant="ghost" className={getButtonStyle("dashboard")} onClick={() => setActiveTab("dashboard")}>
              <Home className="mr-2 h-5 w-5" />
              Dashboard
            </Button>
            <Button variant="ghost" className={getButtonStyle("My-Orders")} onClick={() => setActiveTab("My-Orders")}>
              <Package className="mr-2 h-5 w-5" />
              My Orders
            </Button>
            <Button variant="ghost" className={getButtonStyle("Estimates")} onClick={() => setActiveTab("Estimates")}>
              <Handshake className="mr-2 h-5 w-5" />
              Estimates
            </Button>
          </div>
        </div>

        {/* Main Content */}
        <div className="flex-1 md:ml-2 p-4 md:p-8">
          {/* Header */}
          <div className="flex justify-between items-center mb-8">
            <div>
              <h1 className="text-2xl md:text-3xl font-bold">
                {activeTab === "dashboard" ? "Dashboard" : activeTab === "My-Orders" ? "My Orders" : "Estimates"}
              </h1>
              <p className="text-muted-foreground">
                Good {getTimeOfDay()}, {userDetails?.first_name}
              </p>
            </div>
            <div className="flex items-center gap-4">
              <Button variant="outline" size="icon">
                <Bell className="h-5 w-5" />
              </Button>
            </div>
          </div>

          {/* Dashboard Tab */}
          {activeTab === "dashboard" && (
            <div className="space-y-8">
              {/* User Profile Cards */}
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
                {/* Profile Card */}
                <Card>
                  <CardHeader className="pb-2">
                    <CardTitle className="text-sm font-medium text-muted-foreground">PROFILE</CardTitle>
                  </CardHeader>
                  <CardContent className="flex items-center">
                    <div className="mr-4 flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
                      <User className="h-6 w-6 text-red-500" />
                    </div>
                    <div>
                      <p className="text-lg font-medium">
                        {userDetails?.first_name} {userDetails?.last_name}
                      </p>
                      <p className="text-sm text-muted-foreground">
                        {userDetails?.is_partner ? "Partner" : "Customer"}
                      </p>
                    </div>
                  </CardContent>
                </Card>

                {/* Email Card */}
                <Card>
                  <CardHeader className="pb-2">
                    <CardTitle className="text-sm font-medium text-muted-foreground">EMAIL</CardTitle>
                  </CardHeader>
                  <CardContent className="flex items-center">
                    <div className="mr-4 flex-shrink-0 flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
                      <Mail className="h-6 w-6 text-red-500" />
                    </div>
                    <div className="flex items-center min-w-0 flex-grow">
                      <div className="min-w-0 flex-grow">
                        <p className="text-lg font-medium truncate">{userDetails?.email}</p>
                        <p className="text-sm text-muted-foreground">
                          {userDetails?.verified_email ? "Verified" : "Not Verified"}
                        </p>
                      </div>
                      {userDetails?.verified_email && (
                        <Badge className="ml-2 flex-shrink-0" variant="outline">
                          Verified
                        </Badge>
                      )}
                    </div>
                  </CardContent>
                </Card>

                {/* Mobile Card */}
                <Card>
                  <CardHeader className="pb-2">
                    <CardTitle className="text-sm font-medium text-muted-foreground">MOBILE</CardTitle>
                  </CardHeader>
                  <CardContent className="flex items-center">
                    <div className="mr-4 flex-shrink-0 flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
                      <Phone className="h-6 w-6 text-red-500" />
                    </div>
                    <div className="flex items-center min-w-0 flex-grow">
                      <div className="min-w-0 flex-grow">
                        <p className="text-lg font-medium truncate">{userDetails?.mobile}</p>
                        <p className="text-sm text-muted-foreground">
                          {userDetails?.verified_mobile ? "Verified" : "Not Verified"}
                        </p>
                      </div>
                      {!userDetails?.verified_mobile && (
                        <Button variant="link" className="ml-2 flex-shrink-0 h-auto p-0 text-primary">
                          Verify
                        </Button>
                      )}
                    </div>
                  </CardContent>
                </Card>
              </div>

              {/* Recent Orders Section */}
              <Card>
                <CardHeader className="flex flex-row items-center justify-between">
                  <CardTitle>Recent Orders</CardTitle>
                  <Button
                    variant="ghost"
                    size="sm"
                    className="text-sm text-red-500"
                    onClick={() => setActiveTab("My-Orders")}
                  >
                    View All
                    <ChevronRight className="ml-1 h-4 w-4" />
                  </Button>
                </CardHeader>
                <CardContent>
                  {ordersLoading ? (
                    <div className="space-y-4">
                      {[1, 2, 3, 4].map((i) => (
                        <div key={i} className="flex items-center space-x-4">
                          <Skeleton className="h-12 w-12 rounded-full" />
                          <div className="space-y-2">
                            <Skeleton className="h-4 w-[250px]" />
                            <Skeleton className="h-4 w-[200px]" />
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : recentOrders.length === 0 ? (
                    <div className="text-center py-6">
                      <Package className="mx-auto h-12 w-12 text-muted-foreground opacity-50" />
                      <h3 className="mt-4 text-lg font-medium">No orders yet</h3>
                      <p className="mt-2 text-sm text-muted-foreground">
                        When you place orders, they will appear here.
                      </p>
                    </div>
                  ) : (
                    <div className="space-y-4">
                      {recentOrders.map((order) => {
                        const StatusIcon = statusConfig[order.status]?.icon || AlertCircle
                        const { bg, text, border } = statusConfig[order.status] || statusConfig.ordered

                        return (
                          <div key={order.id} className="group">
                            <Link
                              to={`/order-details/${order.id}`}
                              className="flex items-start space-x-4 p-3 rounded-lg hover:bg-muted transition-colors"
                            >
                              <div className={`flex-shrink-0 rounded-full p-2 ${bg}`}>
                                <StatusIcon className={`h-5 w-5 ${text}`} />
                              </div>
                              <div className="flex-1 min-w-0">
                                <div className="flex justify-between">
                                  <p className="text-sm font-medium">Order #{order.id}</p>
                                  <p className="text-sm font-medium">
                                    {formatCurrency(order.total_amount || order.amount)}
                                  </p>
                                </div>
                                <div className="flex items-center mt-1">
                                  <Calendar className="mr-1 h-3 w-3 text-muted-foreground" />
                                  <p className="text-xs text-muted-foreground">{formatDate(order.ordered_date)}</p>
                                  <Badge variant="outline" className={`ml-2 ${bg} ${text} ${border}`}>
                                    {statusConfig[order.status]?.label || order.status}
                                  </Badge>
                                </div>
                                <p className="mt-1 text-xs text-muted-foreground truncate">
                                  {order.cart?.items?.length || 0} item(s)
                                </p>
                              </div>
                              <ChevronRight className="h-5 w-5 text-muted-foreground opacity-0 group-hover:opacity-100 transition-opacity" />
                            </Link>
                            <Separator className="mt-2" />
                          </div>
                        )
                      })}
                    </div>
                  )}
                </CardContent>
              </Card>
            </div>
          )}

          {/* My Orders Tab */}
          {activeTab === "My-Orders" && (
            <div className="space-y-6">
              {ordersLoading ? (
                <div className="space-y-4">
                  {[1, 2, 3, 4].map((i) => (
                    <Card key={i}>
                      <CardContent className="p-0">
                        <div className="h-2 bg-gray-100" />
                        <div className="p-6 space-y-4">
                          <div className="flex justify-between">
                            <Skeleton className="h-6 w-[120px]" />
                            <Skeleton className="h-6 w-[80px]" />
                          </div>
                          <div className="flex items-center space-x-4">
                            <Skeleton className="h-14 w-14 rounded" />
                            <div className="space-y-2 flex-1">
                              <Skeleton className="h-4 w-full" />
                              <Skeleton className="h-4 w-[70%]" />
                            </div>
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                  ))}
                </div>
              ) : !orders || orders.length === 0 ? (
                <Card>
                  <CardContent className="flex flex-col items-center justify-center py-12">
                    <Package className="h-16 w-16 text-muted-foreground opacity-50 mb-4" />
                    <h3 className="text-xl font-medium">No orders found</h3>
                    <p className="text-muted-foreground mt-2 text-center max-w-md">
                      You haven&#39;t placed any orders yet. Browse our products and discover amazing items!
                    </p>
                    <Button className="mt-6" variant="default">
                      Start Shopping
                    </Button>
                  </CardContent>
                </Card>
              ) : (
                <div className="space-y-4">
                  {orders.map((order) => {
                    const StatusIcon = statusConfig[order.status]?.icon || AlertCircle
                    const { bg, text, border } = statusConfig[order.status] || statusConfig.ordered
                    const itemCount = order.cart?.items?.length || 0
                    const firstItem = order.cart?.items?.[0]

                    return (
                      <Card key={order.id} className="overflow-hidden hover:shadow-md transition-shadow">
                        <div className={`h-2 ${bg}`} />
                        <CardContent className="p-4">
                          <Link to={`/order-details/${order.id}`}>
                            <div className="flex justify-between items-start mb-3">
                              <div>
                                <div className="flex items-center">
                                  <span
                                    className={`inline-flex items-center justify-center w-8 h-8 rounded-full ${bg} mr-2`}
                                  >
                                    <StatusIcon className={`w-4 h-4 ${text}`} />
                                  </span>
                                  <h3 className="font-medium">Order #{order.id}</h3>
                                </div>
                                <p className="text-sm text-muted-foreground mt-1">{formatDate(order.ordered_date)}</p>
                              </div>
                              <div className="text-right">
                                <p className="font-medium">{formatCurrency(order.total_amount || order.amount)}</p>
                                <Badge variant="outline" className={`mt-1 ${bg} ${text} ${border}`}>
                                  {statusConfig[order.status]?.label || order.status}
                                </Badge>
                              </div>
                            </div>

                            {firstItem && (
                              <div className="flex items-center">
                                <img
                                  src={
                                    firstItem.design_image !== "https://default-image-url.com"
                                      ? firstItem.design_image
                                      : "/placeholder.svg?height=100&width=100"
                                  }
                                  alt="Product"
                                  className="h-14 w-14 object-cover rounded"
                                />
                                <div className="ml-3 flex-1">
                                  <p className="font-medium">Product #{firstItem.product}</p>
                                  <p className="text-xs text-muted-foreground">
                                    {firstItem.custom_width}x{firstItem.custom_height} {firstItem.size_unit} • Qty:{" "}
                                    {firstItem.quantity}
                                  </p>
                                </div>
                                {itemCount > 1 && (
                                  <span className="text-xs text-muted-foreground">
                                    +{itemCount - 1} more item{itemCount > 2 ? "s" : ""}
                                  </span>
                                )}
                              </div>
                            )}

                            <div className="mt-3 flex justify-between items-center pt-3 border-t border-gray-100">
                              <div className="text-sm">
                                <span className="text-muted-foreground">Payment:</span>
                                <span className="ml-1 capitalize">{order.payment_method}</span>
                              </div>
                              <div className="flex items-center text-red-600 text-sm font-medium">
                                View Details
                                <ChevronRight className="w-4 h-4 ml-1" />
                              </div>
                            </div>
                          </Link>
                        </CardContent>
                      </Card>
                    )
                  })}
                </div>
              )}
            </div>
          )}

          {/* Estimates Tab */}
          {activeTab === "Estimates" && (
            <div className="space-y-6">
              {loadingRFQs ? (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                  {[1, 2, 3].map((i) => (
                    <Card key={i}>
                      <CardContent className="p-6 space-y-4">
                        <div className="flex justify-between">
                          <Skeleton className="h-6 w-[150px]" />
                          <Skeleton className="h-6 w-[24px] rounded-full" />
                        </div>
                        <div className="space-y-2">
                          <Skeleton className="h-4 w-[180px]" />
                          <Skeleton className="h-4 w-[120px]" />
                          <Skeleton className="h-4 w-[100px]" />
                        </div>
                        <Skeleton className="h-6 w-[120px]" />
                        <Skeleton className="h-10 w-full rounded-lg" />
                      </CardContent>
                    </Card>
                  ))}
                </div>
              ) : savedRFQs.length === 0 ? (
                <Card>
                  <CardContent className="flex flex-col items-center justify-center py-12">
                    <ShoppingBag className="h-16 w-16 text-muted-foreground opacity-50 mb-4" />
                    <h3 className="text-xl font-medium">No Saved Estimates</h3>
                    <p className="text-muted-foreground mt-2 text-center max-w-md">
                      You haven't saved any Request for Quotations yet. Add items to your cart and save an RFQ to see it
                      here.
                    </p>
                    <div className="flex gap-4 mt-6">
                      <Button variant="default" onClick={() => navigate("/products")}>
                        Browse Products
                      </Button>
                      <Button variant="outline" onClick={() => navigate("/cart")}>
                        Go to Cart
                      </Button>
                    </div>
                  </CardContent>
                </Card>
              ) : (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                  {savedRFQs.map((rfq, index) => (
                    <Card key={index} className="overflow-hidden hover:shadow-md transition-shadow">
                      <CardHeader className="pb-2 flex flex-row items-start justify-between">
                        <CardTitle className="text-lg">{rfq.name}</CardTitle>
                        <Button
                          variant="ghost"
                          size="icon"
                          className="h-8 w-8 text-red-500 hover:text-red-700 hover:bg-red-50"
                          onClick={() => handleDeleteRFQ(index, rfq.name)}
                        >
                          <Trash2 className="h-4 w-4" />
                        </Button>
                      </CardHeader>
                      <CardContent className="space-y-4">
                        <div className="flex items-center gap-2 text-sm text-muted-foreground">
                          <Calendar className="h-4 w-4" />
                          <span>{formatDate(rfq.saved_date)}</span>
                          <Clock className="h-4 w-4 ml-2" />
                          <span>{formatTime(rfq.saved_date)}</span>
                        </div>

                        <div className="space-y-2">
                          <div className="flex justify-between text-sm">
                            <span className="text-muted-foreground">Items:</span>
                            <span className="font-medium">{rfq.cart_items.length}</span>
                          </div>
                          <div className="flex justify-between text-sm">
                            <span className="text-muted-foreground">Site Visit:</span>
                            <span className="font-medium">{rfq.site_visit ? "Yes" : "No"}</span>
                          </div>
                        </div>

                        <div className="text-xl font-bold">Total: {rfq.total} AED</div>

                        <Button
                          className="w-full bg-gradient-to-r from-red-600 to-red-800 hover:from-red-700 hover:to-red-900"
                          onClick={() => handleLoadRFQ(rfq)}
                        >
                          <ShoppingBag className="mr-2 h-4 w-4" />
                          Load RFQ
                        </Button>
                      </CardContent>
                    </Card>
                  ))}
                </div>
              )}

              {savedRFQs.length > 0 && (
                <div className="flex justify-end">
                  <Button variant="outline" className="flex items-center gap-2" onClick={() => navigate("/cart")}>
                    Create New Estimate
                    <ArrowUpRight className="h-4 w-4" />
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

