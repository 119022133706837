"use client"

import { useState, useEffect } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { Trash2, Plus, Minus, ShoppingBag, ArrowLeft, ArrowRight } from "lucide-react"
import { motion } from "framer-motion"
import { toast } from "react-toastify"
import { useModal } from "../context/ModalContext"
import { isAuthenticated } from "../utils/auth"
import EmptyCartIcon from "./EmptyCartIcon"
import RFQs from "../pages/cart/RFQ"
import Content from "../assets/images/content1.png"

export default function Cart() {
  const { openModal } = useModal()
  const navigate = useNavigate()
  const location = useLocation()
  const [cart, setCart] = useState(
    () =>
      JSON.parse(sessionStorage.getItem("cart")) || {
        cart_items: [],
        site_visit: false,
      },
  )
  const [isGeneratingPdf, setIsGeneratingPdf] = useState(false)
  const [includeSiteVisit, setIncludeSiteVisit] = useState(cart.site_visit || false)
  const [siteVisitFee, setSiteVisitFee] = useState(0)
  const [isLoadingSiteVisitFee, setIsLoadingSiteVisitFee] = useState(false)
  const [isLoadingDesign, setIsLoadingDesign] = useState(false)

  useEffect(() => {
    const fetchSiteVisitFee = async () => {
      setIsLoadingSiteVisitFee(true)
      try {
        const response = await fetch("https://dash.brandexperts.ae/dash/site-visit/")
        if (!response.ok) throw new Error("Failed to fetch site visit fee")
        const data = await response.json()
        setSiteVisitFee(data.amount)
      } catch (error) {
        console.error("Error fetching site visit fee:", error)
        toast.error("Failed to load site visit fee. Using default value.")
        setSiteVisitFee(350)
      } finally {
        setIsLoadingSiteVisitFee(false)
      }
    }
    fetchSiteVisitFee()
  }, [])

  // Check for UUID in URL parameters
  useEffect(() => {
    const checkForUuid = async () => {
      const searchParams = new URLSearchParams(location.search)
      const uuid = searchParams.get("id")

      if (uuid) {
        setIsLoadingDesign(true)
        try {
          // Fetch design data using the UUID
          const response = await fetch(`https://dash.brandexperts.ae/generate-image/${uuid}/`)
          if (!response.ok) {
            console.error("Response not OK:", response.status, response.statusText)
            throw new Error(`Failed to fetch design data: ${response.status}`)
          }

          const designData = await response.json()
          console.log("Design data received:", designData) // Debug log

          // After receiving designData
          let currentCart = { cart_items: [], site_visit: false }

          // Try to get existing cart
          try {
            const existingCart = sessionStorage.getItem("cart")
            if (existingCart) {
              currentCart = JSON.parse(existingCart)
            }
          } catch (e) {
            console.error("Error parsing existing cart:", e)
            // Continue with empty cart
          }

          // Ensure the design data has a timestamp if not provided by API
          if (!designData.timestamp) {
            designData.timestamp = Date.now()
          }

          // Ensure quantity is set
          if (!designData.quantity) {
            designData.quantity = 1
          }

          // Ensure total is a number
          if (typeof designData.total === "string") {
            designData.total = Number.parseFloat(designData.total)
          }

          // Add new item to cart
          currentCart.cart_items.push(designData)

          // Save to session storage
          sessionStorage.setItem("cart", JSON.stringify(currentCart))

          // Update state
          setCart(currentCart)

          // Dispatch event to notify other components
          window.dispatchEvent(new Event("cartUpdated"))

          // Clear the URL parameter
          navigate("/cart", { replace: true })
          toast.success("Design added to cart!")
        } catch (error) {
          console.error("Error fetching design:", error)
          toast.error("Failed to load design. Please try again.")
        } finally {
          setIsLoadingDesign(false)
        }
      }
    }

    checkForUuid()
  }, [location, navigate])

  const updateCart = (newCart) => {
    setCart(newCart)
    sessionStorage.setItem("cart", JSON.stringify(newCart))
    window.dispatchEvent(new Event("cartUpdated"))
  }

  const removeItem = (timestamp) => {
    const newCart = {
      ...cart,
      cart_items: cart.cart_items.filter((item) => item.timestamp !== timestamp),
    }
    updateCart(newCart)
    toast.success("Item removed from cart")
  }

  const updateQuantity = (timestamp, newQuantity) => {
    if (!Number.isInteger(newQuantity) || newQuantity < 1) return

    const newCart = {
      ...cart,
      cart_items: cart.cart_items.map((item) => {
        if (item.timestamp === timestamp) {
          const unitProductPrice =
            (Number.parseFloat(item.total) - Number.parseFloat(item.designerFee || 0)) / item.quantity
          const newProductTotal = unitProductPrice * newQuantity
          const newTotal = newProductTotal + Number.parseFloat(item.designerFee || 0)

          return {
            ...item,
            quantity: newQuantity,
            total: newTotal.toFixed(2),
          }
        }
        return item
      }),
    }
    updateCart(newCart)
  }



  const generatePDF = () => {
    if (cart.cart_items.length === 0) {
      toast.error("Your cart is empty!")
      return
    }
  
    setIsGeneratingPdf(true)
  
    // Load the required libraries dynamically
    Promise.all([import("jspdf"), import("jspdf-autotable")])
      .then(([jsPDF, autoTable]) => {
        try {
          // Create new PDF document
          const doc = new jsPDF.default("p", "mm", "a4")
          const pageWidth = doc.internal.pageSize.width
          const pageHeight = doc.internal.pageSize.height
          const margin = 20
  
          // Company logo and header 
          const logoUrl = "https://brand-experts.b-cdn.net/designs/design_1742734835360_Picture.png";
          doc.addImage(logoUrl, "PNG", margin, margin, 50, 20);
          
          // Quote number - align to right side with light blue color
          doc.setTextColor(173, 216, 230) // Light blue
          doc.setFontSize(10)
          doc.setFont("helvetica", "normal")
          doc.text("QUOTE NUMBER", pageWidth - margin - 60, margin + 2)
          
          doc.setTextColor(0, 0, 0) // Black
          doc.setFontSize(14)
          doc.setFont("helvetica", "bold")
          doc.text(`BE-${Date.now().toString().slice(-4)}`, pageWidth - margin - 60, margin + 10)
          
          // SPR number
          doc.setTextColor(0, 0, 0)
          doc.setFontSize(10)
          doc.setFont("helvetica", "normal")
          doc.text(`SPR-${new Date().getFullYear()}-${Date.now().toString().slice(-5)}`, pageWidth - margin - 60, margin + 18)
  
          // Company details (left side)
          doc.setTextColor(0, 0, 0)
          doc.setFontSize(12)
          doc.setFont("helvetica", "bold")
          doc.text("Brand Experts Advertising LLC", margin, margin + 35)
          
          doc.setFontSize(10)
          doc.setFont("helvetica", "normal")
          doc.text("Industrial Area 17, Sharjah Kalba Ring Road", margin, margin + 42)
          doc.text("Sharjah, United Arab Emirates", margin, margin + 48)
          doc.text("PO Box 23943", margin, margin + 54)
          doc.text("+971 6 531 4088", margin, margin + 60)
  
          // Client details (right side) - right aligned
          // doc.setTextColor(0, 0, 0)
          // doc.setFontSize(12)
          // doc.setFont("helvetica", "bold")
          // doc.text("Sharjah Investment &", pageWidth - margin, margin + 35, { align: "right" })
          // doc.text("Development Authority", pageWidth - margin, margin + 42, { align: "right" })
          
          // doc.setFontSize(10)
          // doc.setFont("helvetica", "normal")
          // doc.text("Mr Ahmed Mirza", pageWidth - margin, margin + 48, { align: "right" })
          // doc.text("Sharjah", pageWidth - margin, margin + 54, { align: "right" })
          // doc.text("Sharjah", pageWidth - margin, margin + 60, { align: "right" })
          // doc.text("867", pageWidth - margin, margin + 66, { align: "right" })
          // doc.text("United Arab Emirates", pageWidth - margin, margin + 72, { align: "right" })
          // doc.text("100349640000003", pageWidth - margin, margin + 78, { align: "right" })
  
          // Amount section - light blue color for label
          doc.setTextColor(173, 216, 230) // Light blue
          doc.setFontSize(10)
          doc.text("AMOUNT (AED)", pageWidth - margin, margin + 90, { align: "right" })
          
          // Calculate total
          const subtotal = cart.cart_items.reduce((sum, item) => sum + Number.parseFloat(item.total), 0).toFixed(2)
          const vat = (Number.parseFloat(subtotal) * 0.05).toFixed(2)
          const total = (Number.parseFloat(subtotal) + Number.parseFloat(vat) + (includeSiteVisit ? siteVisitFee : 0)).toFixed(2)
          
          // Large total amount in black
          doc.setTextColor(0, 0, 0)
          doc.setFontSize(24)
          doc.setFont("helvetica", "bold")
          doc.text(`AED${total}`, pageWidth - margin, margin + 105, { align: "right" })
          
          // Valid until - light blue color for label
          doc.setTextColor(173, 216, 230) // Light blue
          doc.setFontSize(10)
          doc.setFont("helvetica", "normal")
          doc.text("VALID UNTIL", pageWidth - margin, margin + 115, { align: "right" })
          
          // Calculate date 30 days from now
          const validUntil = new Date()
          validUntil.setDate(validUntil.getDate() + 30)
          
          doc.setTextColor(0, 0, 0)
          doc.text(validUntil.toLocaleDateString('en-GB', {day: '2-digit', month: 'short', year: 'numeric'}), 
            pageWidth - margin, margin + 122, { align: "right" })
  
          // Overview section
          doc.setFontSize(11)
          doc.setFont("helvetica", "bold")
          doc.text("", margin, margin + 135)
          doc.setFontSize(10)
          doc.setFont("helvetica", "normal")
          doc.text("", margin, margin + 142)
  
          // Prepare table data
          const tableColumn = ["#", "NAME AND DESCRIPTION", "UNIT", "QTY", "PRICE/RATE", "LINE TOTAL"]
          const tableRows = []
  
          // // Sample data based on the image provided
          // // In your actual code, you'll use cart.cart_items instead
          // const sampleItem = {
          //   name: "supply & application of digital printed vinyl with matt lamination applied directly on existing glass wall",
          //   unit: "Sqm",
          //   quantity: 18,
          //   unitPrice: 85.00,
          //   total: 1530.00
          // }
  
          // tableRows.push([
          //   1,
          //   sampleItem.name,
          //   sampleItem.unit,
          //   sampleItem.quantity,
          //   `AED${sampleItem.unitPrice.toFixed(2)}`,
          //   `AED${sampleItem.total.toFixed(2)}\n+VAT`
          // ])
  
          // For your actual implementation, use this loop instead of the sample data above
          
          cart.cart_items.forEach((item, index) => {
            const unitPrice = (
              (Number.parseFloat(item.total) - Number.parseFloat(item.designerFee || 0)) /
              item.quantity
            ).toFixed(2)
  
            let description = item.name
            if (item.designerFee > 0) {
              description += `\nIncludes Designer Fee: ${item.designerFee} AED`
            }
            description += `\n${item.standardSize || `${item.customSize?.width}x${item.customSize?.height} ${item.customSize?.unit}`}`
  
            tableRows.push([
              index + 1,
              description,
              "Sqm",
              item.quantity,
              `AED${unitPrice}`,
              `AED${item.total}\n+VAT`
            ])
          })
          
  
          // Add site visit if included
          if (includeSiteVisit) {
            tableRows.push([
              tableRows.length + 1,
              "Site Visit Fee",
              "Service",
              1,
              `AED${siteVisitFee}`,
              `AED${siteVisitFee}\n+VAT`
            ])
          }
  
          // Add table
          autoTable.default(doc, {
            head: [tableColumn],
            body: tableRows,
            startY: margin + 145,
            theme: "plain",
            styles: {
              fontSize: 9,
              cellPadding: 4,
            },
            columnStyles: {
              0: { cellWidth: 10 },
              1: { cellWidth: 80 },
              2: { cellWidth: 20 },
              3: { cellWidth: 15 },
              4: { cellWidth: 25 },
              5: { cellWidth: 30 },
            },
            headStyles: {
              fillColor: [255, 255, 255],
              textColor: [100, 100, 100],
              fontStyle: "bold",
            },
          })
  
          // Get Y position after the table
          const finalY = doc.lastAutoTable.finalY + 10
  
          // Add summary section - right aligned with labels on left and values on right
          doc.text("Subtotal", pageWidth - margin - 60, finalY)
          doc.text(`AED${subtotal}`, pageWidth - margin, finalY, { align: "right" })
          
          doc.text("VAT (5%)", pageWidth - margin - 60, finalY + 7)
          doc.text(`AED${vat}`, pageWidth - margin, finalY + 7, { align: "right" })
          
          doc.setFont("helvetica", "bold")
          doc.text("TOTAL (AED)", pageWidth - margin - 60, finalY + 15)
          doc.text(`AED${total}`, pageWidth - margin, finalY + 15, { align: "right" })
  
          // Terms and conditions
          doc.setFont("helvetica", "bold")
          doc.text("Terms and Conditions", margin, finalY + 25)
          doc.setFont("helvetica", "normal")
          doc.text("1. Order to be confirmed by LPO", margin, finalY + 32)
          doc.text("2. Payment: 100% on work completion", margin, finalY + 39)
          doc.text("3. Delivery: Within 3 to 5 working days after receipt of LPO artwork files", margin, finalY + 46)
  
          // Page number
          doc.setFontSize(8)
          doc.text(`Page 1/1`, pageWidth/2, pageHeight - 10, { align: "center" })
  
          // Save the PDF
          doc.save(`Brand_Experts_Quote_${new Date().toISOString().split("T")[0]}.pdf`)
  
          toast.success("Quote PDF Downloaded Successfully!")
        } catch (error) {
          console.error("Error generating PDF:", error)
          toast.error("Failed to generate PDF. Please try again.")
        } finally {
          setIsGeneratingPdf(false)
        }
      })
      .catch((error) => {
        console.error("Error loading PDF libraries:", error)
        toast.error("Failed to load PDF generation tools. Please try again.")
        setIsGeneratingPdf(false)
      })
  }


  const handleCheckout = () => {
    if (!isAuthenticated()) {
      openModal("register")
      return
    }
    navigate("/checkout")
  }

  if (isLoadingDesign) {
    return (
      <div className="min-h-[calc(100vh-160px)] bg-gray-50 flex items-center justify-center">
        <div className="text-center p-8">
          <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-red-600 mx-auto mb-4"></div>
          <h2 className="text-2xl font-bold text-gray-800">Loading Your Design</h2>
          <p className="text-gray-600 mt-2">Please wait while we add your design to the cart...</p>
        </div>
      </div>
    )
  }

  if (cart.cart_items.length === 0) {
    return (
      <div className="min-h-[calc(100vh-160px)] bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 py-16">
          <div className="bg-white rounded-2xl shadow-sm p-8 text-center">
            <div className="flex justify-center mb-6">
              <EmptyCartIcon className="w-32 h-32 text-gray-400" />
            </div>
            <h2 className="text-3xl font-bold mb-4 text-gray-800">Your Shopping Cart is Empty</h2>
            <p className="text-gray-600 mb-8 max-w-md mx-auto">
              Start designing your perfect custom products or explore our ready-made collections.
            </p>
            <div className="flex gap-4 justify-center">
              <button
                onClick={() => navigate("/products")}
                className="bg-gradient-to-r from-[#BF1A1C] to-[#590C0D] text-white px-8 py-4 rounded-xl
                hover:shadow-lg transform transition-all duration-300 hover:-translate-y-1 cursor-pointer"
              >
                Browse Products
              </button>
              <button
                onClick={() => navigate("/saved-rfqs")}
                className="bg-white border-2 border-red-600 text-red-600 px-8 py-4 rounded-xl
                hover:bg-red-50 transition-all duration-300 cursor-pointer"
              >
                Load RFQs
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="min-h-[calc(100vh-160px)] bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 py-8">
        <div className="flex flex-col lg:flex-row gap-8">
          <div className="lg:w-2/3">
            <RFQs
              cart={cart}
              includeSiteVisit={includeSiteVisit}
              siteVisitFee={siteVisitFee}
              isLoadingSiteVisitFee={isLoadingSiteVisitFee}
              generatePDF={generatePDF}
              isGeneratingPdf={isGeneratingPdf}
            />

            <div className="space-y-4">
              {cart.cart_items.map((item) => (
                <div
                  key={item.timestamp}
                  className="bg-white rounded-xl shadow-sm border border-gray-100 p-6 hover:shadow-md transition-shadow"
                >
                  <div className="flex flex-col md:flex-row gap-6">
                    <div className="relative group">
                      {item.designerFee > 0 ? (
                        <div className="w-40 h-40 bg-gray-50 rounded-lg flex items-center justify-center">
                          <img src={Content || "/placeholder.svg"} alt="" className="w-20 h-20 object-cover" />
                        </div>
                      ) : (
                        <img
                          src={item.design_image || "placeholder.svg"}
                          alt="Design preview"
                          className="w-40 h-40 object-cover rounded-lg"
                        />
                      )}
                    </div>

                    <div className="flex-1 flex flex-col md:flex-row justify-between">
                      <div>
                        <h3 className="font-semibold text-xl mb-2">{item.name}</h3>
                        <div className="space-y-2 text-sm text-gray-600">
                          <p>
                            <span className="font-medium">Size:</span>{" "}
                            {item.standardSize ||
                              `${item.customSize?.width}x${item.customSize?.height} ${item.customSize.unit}`}
                          </p>
                          <p>
                            <span className="font-medium">Design ID:</span> {item.timestamp.toString().slice(-8)}
                          </p>
                          <p>
                            <span className="font-medium">Unit Price:</span>{" "}
                            {(
                              (Number.parseFloat(item.total) - Number.parseFloat(item.designerFee || 0)) /
                              item.quantity
                            ).toFixed(2)}{" "}
                            AED
                          </p>
                          {item.designerFee > 0 && (
                            <p>
                              <span className="font-medium">Designer Fee:</span> {item.designerFee} AED
                            </p>
                          )}
                          {item.amazon_url && (
                            <p>
                              <a
                                href={item.amazon_url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-blue-600 hover:underline"
                              >
                                View on Amazon
                              </a>
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="mt-4 md:mt-0 flex flex-row md:flex-col justify-between items-end gap-4">
                        <div className="flex items-center gap-2 bg-gray-50 rounded-lg p-2">
                          <button
                            onClick={() => updateQuantity(item.timestamp, item.quantity - 1)}
                            className="p-1 hover:bg-white rounded-md"
                          >
                            <Minus size={16} />
                          </button>
                          <input
                            type="number"
                            min="1"
                            value={item.quantity}
                            onChange={(e) => updateQuantity(item.timestamp, Number.parseInt(e.target.value) || 1)}
                            className="w-16 text-center bg-transparent border-none focus:ring-0"
                          />
                          <button
                            onClick={() => updateQuantity(item.timestamp, item.quantity + 1)}
                            className="p-1 hover:bg-white rounded-md"
                          >
                            <Plus size={16} />
                          </button>
                        </div>

                        <div className="text-right">
                          <div className="text-sm text-gray-500">Total</div>
                          <div className="text-xl font-bold text-gray-900">{item.total} AED</div>
                        </div>

                        <button
                          onClick={() => removeItem(item.timestamp)}
                          className="text-red-500 hover:text-red-600 p-2"
                        >
                          <Trash2 size={20} />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Order Summary */}
          <div className="lg:w-1/3">
            <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6 sticky top-4">
              <h3 className="text-xl font-bold mb-6">Order Summary</h3>
              <div className="space-y-4">
                <div className="flex justify-between text-gray-600">
                  <span>Subtotal ({cart.cart_items.length} items)</span>
                  <span className="font-medium">
                    {cart.cart_items.reduce((sum, item) => sum + Number.parseFloat(item.total), 0).toFixed(2)} AED
                  </span>
                </div>

                <div className="flex justify-between text-gray-600">
                  <span>Shipping</span>
                  <span>Calculated at checkout</span>
                </div>

                <div className="relative flex justify-between text-gray-600 items-center p-2 rounded-lg border-2 border-red-200 bg-red-50">
                  <motion.span
                    className="text-red-600 mr-4"
                    animate={{ x: 14 }}
                    transition={{
                      repeat: Number.POSITIVE_INFINITY,
                      repeatType: "reverse",
                      duration: 0.5,
                    }}
                  >
                    <ArrowRight size={24} weight="bold" />
                  </motion.span>
                  <div className="flex items-center gap-2 flex-1">
                    <input
                      type="checkbox"
                      id="siteVisit"
                      checked={includeSiteVisit}
                      onChange={(e) => {
                        const updatedCart = {
                          ...cart,
                          site_visit: e.target.checked,
                        }
                        updateCart(updatedCart)
                        setIncludeSiteVisit(e.target.checked)
                      }}
                      className="rounded border-gray-300 text-red-600 w-5 h-5 accent-red-600"
                    />
                    <label htmlFor="siteVisit" className="text-sm font-medium text-red-700">
                      Add site visit {isLoadingSiteVisitFee ? "(loading...)" : `(${siteVisitFee} AED)`}
                    </label>
                  </div>
                  <span className="font-medium text-red-700">{includeSiteVisit ? `${siteVisitFee} AED` : "-"}</span>
                </div>

                <div className="border-t pt-4 mt-4">
                  <div className="flex justify-between text-lg font-bold">
                    <span>Total</span>
                    <span>
                      {(
                        cart.cart_items.reduce((sum, item) => sum + Number.parseFloat(item.total), 0) +
                        (includeSiteVisit ? siteVisitFee : 0)
                      ).toFixed(2)}{" "}
                      AED
                    </span>
                  </div>
                </div>
              </div>
              <button
                onClick={handleCheckout}
                className="w-full mt-6 bg-gradient-to-r from-[#BF1A1C] to-[#590C0D] text-white px-8 py-4 rounded-xl
                hover:shadow-lg transform transition-all duration-300 hover:-translate-y-1"
              >
                <ShoppingBag size={20} className="inline mr-2" />
                Proceed to Checkout
              </button>
            </div>
            <button
              onClick={() => navigate("/products")}
              className="mt-8 flex items-center gap-2 text-gray-600 hover:text-red-600 text-lg"
            >
              <ArrowLeft size={20} />
              Continue Shopping
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

