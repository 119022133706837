"use client";

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowLeft, ShoppingBag, Calendar, Clock, Trash2 } from "lucide-react";
import { toast } from "react-toastify";

export default function SavedRFQs() {
  const navigate = useNavigate();
  const [savedRFQs, setSavedRFQs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Load saved RFQs from localStorage
    const loadSavedRFQs = () => {
      setIsLoading(true);
      try {
        const rfqs = JSON.parse(localStorage.getItem("savedRFQs") || "[]");
        // Sort by most recent first
        rfqs.sort((a, b) => new Date(b.saved_date) - new Date(a.saved_date));
        setSavedRFQs(rfqs);
      } catch (error) {
        console.error("Error loading saved RFQs:", error);
        toast.error("Failed to load saved RFQs");
        setSavedRFQs([]);
      } finally {
        setIsLoading(false);
      }
    };

    loadSavedRFQs();
  }, []);

  const handleLoadRFQ = (rfq) => {
    try {
      // Create a cart object from the saved RFQ
      const cart = {
        cart_items: rfq.cart_items,
        site_visit: rfq.site_visit
      };
      
      // Save to sessionStorage
      sessionStorage.setItem("cart", JSON.stringify(cart));
      
      // Notify user
      toast.success(`RFQ "${rfq.name}" loaded successfully`);
      
      // Trigger cart update event
      window.dispatchEvent(new Event("cartUpdated"));
      
      // Redirect to cart page
      navigate("/cart");
    } catch (error) {
      console.error("Error loading RFQ:", error);
      toast.error("Failed to load RFQ. Please try again.");
    }
  };

  const handleDeleteRFQ = (index, name) => {
    try {
      // Create a copy of the current saved RFQs
      const updatedRFQs = [...savedRFQs];
      
      // Remove the selected RFQ
      updatedRFQs.splice(index, 1);
      
      // Update state
      setSavedRFQs(updatedRFQs);
      
      // Update localStorage
      localStorage.setItem("savedRFQs", JSON.stringify(updatedRFQs));
      
      // Notify user
      toast.success(`RFQ "${name}" deleted successfully`);
    } catch (error) {
      console.error("Error deleting RFQ:", error);
      toast.error("Failed to delete RFQ. Please try again.");
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString(undefined, {
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  if (isLoading) {
    return (
      <div className="min-h-[calc(100vh-160px)] bg-gray-50 flex items-center justify-center">
        <div className="animate-spin h-12 w-12 border-4 border-red-600 rounded-full border-t-transparent"></div>
      </div>
    );
  }

  if (savedRFQs.length === 0) {
    return (
      <div className="min-h-[calc(100vh-160px)] bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 py-16">
          <div className="bg-white rounded-2xl shadow-sm p-8 text-center">
            <div className="flex justify-center mb-6">
              <ShoppingBag className="w-32 h-32 text-gray-400" />
            </div>
            <h2 className="text-3xl font-bold mb-4 text-gray-800">
              No Saved RFQs Found
            </h2>
            <p className="text-gray-600 mb-8 max-w-md mx-auto">
              You haven't saved any Request for Quotations yet. Add items to your cart and save an RFQ to see it here.
            </p>
            <div className="flex gap-4 justify-center">
              <button
                onClick={() => navigate("/products")}
                className="bg-gradient-to-r from-[#BF1A1C] to-[#590C0D] text-white px-8 py-4 rounded-xl
                hover:shadow-lg transform transition-all duration-300 hover:-translate-y-1"
              >
                Browse Products
              </button>
              <button
                onClick={() => navigate("/cart")}
                className="bg-white border-2 border-red-600 text-red-600 px-8 py-4 rounded-xl
                hover:bg-red-50 transition-all duration-300"
              >
                Go to Cart
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-[calc(100vh-160px)] bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 py-8">
        <div className="flex items-center justify-between mb-6">
          <h1 className="text-2xl font-bold text-gray-800">Saved Requests for Quotation</h1>
          <button
            onClick={() => navigate("/cart")}
            className="flex items-center gap-2 text-gray-600 hover:text-red-600"
          >
            <ArrowLeft size={20} />
            Back to Cart
          </button>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {savedRFQs.map((rfq, index) => (
            <div 
              key={index} 
              className="bg-white rounded-xl shadow-sm border border-gray-100 p-6 hover:shadow-md transition-shadow"
            >
              <div className="flex justify-between items-start mb-4">
                <h3 className="font-semibold text-xl">{rfq.name}</h3>
                <button
                  onClick={() => handleDeleteRFQ(index, rfq.name)}
                  className="text-red-500 hover:text-red-600 p-2"
                  aria-label="Delete RFQ"
                >
                  <Trash2 size={20} />
                </button>
              </div>
              
              <div className="space-y-3 mb-4">
                <div className="flex items-center gap-2 text-sm text-gray-600">
                  <Calendar size={16} />
                  <span>{formatDate(rfq.saved_date)}</span>
                  <Clock size={16} className="ml-2" />
                  <span>{formatTime(rfq.saved_date)}</span>
                </div>
                
                <div className="text-sm">
                  <span className="font-medium">Items:</span> {rfq.cart_items.length}
                </div>
                
                <div className="text-sm">
                  <span className="font-medium">Site Visit:</span> {rfq.site_visit ? 'Yes' : 'No'}
                </div>
                
                <div className="text-xl font-bold mt-2">
                  Total: {rfq.total} AED
                </div>
              </div>
              
              <button
                onClick={() => handleLoadRFQ(rfq)}
                className="w-full bg-gradient-to-r from-[#BF1A1C] to-[#590C0D] text-white px-6 py-3 rounded-xl
                hover:shadow-lg transform transition-all duration-300 hover:-translate-y-1"
              >
                Load RFQ
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}