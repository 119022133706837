import React, { createContext, useContext, useState, useEffect } from "react";

const CartContext = createContext();

export const useCart = () => {
  return useContext(CartContext);
};

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const fetchCart = () => {
      try {
        const cartData = JSON.parse(sessionStorage.getItem("cart")) || [];
        setCart(cartData);
        const cartTotal = cartData.reduce(
          (sum, item) => sum + (parseFloat(item.total) || 0),
          0
        );
        setTotal(cartTotal);
      } catch (error) {
        console.error("Error loading cart data:", error);
        setCart([]);
        setTotal(0);
      }
    };

    fetchCart();
  }, []);

  const addToCart = (newItem) => {
    setCart((prevCart) => {
      const existingItem = prevCart.find((item) => item.id === newItem.id);
      
      if (existingItem) {
        return prevCart.map((item) =>
          item.id === newItem.id
            ? {
                ...item,
                quantity: item.quantity + newItem.quantity,
                total: (parseFloat(item.total) + parseFloat(newItem.total)).toFixed(2),
              }
            : item
        );
      }
  
      return [...prevCart, newItem];
    });
  };
  
  const removeFromCart = (itemId) => {
    try {
      setCart((prevCart) => {
        const itemToRemove = prevCart.find((item) => item.id === itemId);
        if (!itemToRemove) return prevCart;

        const updatedCart = prevCart.filter((item) => item.id !== itemId);
        sessionStorage.setItem("cart", JSON.stringify(updatedCart));
        setTotal(
          (prevTotal) => prevTotal - (parseFloat(itemToRemove.total) || 0)
        );
        return updatedCart;
      });
    } catch (error) {
      console.error("Error removing item from cart:", error);
    }
  };

  const value = {
    cart,
    total,
    addToCart,
    removeFromCart,
  };
  

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};

export default CartProvider;
