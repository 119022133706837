import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { useModal } from "../context/ModalContext";

export default function ProtectedRoute({ children }) {
  const { isAuthenticated, loading } = useAuth();
  const { openModal } = useModal();
  const location = useLocation();

  // Show loading indicator while checking authentication
  if (loading) {
    return <div className="flex justify-center items-center h-screen">
      <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-red-600"></div>
    </div>;
  }

  if (!isAuthenticated) {
    openModal("login");
    // Store the location the user was trying to access
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
}