const ShippingPolicies = () => {
    return (
      <div className="container mx-auto my-6 px-4">
        <h1 className="text-3xl font-bold mb-4">Shipping Policy</h1>
        <p className="mb-4">
          We are currently shipping worldwide! <strong>Please Note:</strong> We do
          not ship to APO/FPO addresses or P.O. Boxes.
        </p>
  
        {/* <h2 className="text-lg font-semibold mt-6 mb-2">Shipping Service</h2>
        <p>We currently ship through UPS.</p>
  
        <h2 className="text-lg font-semibold mt-6 mb-2">Shipping Options</h2>
        <p>
          We offer several different shipping options to meet your needs when
          shipping to the continental U.S. and Canada.
        </p>
  
        <div className="mb-4">
          <h3 className="text-lg font-semibold mt-4 mb-3">
            For shipments within the U.S.
          </h3>
  
          <p>UPS Ground Shipping: Allow 1-5 business days from date shipped.</p>
  
          <p>UPS 3 Day Select: Allow 3 business days from date shipped.</p>
  
          <p>UPS 2nd Day Air: Allow 2 business days from date shipped.</p>
  
          <p>UPS Next Day Air: Allow 1 business day from date shipped.</p>
  
          <p>
            UPS Next Day Air EARLY AM: Allow 1 business day - Order arrives by 9
            AM (not available on all orders).
          </p>
  
          <p>
            UPS Freight: Allow 1-5 business days from date shipped (additional
            transit time may apply for rural areas).
          </p>
        </div>
  
        <div className="flex justify-center">
          <img
            src="https://cdn.printnetwork.com/production/assets/5966561450122033bd4456f8/imageLocker/page/ups_transit-map.png"
            alt="map_image"
          />
        </div>
        <div>
          <h3 className="text-lg font-semibold mt-4">For Shipments To Canada</h3>
  
          <p>
            UPS Standard:Allow 5-10 business days from date shipped (duties and
            fees may apply).
          </p>
  
          <p>UPS WorldWide Expedited: Allow 2 business days from date shipped.</p>
  
          <p>UPS Saver: Allow 1 business day from date shipped.</p>
  
          <h2 className="text-lg font-semibold mt-6 mb-2">Shipping Notes</h2>
          <p>
            BrandExperts makes every attempt to fulfill orders based on the date
            in which an order is placed and artwork has been uploaded approved for
            print, however, holidays and unforeseen circumstances can force
            exceptions to delivery. Below are important shipping notes to keep in
            mind when placing your order.
          </p>
        </div>
        <ul className="list-disc ml-6 mb-4">
          <li>All shipments require a street address (No P.O. Boxes).</li>
          <li>
            We are not responsible for incorrect or incomplete shipping addresses.
          </li>
          <li>
            Inclement weather and other unforeseen circumstances can cause
            shipment delays.
          </li>
          <li>
            UPS will make three delivery attempts before returning the
            merchandise.
          </li>
        </ul>
  
        <h2 className="text-lg font-semibold mt-6 mb-2">Freight Shipments</h2>
        <p>
          UPS Freight Shipments take between 1- 5 business days for transit.
          Depending on your delivery location, transit time may be longer.
        </p>
  
        <h2 className="text-lg font-semibold mt-6 mb-2">
          Special Delivery Handling
        </h2>
        <p>
          Special Delivery Handling options are available for most Freight
          shipments. If you require any of these options at the time of delivery,
          additional charges will be applied to your Order. Printmoz is not
          responsible for special handling for any order at the time of delivery.
        </p>
  
        <div className="p-4 my-6">
          <div className="w-full overflow-x-auto shadow-md rounded-lg">
            <table className="w-full border-collapse bg-white">
              <thead>
                <tr className="bg-gray-50 border-b border-gray-200">
                  <th className="py-3 px-4 text-left font-semibold text-gray-900 border-r border-gray-200">
                    Special Service
                  </th>
                  <th className="py-3 px-4 text-left font-semibold text-gray-900 border-r border-gray-200">
                    Rate
                  </th>
                  <th className="py-3 px-4 text-left font-semibold text-gray-900 border-r border-gray-200">
                    Flat Charge or
                    <br />
                    Minimum Charge
                  </th>
                  <th className="py-3 px-4 text-left font-semibold text-gray-900">
                    Description
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="border-b border-gray-200">
                  <td className="py-3 px-4 text-gray-800 border-r border-gray-200">
                    Inside Pickup or
                    <br />
                    Delivery
                  </td>
                  <td className="py-3 px-4 text-gray-800 border-r border-gray-200">
                    $10.80
                    <br />
                    per cwt.
                  </td>
                  <td className="py-3 px-4 text-gray-800 border-r border-gray-200">
                    $90.00
                  </td>
                  <td className="py-3 px-4 text-gray-800">
                    When the customer asks for it, UPS can occasionally unload
                    items to areas that are not adjacent the road or trailer, such
                    as into a store or shopping center.
                  </td>
                </tr>
                <tr className="border-b border-gray-200 bg-gray-50">
                  <td className="py-3 px-4 text-gray-800 border-r border-gray-200">
                    Lift Gate
                  </td>
                  <td className="py-3 px-4 text-gray-800 border-r border-gray-200">
                    $7.90
                    <br />
                    per cwt.
                  </td>
                  <td className="py-3 px-4 text-gray-800 border-r border-gray-200">
                    $125.00
                  </td>
                  <td className="py-3 px-4 text-gray-800">
                    If a loading dock or other tools are not available, UPS can
                    provide a lift gate to help get the job done on time.
                  </td>
                </tr>
                <tr className="border-b border-gray-200">
                  <td className="py-3 px-4 text-gray-800 border-r border-gray-200">
                    Limited Access
                    <br />
                    Locations
                  </td>
                  <td className="py-3 px-4 text-gray-800 border-r border-gray-200">
                    None
                  </td>
                  <td className="py-3 px-4 text-gray-800 border-r border-gray-200">
                    $115.00
                  </td>
                  <td className="py-3 px-4 text-gray-800">
                    Some locations are more difficult to get to and have
                    restricted access. If this is the case, such as in a school or
                    secured location, UPS has options to deliver these packages
                    for an additional flat charge.
                  </td>
                </tr>
                <tr className="border-b border-gray-200 bg-gray-50">
                  <td className="py-3 px-4 text-gray-800 border-r border-gray-200">
                    Pickup or Delivery on
                    <br />
                    Saturday, Sundays or
                    <br />
                    Holidays
                  </td>
                  <td className="py-3 px-4 text-gray-800 border-r border-gray-200">
                    $195.00
                    <br />
                    per person
                    <br />
                    per hour
                  </td>
                  <td className="py-3 px-4 text-gray-800 border-r border-gray-200">
                    $480.00
                  </td>
                  <td className="py-3 px-4 text-gray-800">
                    Normally, UPS only delivers on Monday through Friday not
                    including holidays. However, advance preparations can be
                    arranged where UPS will deliver on these days for a fee.
                  </td>
                </tr>
                <tr>
                  <td className="py-3 px-4 text-gray-800 border-r border-gray-200">
                    Redelivery
                  </td>
                  <td className="py-3 px-4 text-gray-800 border-r border-gray-200">
                    None
                  </td>
                  <td className="py-3 px-4 text-gray-800 border-r border-gray-200">
                    $95.00
                  </td>
                  <td className="py-3 px-4 text-gray-800">
                    If you are not there or are unable to sign off on delivery of
                    your item for any reason, UPS can schedule to have the item
                    redelivered at a later time.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div> */}
      </div>
    );
  };
  
  export default ShippingPolicies;