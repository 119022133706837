import React, { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { Package, Clock, Info, Truck, ChevronRight, ShoppingBag } from "lucide-react";
import { Link, useParams } from "react-router-dom";
import API from "../loginSignin/Api";

const statusColors = {
  ordered: { bg: "bg-amber-100", text: "text-amber-800", Icon: Clock },
  delivered: { bg: "bg-green-100", text: "text-green-800", Icon: Package },
  cancelled: { bg: "bg-red-100", text: "text-red-800", Icon: Info },
  shipped: { bg: "bg-blue-100", text: "text-blue-800", Icon: Truck },
};

export default function Orders() {
  const [userId, setUserId] = useState(null);
  const [activeTab, setActiveTab] = useState("all");
  
  useEffect(() => {
    const loggedInUserId = sessionStorage.getItem("customer_id") || null;
    setUserId(loggedInUserId);
  }, []);
  
  const activeCustomerId = userId;
  
  const fetchOrders = async () => {
    if (!activeCustomerId) return [];
    const response = await API.get(`/orders/${activeCustomerId}/`);
    return response.data;
  };

  const { data: orders, isLoading, error } = useQuery({
    queryKey: ["orders", activeCustomerId],
    queryFn: fetchOrders,
    enabled: !!activeCustomerId,
  });

  // Loading State with Skeleton UI
  if (!activeCustomerId) {
    return (
      <div className="max-w-3xl mx-auto px-4 py-8">
        <div className="animate-pulse">
          <div className="h-8 w-48 bg-gray-200 rounded mb-6"></div>
          <div className="h-10 bg-gray-200 rounded mb-6"></div>
          <div className="space-y-4">
            {[1, 2, 3].map(i => (
              <div key={i} className="h-36 bg-gray-200 rounded"></div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="max-w-3xl mx-auto px-4 py-8">
        <h1 className="text-2xl font-bold text-gray-800 mb-6">My Orders</h1>
        <div className="animate-pulse">
          <div className="h-10 bg-gray-200 rounded mb-6"></div>
          <div className="space-y-4">
            {[1, 2, 3].map(i => (
              <div key={i} className="h-36 bg-gray-200 rounded"></div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="max-w-3xl mx-auto px-4 py-12 text-center">
        <div className="p-6 bg-red-50 rounded-lg inline-flex items-center justify-center">
          <Info className="w-12 h-12 text-red-500" />
        </div>
        <h2 className="mt-4 text-xl font-medium text-gray-900">Unable to load orders</h2>
        <p className="mt-2 text-gray-500">{error.message}</p>
        <button 
          onClick={() => window.location.reload()} 
          className="mt-6 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
        >
          Try Again
        </button>
      </div>
    );
  }

  // Empty State with Animation
  if (!orders || orders.length === 0) {
    return (
      <div className="max-w-3xl mx-auto px-4 py-16 text-center">
        <div className="inline-block p-6 bg-gray-50 rounded-full animate-bounce-slow">
          <ShoppingBag className="w-16 h-16 text-red-400" />
        </div>
        <h2 className="mt-6 text-2xl font-medium text-gray-900">Your order list is empty</h2>
        <p className="mt-3 text-gray-500 max-w-md mx-auto">
          Looks like you haven't placed any orders yet. Browse our products and discover amazing items!
        </p>
        <Link 
          to="/products" 
          className="mt-8 inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 transition-all duration-300 transform hover:scale-105"
        >
          Start Shopping
          <ChevronRight className="ml-2 w-5 h-5" />
        </Link>
      </div>
    );
  }

  // Get unique statuses from orders
  const statuses = ["all", ...new Set(orders.map(order => order.status))];
  
  // Filter orders based on activeTab
  const filteredOrders = activeTab === "all" 
    ? orders 
    : orders.filter(order => order.status === activeTab);

  return (
    <div className="max-w-3xl mx-auto px-4 py-6">
      <h1 className="text-2xl font-bold text-gray-800">My Orders</h1>
      
      {/* Status Tabs */}
      <div className="border-b border-gray-200 mt-6">
        <nav className="-mb-px flex space-x-6 overflow-x-auto" aria-label="Order status filters">
          {statuses.map(status => (
            <button
              key={status}
              onClick={() => setActiveTab(status)}
              className={`whitespace-nowrap pb-3 px-1 border-b-2 font-medium text-sm ${
                activeTab === status
                  ? 'border-red-500 text-red-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              {status === "all" ? "All Orders" : status.charAt(0).toUpperCase() + status.slice(1)}
            </button>
          ))}
        </nav>
      </div>

      {/* Orders List */}
      <div className="mt-6 space-y-4">
        {filteredOrders.map((order) => {
          const StatusIcon = statusColors[order.status]?.Icon || Info;
          const itemCount = order.cart.items.length;
          const firstItem = order.cart.items[0];
          
          return (
            <Link 
              to={`/order-details/${order.id}`}
              key={order.id} 
              className="block bg-white rounded-lg shadow-sm border border-gray-200 overflow-hidden hover:shadow-md transition-shadow"
            >
              {/* Order Status Bar */}
              <div className={`h-2 ${statusColors[order.status]?.bg || "bg-gray-100"}`} />
              
              {/* Order Summary */}
              <div className="p-4">
                <div className="flex justify-between items-start mb-3">
                  <div>
                    <div className="flex items-center">
                      <span className={`inline-flex items-center justify-center w-8 h-8 rounded-full ${statusColors[order.status]?.bg || "bg-gray-100"} mr-2`}>
                        <StatusIcon className={`w-4 h-4 ${statusColors[order.status]?.text || "text-gray-800"}`} />
                      </span>
                      <h3 className="font-medium text-gray-900">Order #{order.id}</h3>
                    </div>
                    <p className="text-sm text-gray-500 mt-1">
                      {new Date(order.ordered_date).toLocaleDateString()}
                    </p>
                  </div>
                  <div className="text-right">
                    <p className="font-medium text-gray-900">{parseFloat(order.amount).toFixed(2)} AED</p>
                    <span className={`inline-block mt-1 px-2 py-0.5 rounded-full text-xs font-medium ${statusColors[order.status]?.bg || "bg-gray-100"} ${statusColors[order.status]?.text || "text-gray-800"}`}>
                      {order.status.charAt(0).toUpperCase() + order.status.slice(1)}
                    </span>
                  </div>
                </div>
                
                {/* Preview of first item */}
                <div className="flex items-center">
                  <img
                    src={firstItem.design_image !== "https://default-image-url.com" ? firstItem.design_image : "https://placehold.co/100x100"}
                    alt="Product"
                    className="h-14 w-14 object-cover rounded"
                  />
                  <div className="ml-3 flex-1">
                    <p className="font-medium text-gray-900">Product #{firstItem.product}</p>
                    <p className="text-xs text-gray-500">
                      {firstItem.custom_width}x{firstItem.custom_height} {firstItem.size_unit} • Qty: {firstItem.quantity}
                    </p>
                  </div>
                  {itemCount > 1 && (
                    <span className="text-xs text-gray-500">+{itemCount - 1} more item{itemCount > 2 ? 's' : ''}</span>
                  )}
                </div>
                
                {/* View details */}
                <div className="mt-3 flex justify-between items-center pt-3 border-t border-gray-100">
                  <div className="text-sm">
                    <span className="text-gray-500">Payment:</span>
                    <span className="ml-1 text-gray-900 capitalize">{order.payment_method}</span>
                  </div>
                  <div className="flex items-center text-red-600 text-sm font-medium">
                    View Details
                    <ChevronRight className="w-4 h-4 ml-1" />
                  </div>
                </div>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
}