import React from "react";

function Hsection13() {
  return (
    <section className=" py-12 px-6 md:px-12 text-center md:text-left">
      <div className="max-w-4xl mx-auto">
        <h2 className="text-3xl font-bold text-gray-700 mb-4">
          Brand Experts Advertising L.L.C
        </h2>
        <p className="text-lg text-gray-500 leading-relaxed">
          Welcome to <strong>Brand Experts</strong>, your premier destination for high-quality online
          sign printing solutions. We specialize in delivering top-notch custom signage tailored
          to meet all your visual communication needs. Our platform features an <strong>intuitive design tool</strong>
          that empowers you to create and personalize your own signs effortlessly. Whether you need
          <strong>canvas prints</strong> for home decor, <strong>car decals</strong> for branding, <strong>vinyl lettering</strong>
          for storefronts, or elegant <strong>acrylic displays</strong>, we provide a seamless experience
          from design to production. Elevate your brand with our durable and visually striking signage solutions today!
        </p>
      </div>
    </section>
  );
}

export default Hsection13;