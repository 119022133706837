import Tsection1 from "./Tsection1";

const Templates = () => {
  return (
    <div>
      <Tsection1 />
    </div>
  );
};

export default Templates;
