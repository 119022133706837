"use client";

import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  User,
  Mail,
  Phone,
  Plus,
  Trash2,
  MoreHorizontal,
  Search,
  Bell,
  Home,
  Users,
  Menu,
  Wallet,
  Handshake,
  Download,
  FileSpreadsheet,
  Upload,
} from "lucide-react";
import axios from "axios";
import { toast, Toaster } from "sonner";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Api from "../loginSignin/Api";

// Update these imports to match your project structure
// Remove the @/ prefix if your project doesn't use path aliases
import { Button } from "../../components/ui/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import { Input } from "../../components/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../components/ui/dropdown-menu";
import { Label } from "../../components/ui/label";
import { Badge } from "../../components/ui/badge";
import { Separator } from "../../components/ui/separator";
import { Sheet, SheetContent, SheetTrigger } from "../../components/ui/sheet";
import { Skeleton } from "../../components/ui/skeleton";

export default function Dashboard() {
  const [userDetails, setUserDetails] = useState(null);
  const [contacts, setContacts] = useState([]);
  const [totalContacts, setTotalContacts] = useState(0);
  const [loading, setLoading] = useState(true);
  const [contactLoading, setContactLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isAddContactOpen, setIsAddContactOpen] = useState(false);
  const [newContact, setNewContact] = useState({
    name: "",
    email: "",
    number: "",
    accounts: "",
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [accessToken, setAccessToken] = useState(null);
  const [activeTab, setActiveTab] = useState("dashboard"); // Add state for active tab
  const navigate = useNavigate();
  const fileInputRef = useRef(null); // Define fileInputRef

  // Get user details and access token from local storage
  useEffect(() => {
    try {
      const userData = localStorage.getItem("user_details");
      const token = localStorage.getItem("access_token");

      if (userData && token) {
        setUserDetails(JSON.parse(userData));
        setAccessToken(token);
      } else {
        setError("User details or access token not found in local storage");
      }
    } catch (err) {
      setError("Error reading from local storage: " + err.message);
    } finally {
      setLoading(false);
    }
  }, []);

  // Fetch contacts from API - only if user is a partner
  useEffect(() => {
    if (userDetails?.user_id && accessToken && userDetails?.is_partner) {
      fetchContacts();
    }
  }, [userDetails, accessToken]);

  const fetchContacts = async () => {
    setContactLoading(true);
    try {
      const response = await axios.get(
        `https://dash.brandexperts.ae/partner/partner-contacts/${userDetails.user_id}/`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setContacts(response.data.contacts);
      setTotalContacts(response.data.total_contacts);
    } catch (err) {
      console.error("Error fetching contacts:", err);
      toast.error("Failed to load contacts. Please try again later.");

      // Handle unauthorized error
      if (
        err.response &&
        (err.response.status === 401 || err.response.status === 403)
      ) {
        handleLogout();
        toast.error("Your session has expired. Please log in again.");
      }
    } finally {
      setContactLoading(false);
    }
  };

  const handleDeleteContact = async (id) => {
    try {
      await axios.delete(
        `https://dash.brandexperts.ae/partner/contacts/${id}/`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setContacts(contacts.filter((contact) => contact.id !== id));
      setTotalContacts((prev) => prev - 1);
      toast.success("The contact has been successfully removed.");
    } catch (err) {
      console.error("Error deleting contact:", err);
      toast.error("Failed to delete contact. Please try again.");

      // Handle unauthorized error
      if (
        err.response &&
        (err.response.status === 401 || err.response.status === 403)
      ) {
        handleLogout();
        toast.error("Your session has expired. Please log in again.");
      }
    }
  };

  const handleAddContact = async (e) => {
    e.preventDefault();
    try {
      // Format the data according to the API requirements
      const formattedContact = {
        partner_user_id: userDetails.user_id, // Make sure this matches the expected field
        name: newContact.name,
        email: newContact.email,
        mobile: newContact.number,
        accounts: newContact.accounts
          .split(",")
          .map((account) => account.trim()), // Convert to array
      };

      console.log("Sending formatted contact data:", formattedContact);

      const response = await axios.post(
        "https://dash.brandexperts.ae/partner/create_contacts/",
        formattedContact,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      setContacts([...contacts, response.data]);
      setTotalContacts((prev) => prev + 1);
      setNewContact({ name: "", email: "", number: "", accounts: "" });
      setIsAddContactOpen(false);
      toast.success("The new contact has been successfully added.");
    } catch (err) {
      console.error("Error adding contact:", err);

      if (err.response) {
        console.error("Error response data:", err.response.data);

        if (err.response.status === 400) {
          toast.error(
            "The server rejected your request. Please check your input data."
          );
        } else if (err.response.status === 401 || err.response.status === 403) {
          handleLogout();
          toast.error("Your session has expired. Please log in again.");
        } else {
          toast.error(
            `Error: ${err.response.status} - ${err.response.statusText}`
          );
        }
      } else {
        toast.error(
          "Failed to add contact. Please check your connection and try again."
        );
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewContact({ ...newContact, [name]: value });
  };

  const handleLogout = () => {
    localStorage.removeItem("user_details");
    localStorage.removeItem("access_token");
    navigate("/login");
  };

  const getTimeOfDay = () => {
    const hour = new Date().getHours();
    if (hour < 12) return "morning";
    if (hour < 18) return "afternoon";
    return "evening";
  };

  const filteredContacts = contacts.filter(
    (contact) =>
      contact.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      contact.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (contact.accounts &&
        contact.accounts.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  const downloadFile = async () => {
    try {
      const response = await Api.get("/partner/contacts/import/", {
        responseType: "blob", // Important for file downloads
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      // Create a Blob URL for the downloaded file
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "contacts_import.xlsx"); // Set file name
      document.body.appendChild(link);
      link.click();

      // Clean up
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
      toast.success("Sample file downloaded successfully");
    } catch (error) {
      console.error("Download failed:", error);
      toast.error("Failed to download sample file");
    }
  };

  // Function to handle file upload
  const handleFileUpload = async (e) => {
    console.log("handleFileUpload called");
    console.log("Event:", e);

    const file = e.target.files[0];
    if (!file) return;

    // Detailed file validation
    if (!file.name.endsWith(".xlsx") && !file.name.endsWith(".xls")) {
      toast.error(
        "Invalid file format. Please upload an Excel file (.xlsx or .xls)"
      );
      return;
    }

    const formData = new FormData();
    formData.append("user_id", userDetails.user_id);
    formData.append("excel_file", file);

    // Enhanced logging
    console.log("File upload details:", {
      fileName: file.name,
      fileSize: file.size,
      fileType: file.type,
      userId: userDetails.user_id,
    });

    try {
      const toastId = toast.loading("Uploading contacts...");

      const response = await Api.post("/partner/contacts/import/", formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "multipart/form-data",
        },
        // Add timeout and more detailed error catching
        timeout: 30000, // 30 seconds timeout
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          console.log(`Upload progress: ${percentCompleted}%`);
        },
      });

      toast.dismiss(toastId);

      // More comprehensive response handling
      console.log("Full server response:", response);

      if (response.data && response.data.success) {
        toast.success(
          response.data.message || "Contacts imported successfully"
        );
        fetchContacts(); // Refresh contacts list
      } else {
        // Handle potential error scenarios
        const errorMessage =
          response.data?.message ||
          response.data?.error ||
          "Unknown error occurred during upload";
        toast.error(errorMessage);
        console.error("Upload response error:", response.data);
      }
    } catch (err) {
      toast.dismiss();
      console.error("Comprehensive upload error:", err);

      // Detailed error handling
      if (err.response) {
        // The request was made and the server responded with a status code
        console.error("Error response data:", err.response.data);
        console.error("Error response status:", err.response.status);
        console.error("Error response headers:", err.response.headers);

        const errorMessage =
          err.response.data?.message ||
          err.response.data?.error ||
          `Error: ${err.response.status} - ${err.response.statusText}`;

        toast.error(errorMessage);

        // Additional specific error scenarios
        if (err.response.data.required_columns) {
          toast.error(
            `Required columns: ${err.response.data.required_columns.join(", ")}`
          );
        }
        if (err.response.data.suggestion) {
          toast.error(err.response.data.suggestion);
        }
      } else if (err.request) {
        // The request was made but no response was received
        console.error("No response received:", err.request);
        toast.error(
          "No response from server. Please check your network connection."
        );
      } else {
        // Something happened in setting up the request
        console.error("Error setting up request:", err.message);
        toast.error(`Upload failed: ${err.message}`);
      }
    } finally {
      // Reset file input
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="space-y-4 w-[400px]">
          <Skeleton className="h-12 w-full" />
          <Skeleton className="h-40 w-full" />
          <Skeleton className="h-40 w-full" />
        </div>
      </div>
    );
  }
  if (error) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Card className="w-[400px]">
          <CardHeader>
            <CardTitle className="text-red-500">Error</CardTitle>
          </CardHeader>
          <CardContent>
            <p>{error}</p>
          </CardContent>
          <CardFooter>
            <Button onClick={() => navigate("/login")}>Back to Login</Button>
          </CardFooter>
        </Card>
      </div>
    );
  }

  // Helper function to determine active button style
  const getButtonStyle = (tabName) => {
    return activeTab === tabName
      ? "w-full justify-start bg-red-50 text-red-500 font-medium"
      : "w-full justify-start";
  };

  return (
    <div className="min-h-screen bg-background">
      {/* Mobile Navigation */}
      <Sheet>
        <SheetTrigger asChild>
          <Button
            variant="outline"
            size="icon"
            className="md:hidden fixed top-4 left-4 z-50"
          >
            <Menu className="h-5 w-5" />
          </Button>
        </SheetTrigger>
        <SheetContent side="left" className="w-64">
          <div className="flex items-center mb-6">
            <User className="h-6 w-6 mr-2 text-primary" />
            <h2 className="text-xl font-bold">Brand Experts</h2>
          </div>
          <nav className="space-y-2">
            <Button
              variant="ghost"
              className={getButtonStyle("dashboard")}
              onClick={() => setActiveTab("dashboard")}
            >
              <Home className="mr-2 h-5 w-5" />
              Dashboard
            </Button>
            <Button
              variant="ghost"
              className={getButtonStyle("contacts")}
              onClick={() => setActiveTab("contacts")}
            >
              <Users className="mr-2 h-5 w-5" />
              Contacts
            </Button>
            <Button
              variant="ghost"
              className={getButtonStyle("client")}
              onClick={() => setActiveTab("client")}
            >
              <Handshake className="mr-2 h-5 w-5" />
              Client
            </Button>
            <Button
              variant="ghost"
              className={getButtonStyle("earnings")}
              onClick={() => setActiveTab("earnings")}
            >
              <Wallet className="mr-2 h-5 w-5" />
              Earnings
            </Button>
          </nav>
          {/* <div className="absolute bottom-4 w-full pr-6">
            <Button
              variant="destructive"
              className="w-full"
              onClick={handleLogout}
            >
              <LogOut className="mr-2 h-5 w-5" />
              Logout
            </Button>
          </div> */}
        </SheetContent>
      </Sheet>

      <div className="flex">
        {/* Desktop Sidebar */}
        <div className="hidden md:flex w-64 flex-col sticky inset-y-0 border-r bg-card">
          <div className="flex-1 py-6 px-4 space-y-2">
            <Button
              variant="ghost"
              className={getButtonStyle("dashboard")}
              onClick={() => setActiveTab("dashboard")}
            >
              <Home className="mr-2 h-5 w-5" />
              Dashboard
            </Button>
            <Button
              variant="ghost"
              className={getButtonStyle("contacts")}
              onClick={() => setActiveTab("contacts")}
            >
              <Users className="mr-2 h-5 w-5" />
              Contacts
            </Button>
            <Button
              variant="ghost"
              className={getButtonStyle("client")}
              onClick={() => setActiveTab("client")}
            >
              <Handshake className="mr-2 h-5 w-5" />
              Client
            </Button>
            <Button
              variant="ghost"
              className={getButtonStyle("earnings")}
              onClick={() => setActiveTab("earnings")}
            >
              <Wallet className="mr-2 h-5 w-5" />
              Earnings
            </Button>
          </div>
        </div>

        {/* Main Content */}
        <div className="flex-1 md:ml-2 p-4 md:p-8">
          {/* Header */}
          <div className="flex justify-between items-center mb-8">
            <div>
              <h1 className="text-2xl md:text-3xl font-bold">
                {activeTab.charAt(0).toUpperCase() + activeTab.slice(1)}
              </h1>
              <p className="text-muted-foreground">
                Good {getTimeOfDay()}, {userDetails?.first_name}
              </p>
            </div>
            <div className="flex items-center gap-4">
              <Button variant="outline" size="icon">
                <Bell className="h-5 w-5" />
              </Button>
            </div>
          </div>

          {/* Dashboard Section - Only show if activeTab is dashboard */}
          {activeTab === "dashboard" && (
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
              {/* Profile Card */}
              <Card>
                <CardHeader className="pb-2">
                  <CardTitle className="text-sm font-medium text-muted-foreground">
                    PROFILE
                  </CardTitle>
                </CardHeader>
                <CardContent className="flex items-center">
                  <div className="mr-4 flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
                    <User className="h-6 w-6 text-red-500" />
                  </div>
                  <div>
                    <p className="text-lg font-medium">
                      {userDetails?.first_name} {userDetails?.last_name}
                    </p>
                    <p className="text-sm text-muted-foreground">
                      {userDetails?.is_partner ? "Partner" : "Customer"}
                    </p>
                  </div>
                </CardContent>
              </Card>

              {/* Email Card */}
              <Card>
                <CardHeader className="pb-2">
                  <CardTitle className="text-sm font-medium text-muted-foreground">
                    EMAIL
                  </CardTitle>
                </CardHeader>
                <CardContent className="flex items-center">
                  <div className="mr-4 flex-shrink-0 flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
                    <Mail className="h-6 w-6 text-red-500" />
                  </div>
                  <div className="flex items-center min-w-0 flex-grow">
                    <div className="min-w-0 flex-grow">
                      <p className="text-lg font-medium truncate">
                        {userDetails?.email}
                      </p>
                      <p className="text-sm text-muted-foreground">
                        {userDetails?.verified_email
                          ? "Verified"
                          : "Not Verified"}
                      </p>
                    </div>
                    {userDetails?.verified_email && (
                      <Badge className="ml-2 flex-shrink-0" variant="outline">
                        Verified
                      </Badge>
                    )}
                  </div>
                </CardContent>
              </Card>

              {/* Mobile Card */}
              <Card>
                <CardHeader className="pb-2">
                  <CardTitle className="text-sm font-medium text-muted-foreground">
                    MOBILE
                  </CardTitle>
                </CardHeader>
                <CardContent className="flex items-center">
                  <div className="mr-4 flex-shrink-0 flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
                    <Phone className="h-6 w-6 text-red-500" />
                  </div>
                  <div className="flex items-center min-w-0 flex-grow">
                    <div className="min-w-0 flex-grow">
                      <p className="text-lg font-medium truncate">
                        {userDetails?.mobile}
                      </p>
                      <p className="text-sm text-muted-foreground">
                        {userDetails?.verified_mobile
                          ? "Verified"
                          : "Not Verified"}
                      </p>
                    </div>
                    {!userDetails?.verified_mobile && (
                      <Button
                        variant="link"
                        className="ml-2 flex-shrink-0 h-auto p-0 text-primary"
                      >
                        Verify
                      </Button>
                    )}
                  </div>
                </CardContent>
              </Card>
            </div>
          )}

          {/* Contacts Section - Only show if activeTab is contacts and user is a partner */}
          {activeTab === "contacts" && userDetails?.is_partner && (
            <Card className="mb-8">
              <CardHeader className="pb-3">
                <div className="flex items-center justify-between">
                  <CardTitle>Contacts</CardTitle>
                  <div className="flex items-center space-x-2">
                    <Dialog
                      open={isAddContactOpen}
                      onOpenChange={setIsAddContactOpen}
                    >
                      <DialogTrigger asChild>
                        <Button className="bg-red-500">
                          <Plus className="mr-2 h-4 w-4" />
                          Add Contact
                        </Button>
                      </DialogTrigger>
                      <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                          <Button
                            variant="outline"
                            className="flex items-center"
                          >
                            <Upload className="mr-2 h-4 w-4" />
                            Import Contacts
                          </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end">
                          <DropdownMenuItem
                            onSelect={(e) => e.preventDefault()} // Prevent menu close
                            onClick={() => fileInputRef.current.click()}
                          >
                            <FileSpreadsheet className="mr-2 h-4 w-4" />
                            Import XL
                          </DropdownMenuItem>
                          <DropdownMenuItem onClick={downloadFile}>
                            <Download className="mr-2 h-4 w-4" />
                            Download Sample
                          </DropdownMenuItem>
                        </DropdownMenuContent>
                      </DropdownMenu>

                      <input
                        type="file"
                        accept=".xls, .xlsx"
                        ref={fileInputRef}
                        onChange={handleFileUpload}
                        className="hidden"
                      />
                      <DialogContent>
                        <DialogHeader>
                          <DialogTitle>Add New Contact</DialogTitle>
                          <DialogDescription>
                            Fill in the details to add a new contact to your
                            list.
                          </DialogDescription>
                        </DialogHeader>
                        <form onSubmit={handleAddContact}>
                          <div className="grid gap-4 py-4">
                            <div className="grid gap-2">
                              <Label htmlFor="name">Name</Label>
                              <Input
                                id="name"
                                name="name"
                                value={newContact.name}
                                onChange={handleInputChange}
                                required
                              />
                            </div>
                            <div className="grid gap-2">
                              <Label htmlFor="email">Email</Label>
                              <Input
                                id="email"
                                name="email"
                                type="email"
                                value={newContact.email}
                                onChange={handleInputChange}
                                required
                              />
                            </div>
                            <div className="grid gap-2">
                              <Label htmlFor="number">WhatsApp Number</Label>
                              <PhoneInput
                                country={"ae"}
                                value={newContact.number}
                                enableSearch={true}
                                onChange={(phone) =>
                                  setNewContact({
                                    ...newContact,
                                    number: phone,
                                  })
                                }
                                inputProps={{
                                  name: "number",
                                  id: "number",
                                  required: true,
                                  className:
                                    "w-full pl-11 p-2 rounded-md border",
                                }}
                                containerClass="w-full"
                                buttonClass="border rounded-l-md"
                              />
                            </div>
                            <div className="grid gap-2">
                              <Label htmlFor="accounts">Company</Label>
                              <Input
                                id="accounts"
                                name="accounts"
                                value={newContact.accounts}
                                onChange={handleInputChange}
                                placeholder=""
                                required
                              />
                            </div>
                          </div>
                          <DialogFooter>
                            <Button
                              type="submit"
                              className="bg-red-500 hover:bg-red-400"
                            >
                              Add Contact
                            </Button>
                          </DialogFooter>
                        </form>
                      </DialogContent>
                    </Dialog>
                  </div>
                </div>
                <Separator className="my-4" />
                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-2">
                    <Input
                      placeholder="Search contacts..."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      className="w-[250px] md:w-[300px]"
                    />
                    <Button variant="outline" size="icon">
                      <Search className="h-4 w-4" />
                    </Button>
                  </div>
                  <div className="text-sm text-muted-foreground">
                    Showing {filteredContacts.length} of {totalContacts}{" "}
                    contacts
                  </div>
                </div>
              </CardHeader>
              <CardContent>
                {contactLoading ? (
                  <div className="space-y-2">
                    <Skeleton className="h-10 w-full" />
                    <Skeleton className="h-10 w-full" />
                    <Skeleton className="h-10 w-full" />
                  </div>
                ) : (
                  <div className="rounded-md border">
                    <Table>
                      <TableHeader>
                        <TableRow>
                          <TableHead>Name</TableHead>
                          <TableHead>Email</TableHead>
                          <TableHead>WhatsApp Number</TableHead>
                          <TableHead>Company</TableHead>
                          <TableHead className="w-[80px]">Actions</TableHead>
                        </TableRow>
                      </TableHeader>
                      <TableBody>
                        {filteredContacts.length === 0 ? (
                          <TableRow>
                            <TableCell
                              colSpan={5}
                              className="text-center py-6 text-muted-foreground"
                            >
                              {searchTerm
                                ? "No contacts found matching your search"
                                : "No contacts available"}
                            </TableCell>
                          </TableRow>
                        ) : (
                          filteredContacts.map((contact) => (
                            <TableRow key={contact.id}>
                              <TableCell className="font-medium">
                                {contact.name}
                              </TableCell>
                              <TableCell>{contact.email}</TableCell>
                              <TableCell>{contact.number}</TableCell>
                              <TableCell>{contact.accounts}</TableCell>
                              <TableCell>
                                <DropdownMenu>
                                  <DropdownMenuTrigger asChild>
                                    <Button variant="ghost" size="icon">
                                      <MoreHorizontal className="h-4 w-4" />
                                    </Button>
                                  </DropdownMenuTrigger>
                                  <DropdownMenuContent align="end">
                                    {/* <DropdownMenuItem>Edit</DropdownMenuItem> */}
                                    <DropdownMenuItem
                                      className="text-destructive"
                                      onClick={() =>
                                        handleDeleteContact(contact.id)
                                      }
                                    >
                                      <Trash2 className="mr-2 h-4 w-4" />
                                      Delete
                                    </DropdownMenuItem>
                                  </DropdownMenuContent>
                                </DropdownMenu>
                              </TableCell>
                            </TableRow>
                          ))
                        )}
                      </TableBody>
                    </Table>
                  </div>
                )}
              </CardContent>
            </Card>
          )}

          {/* Client Section - Placeholder */}
          {activeTab === "client" && (
            <Card className="mb-8">
              <CardHeader>
                <CardTitle>Client Management</CardTitle>
              </CardHeader>
              <CardContent>
                <p className="text-muted-foreground">
                  Client management features will be available soon.
                </p>
              </CardContent>
            </Card>
          )}

          {/* Earnings Section - Placeholder */}
          {activeTab === "earnings" && (
            <Card className="mb-8">
              <CardHeader>
                <CardTitle>Earnings Overview</CardTitle>
              </CardHeader>
              <CardContent>
                <p className="text-muted-foreground">
                  Earnings and financial data will be displayed here.
                </p>
              </CardContent>
            </Card>
          )}
        </div>
      </div>
      <Toaster />
    </div>
  );
}
