const ReturnPolicies = () => {
    return (
      <div className="container mx-auto px-4 my-6 text-gray-800">
        <h1 className="text-3xl font-bold mb-3 text-start">Return Policy</h1>
  
        <section className="mb-6">
          <h2 className="text-lg font-semibold mb-2">
            Contact Within 24-48 Hours
          </h2>
          <p>
            At BrandExperts, ALL orders are customized to each client and
            made-to-order. We DO NOT accept returns or exchanges. If there is an
            issue upon receiving your order, whether it be due to print,
            production, or shipping damage, you must notify BrandExperts within
            24-48 Hours. In order to determine the cause of the damage or error
            with your job, we require that sufficient evidence of the damage can
            be provided by photos. If the issue cannot be determined through
            photos alone, the product must be returned for inspection before a
            resolution can be met.
          </p>
          <p>
            If it is determined that the damage was caused by either a ‘Print’ or
            ‘Production’ error and has not met our standards, and contact of this
            issue was made within 24-48 Hours, we will gladly reprint the item(s).
            Please read our Guaranteed Quality page for more info.
          </p>
        </section>
  
        <section className="mb-6">
          <h2 className="text-lg font-semibold mb-2">
            Contact After 24-48 Hours
          </h2>
          <p>
            If you do not notify Printmoz within 24-48 Hours regarding any
            issue(s), one of two methods will be carried out:
          </p>
          <p className="my-4">
            Option A: Receive a 25% refund on damaged/incorrect item(s).
          </p>
          <p className="my-4">
            Option B: Reprint the damaged/incorrect items. A Pre-Paid shipping
            label will be provided for return.
          </p>
  
          <p>
            *Note: ALL Reprint items go through with &quot;Rush production&quot;
            and ship at the original method of shipping selected at the time of
            purchase. If you need the reprint sooner, expedited shipping options
            may be available for an additional fee. You must return the original
            print order within 15 business days after a solution has been met.
            Failure to return it in this time frame may result in charges to your
            credit card.
          </p>
        </section>
  
        <section className="mb-6">
          <h2 className="text-lg font-semibold mb-2">Shipping Damage</h2>
          <p>
            If the damage is determined to be a shipping issue, a Damaged Shipping
            Claim is filed immediately. A reprint order is then processed with
            Rush production and shipped at the original method of shipping. Please
            keep ALL packaging and merchandise until further advised by Printmoz.
            An inspection of the product by the shipping company may be issued at
            their convenience.
          </p>
        </section>
  
        <section>
          <h2 className="text-lg font-semibold mb-2">
            THE FOLLOWING ARE EXAMPLES OF ISSUES THAT DO NOT QUALIFY FOR A REPRINT
            OR REFUND:
          </h2>
          <ul className="list-disc ml-6">
            <li>
              Images that come out fuzzy, pixelated, or otherwise distorted due to
              low-quality files submitted for print.
            </li>
            <li>
              Images that experience a color shift due to the conversion into CMYK
              format.
            </li>
            <li>
              Errors within customer artwork such as insufficient bleed,
              misspelling, punctuation errors, or incorrect scaling.
            </li>
            <li>
              Orders that have been used or canceled after entering production.
            </li>
            <li>
              Orders containing dated materials that arrive after the relevant
              date.
            </li>
            <li>
              Orders refused at delivery, undeliverable, or delayed due to
              incorrect shipping information provided by the customer.
            </li>
          </ul>
        </section>
      </div>
    );
  };
  
  export default ReturnPolicies;