const TermsOfus = () => {
    return (
      <div className="container mx-auto my-4 px-4 terms_of_us">
        <h1>Terms of Use</h1>
  
        <p>
          If you continue to browse and use this website, you are agreeing to
          comply with and be bound by the Terms and Conditions of use. The term
          'BrandExperts' refers to the owner of the website. The term 'you' refers
          to the user or viewer of our website.
        </p>
  
        <p>
          BrandExperts reserves the right to update the Terms and Conditions at
          any time without notice. The most current version of the Terms and
          Conditions can be reviewed by clicking on the "Terms of Use" hypertext
          link located at the bottom of our Web pages. The services that
          BrandExperts provides and use of this website are subject to the
          following Terms and Conditions.
        </p>
  
        <p>
          The right to use BrandExperts is personal and is not transferable to any
          other person or entity. You are responsible for all use of your Account
          (under any screen name or password) and for ensuring that all use of
          your Account complies fully with the provisions of this Agreement.
        </p>
  
        <p>
          BrandExperts shall have the right at any time to change or discontinue
          any aspect or feature of BrandExperts.
        </p>
  
        <h2>Changed Terms</h2>
        <p>
          BrandExperts shall have the right at any time to change or modify the
          Terms and Conditions without prior notice. Your continued use of this
          site is and shall be deemed to be your agreement to be bound by any such
          modifications or updates.
        </p>
  
        <h2>User Conduct</h2>
        <p>
          BrandExperts contains copyrighted material, trademarks, and other
          proprietary information, including, but not limited to, text, software,
          photos, video, graphics, music and sound, and the entire contents of
          BrandExperts are copyrighted as a collective work under the U.S.
          copyright laws. BrandExperts owns a copyright in the selection,
          coordination, arrangement, and enhancement of such content, as well as
          in the content original to it. You may not modify, publish, transmit,
          participate in the transfer or sale, create derivative works, or in any
          way exploit, any of the content, in whole or in part. Except as
          otherwise expressly permitted under copyright law, no copying,
          redistribution, retransmission, publication or commercial exploitation
          of downloaded material will be permitted without the express permission
          of BrandExperts and the copyright owner. In the event of any permitted
          copying, redistribution or publication of copyrighted material, no
          changes in or deletion of author attribution, trademark legend or
          copyright notice shall be made.
        </p>
  
        <p>
          You may elect to upload or submit materials to BrandExperts for use in
          print products. You agree and understand that you will not upload, post
          or otherwise make available on BrandExperts any material protected by
          copyright, trademark or other proprietary rights without the express
          permission of the owner of the copyright, trademark or other proprietary
          rights.
        </p>
  
        <p>
          By uploading content you confirm that you either own or have permission
          to copy any materials and will be solely liable for any damage resulting
          from any infringement of copyrights, proprietary rights, or any other
          harm resulting from such a submission. By submitting material to any
          public area of BrandExperts, you automatically grant, or warrant that
          the owner of such material has expressly granted BrandExperts the
          royalty-free, perpetual, irrevocable, non-exclusive right and license to
          use, reproduce, modify, adapt, publish, translate and distribute such
          material (in whole or in part) worldwide and/or to incorporate it in
          developed for the full term of any copyright that may exist in such
          material. You hereby grant BrandExperts the right to edit, copy, publish
          and distribute any material made publicly available on BrandExperts by
          you.
        </p>
  
        <p>
          You agree that you will not upload or submit any material that: is
          indecent, unlawful, abusive, threatening, defamatory, and/or obscene;
          which contains trojans, viruses, malware or anything that would disrupt
          the production of BrandExperts; which invades another person's privacy.
        </p>
  
        <h2>Guidelines for Ordering</h2>
        <h3>Artwork</h3>
        <p>
          We take pride in our quick turnaround time and high-quality products.
          Following the guidelines below will help guarantee that your order will
          not be delayed in the pre-press process.
        </p>
  
        <p>
          We uphold a high moral standard in our printing and reserve the right to
          refuse production of any indecent artwork.
        </p>
  
        <p>
          * For larger files such as billboards and banners, artwork files should
          be built at 1'=1" (1/12 scale)
        </p>
  
        <h3>Basic Guidelines:</h3>
        <p>
          Our site has been created for designers, by designers. No complicated,
          fluffy order process. We want to make the ordering process as fast and
          painless as possible. We hope you will enjoy our online preview option.
          This option gives you the confidence to upload your design and proof it
          on screen. If you like what you see, then feel secure in the quality of
          the work you'll receive!
        </p>
  
        <h3>Resolution Requirements and Preparation Guidelines:</h3>
        <table>
          <thead>
            <tr>
              <th>Product Type</th>
              <th>Maximum DPI</th>
              <th>Preferred DPI</th>
              <th>Minimum DPI</th>
              <th>Notes/Example</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                Display Quality: Banners, Stage Backdrops, UV Printing, Adhesive
                Vinyl, Wallpaper, Duratrans
              </td>
              <td>
                Full Scale: 150
                <br />
                1/12 Scale: 1800
              </td>
              <td>
                Full Scale: 100
                <br />
                1/12 Scale: 1200
              </td>
              <td>
                Full Scale: 75
                <br />
                1/12 Scale: 900
              </td>
              <td>
                When creating a 4' x 8' banner print, make a Photoshop document 4"
                x 8" @ 1200 dpi. Create your graphic then save in PDF format with
                .ZIP compression for smallest file size.
              </td>
            </tr>
            <tr>
              <td>Fine Art: Canvas, Photo</td>
              <td>
                Full Scale: 200
                <br />
                1/12 Scale: 2400
              </td>
              <td>
                Full Scale: 150
                <br />
                1/12 Scale: 1800
              </td>
              <td>
                Full Scale: 100
                <br />
                1/12 Scale: 1200
              </td>
              <td>
                When creating a 3' x 5' Canvas print, make a Photoshop document 3"
                x 5" @ 1800 dpi. Create your graphic then save in PDF format with
                .ZIP compression for smallest file size.
              </td>
            </tr>
            <tr>
              <td>Billboard: Billboard, Building Wrap Mesh</td>
              <td>
                Full Scale: 50
                <br />
                1/12 Scale: 600
              </td>
              <td>
                Full Scale: 25
                <br />
                1/12 Scale: 300
              </td>
              <td>
                Full Scale: 12.5
                <br />
                1/12 Scale: 150
              </td>
              <td>
                When creating a 14' x 48' billboard print, make a Photoshop
                document 14" x 48" @ 300 dpi. Create your graphic then save in PDF
                format with .ZIP compression for smallest file size.
              </td>
            </tr>
            <tr>
              <td>Offset Printing, Business Cards, Postcards, Brochures</td>
              <td>Full Scale: 300</td>
              <td>Full Scale: 300</td>
              <td>Full Scale: 300</td>
              <td>
                When creating a 4' x 6' postcard, make a Photoshop document 4" x
                6"@ 300 dpi. Create your graphic then save in PDF format with .ZIP
                compression for smallest file size.
              </td>
            </tr>
          </tbody>
        </table>
  
        <h3>File Formats Accepted:</h3>
        <ul>
          <li>Photoshop (PSD, TIFF, EPS, PDF, JPEG, PNG, SVG)</li>
          <li>Illustrator (AI, EPS, PDF, with all fonts outlined)</li>
          <li>
            Any other graphics program capable of creating a PDF, TIFF, EPS, JPEG,
            PNG, SVG file.
          </li>
        </ul>
  
        <h3>Color</h3>
        <p>
          Color is extremely important to us as it is to you. If you have specific
          colors that have to be matched, they should be specified in "Additional
          Comments" while placing your order, hard copy or email. If not
          specified, color settings in your digital files will be reproduced and
          will be reflected in the proof and final production.
        </p>
        <p>
          Spot (PMS) colors can help in the design and printing process of your
          job, as spot (PMS) colors can be referenced to a Pantone "Swatch Book".
          BrandExperts will print as close to spot (PMS) color as possible (within
          5-10%). It is important to note however that all printing with
          BrandExperts is done in the Process color model using Cyan, Magenta,
          Yellow, and Black ink. Not all PMS colors can be matched 100% in
          four-color process.
        </p>
        <p>
          BrandExperts will match to the absolute closest process color equivalent
          to specified spot color. BrandExperts recommends obtaining a Pantone
          "Solid to Process" color guide (a great resource for all CMYK printing)
          that will show the Spot (PMS) and CMYK conversions side by side for you
          to compare the difference.
        </p>
  
        <h3>Customer Proofs</h3>
        <p>
          The most important thing to note about the proof is that when you
          approve a proof, it means that the job is going into production.
          Approval of the proof is your statement telling BrandExperts to "Print
          this job. I take complete responsibility that this is the exact design I
          want to be printed."
        </p>
        <p>
          Proofread all layouts, carefully checking position, type, numbers,
          halftones, and names. BrandExperts will output your color printing as
          accurately as possible from your digitally created files/layouts, but
          may not match density and color fully due to inherent limitations in the
          full-color printing process.
        </p>
        <p>
          You should use the online proof (soft proof) for object placement and
          spelling correctness, and to make certain that all elements of the
          design have been included. Please note that our online proofs are low
          resolution and not the actual high-resolution print file.
        </p>
        <p>
          BrandExperts has the ability to provide a hard copy proof at a nominal
          fee of your final large-format output. This should be used for color
          representation as well as to object placement, spelling correctness, and
          to make certain that all elements of the design have been included. If
          you have any questions or concerns with the proof, please DO NOT sign
          the proof approval, and instead contact your Account Manager immediately
          to discuss.
        </p>
        <p>
          In the unlikely event of a production error or defect, you must notify
          BrandExperts within 24 hours after you have received your product/s and
          we will re-run your project at no additional charge to you. BrandExperts
          reserves the right to determine the validity of claims of manufacturer
          errors or defects.
        </p>
  
        <h3>Proofing Options - Additional Changes</h3>
        <p>
          BrandExperts requires every client to review a proof for every order.
          Approval must be received within a two hour period BEFORE production is
          started. The two hours will begin once the client has ordered their
          product. The client may choose any of the following types of proofs
          available:
        </p>
  
        <h4>Online Proof:</h4>
        <p>
          BrandExperts generates an online proof as soon as you upload your
          artwork, during the order process. Online proofs give you a firsthand
          look at your file the way we see it, print ready. Online proofs are not
          color accurate as most monitors are not 100% color calibrated. There is
          no charge for an online proof. If for any reason your online proof is
          incorrect but you are certain that the art files are correct, please
          make a note in the additional comments field while placing your order.
          BrandExperts online proofing engine is extremely good but not perfect,
          luckily our trained staff of designers reviews all files for final
          output giving you our "worry-free guarantee."
        </p>
  
        <h4>Actual Material Proof:</h4>
        <p>
          BrandExperts can generate proofs on the exact ordered material ordered
          on the actual press that your job will be produced on. This is the most
          accurate proofing method, however, it is also the most expensive. These
          proofs take up to 48 hours to produce and cost $50 + shipping.
        </p>
  
        <h4>Critical Color Match/ Fine Art Policy:</h4>
        <p>
          There will be a $50 fee assessed for critical color matching on all fine
          art. This fee will provide you with up to 3 color proofs to ensure the
          highest quality color match possible. Please Note: Art is subjective.
          Different substrates will produce different results in color and/or
          appearance. Art reproduction may not duplicate original work exactly.
        </p>
        <p>
          By customer request, BrandExperts can make adjustments to supplied
          artwork. File manipulation fee is $50 minimum and $75 an hour.
        </p>
  
        <h3>Confidential Information</h3>
        <p>
          Please inform BrandExperts in writing if any portion of the project or
          materials or information provided is confidential. BrandExperts shall
          take reasonable precautions to safeguard original or other materials.
          BrandExperts is not liable for any damage to or loss of any material
          except in the case of willful neglect or gross negligence by
          BrandExperts. Please note that BrandExperts will not return any
          customer-supplied artwork or proofs unless specifically directed to do
          so in writing prior to processing the order.
        </p>
  
        <h2>Shipping and Turnaround Times</h2>
        <p>
          We offer several methods of production as well as rush production
          options and standard and expedited shipping methods. These methods are
          viewable at check out and vary depending on order contents and delivery
          location.
        </p>
  
        <p>Artwork must be uploaded and approved by 10:00 A.M. (PST)</p>
  
        <p>
          The turnaround time begins when the artwork is uploaded and complete.
          Turnaround times refer only to production. If your order completes after
          4:00 PM it may not ship or be available for pick-up until the following
          day. We strive to release all orders as soon as completion of production
          allows.
        </p>
  
        <h3>Turnaround</h3>
        <p>
          BrandExperts will generally ship all standard* jobs within 3 business
          days. Production begins once artwork is approved (final proof), and
          payment is received. If approval is given after 10:00 A.M. (PST)
          production will begin the following workday. Orders placed on Saturday
          or Sunday will be processed Monday morning.
        </p>
  
        <p>*Exceptions to standard Turnaround Times:</p>
        <p>
          Large orders (quantity or square footage) may require more time. An
          appropriate deadline will be discussed with the customer when the order
          is placed. It is understood production will not begin until all artwork,
          changes to the artwork, and approval of all artwork is received.
          Deadlines will be modified based on these criteria being met.
        </p>
  
        <p>Shipment</p>
        <p>
          Once production has shipped, the client assumes the risk of loss.
          Insurance may be provided by customer request and will be billed to the
          customer. Compliance with all current Post Office/Shipping laws and
          regulations must be strictly adhered to. For more information about our
          Shipping Policy, please visit our Shipping Policy Page.
        </p>
  
        <p>Rush Orders</p>
        <p>
          In the event an order is needed before the standard turnaround time,
          BrandExperts will work diligently to accommodate your needs. There will
          be a nominal rush fee plus expedited shipping charges added to the bill.
          At the time of check out, the rush options available for that particular
          order will be listed for you to choose from. An In-Hand date will be
          given for each rush option available so you can plan accordingly.
        </p>
  
        <p>Oversized/Overweight Packages</p>
        <p>
          Express delivery carriers will not ship packages over 150lbs or 108"
          long. In the event one of these criteria has been met, the option for
          UPS Freight will be available. UPS Freight is a flat fee. For more
          information about Freight Shipping, please see our Shipping Policy page.
        </p>
  
        <p>PROMOTIONS, DISCOUNTS, AND OFFERS</p>
        <p>
          BrandExperts holds special promotions and offers for discounts and free
          products from time to time. BrandExperts reserves the right to
          discontinue a special offer or promotion at any time. Most promotions
          are accompanied by a Coupon Code. Each Coupon Code will have different
          discounts and requirements. Please read all the promotion ads carefully.
          All requirements and terms will be stated clearly with each ad promoting
          a Coupon Code. Unless stated otherwise, BrandExperts FREE PROMOTIONS are
          limited to one per customer. Other discounts and offers may only be
          valid during a specified offer period or for one-time use only.
        </p>
        <p className="my-4">
          To participate in our promotions for discounts or other offers, enter
          the Coupon Code in the appropriate field at Check Out. Your adjusted
          price will be displayed before you confirm payment. If the Coupon Code
          does not work when entered, then either your order does not qualify for
          the promotion, or the Coupon Code you entered is invalid or expired.
        </p>
        <p className="my-4">
          Coupon Codes cannot be applied to orders already placed with
          BrandExperts and cannot be combined with our promotions or discounts.
          Once the coupon code has expired, it is no longer redeemable.
        </p>
        <div className="my-6">
          <p>PAYMENT</p>
          <p>
            The client is responsible for all product and shipping costs. Orders
            will not ship until the invoice is paid in full, no exceptions.
            BrandExperts does not ensure shipments unless requested by the client.
            If a client wishes to ensure shipment please inform BrandExperts at
            time of order. Final fees (shipping, etc) and expenses are shown at
            the time the order is placed; however, this will not include insurance
            fees. The extra insurance charges will be added to the client's
            invoice at the time of shipping. For questions regarding your invoice
            or order, feel free to call BrandExperts at (877) 287-0059. If there
            are any billing questions or problems please call us within ten (10)
            business days, otherwise, no changes will be made to past invoices.
          </p>
        </div>
        <div className="my-6">
          <p>PROPERTY OF BrandExperts</p>
          <p>
            BrandExperts contains copyrighted material, trademarks, and other
            proprietary information, including, but not limited to, text,
            software, photos, video, graphics, music and sound, and the entire
            contents of BrandExperts are copyrighted as a collective work under
            the U.S. copyright laws. BrandExperts owns a copyright in the
            selection, coordination, arrangement, and enhancement of such content,
            as well as in the content original to it. You may not modify, publish,
            transmit, participate in the transfer or sale, create derivative
            works, or in any way exploit, any of the content, in whole or in part.
            You may download copyrighted material for personal use only. Except as
            otherwise expressly permitted under copyright law, no copying,
            redistribution, retransmission, publication or commercial exploitation
            of downloaded material will be permitted without the express
            permission of BrandExperts and the copyright owner. In the event of
            any permitted copying, redistribution or publication of copyrighted
            material, no changes in or deletion of author attribution, trademark
            legend or copyright notice shall be made. You acknowledge that it does
            not acquire any ownership rights by downloading copyrighted material.
          </p>
        </div>
        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">Termination</h2>
          <p>
            In the event that BrandExperts suspects that you are in violation of
            these Terms and Conditions, we reserve the right to suspend or
            terminate your account and your right to the services provided by our
            website.
          </p>
        </section>
        <section>
          <h2 className="text-2xl font-semibold mb-2">Reprints/Refunds</h2>
          <ul className="list-disc pl-6 mb-4">
            <li>
              At BrandExperts, ALL orders are customized to each client and
              made-to-order. We DO NOT accept returns or exchanges.
            </li>
            <li>
              If there is an issue upon receiving your order, whether it be due to
              print, production, or shipping damage, you must notify BrandExperts
              within 5 days and prior to leaving any feedback, so that we can
              resolve the issue.
            </li>
            <li>
              If correspondence is not made back within 5 business days, we will
              assume that the issue has been resolved and will be under no
              obligation to provide a reprint or refund.
            </li>
            <li>
              In order to determine the cause of the damage or error with your
              job, we require that sufficient evidence of the damage can be
              provided by photos. If the issue cannot be determined through photos
              alone, the product must be returned for inspection before a
              resolution can be met.
            </li>
            <li>
              If it is determined that the damage was caused by either a ‘Print’
              or ‘Production’ error and has not met our standards, one of the two
              methods will be carried out:
              <ul className="list-disc pl-0 mt-2 " style={{ listStyle: "none" }}>
                <li>
                  <p>
                    Option A: Receive a 25% refund on damaged/incorrect item(s).
                  </p>
                </li>
                <li>
                  <p>
                    Option B: Reprint the damaged/incorrect items. A Pre-Paid
                    shipping label will be provided so that the original
                    merchandise can be returned. *Note: ALL Reprint items go
                    through with Rush production and ship at the original method
                    of shipping selected on the order at the time of purchase. If
                    you need the reprint item(s) sooner, expedited shipping
                    options may be available to you at an additional fee. You must
                    return the entire original print order within 15 business days
                    after a solution has been met. If you do not return it within
                    this time frame, you agree that the original order will be
                    charged against your credit card on file with BrandExperts.
                  </p>
                </li>
              </ul>
            </li>
          </ul>
        </section>
        <p className="my-6">
          If the damage is determined to be a shipping issue, a damage shipping
          claim is filed immediately. A reprint order will then be processed with
          Rush production and ship at the original method of shipping. Please keep
          ALL packaging and merchandise until further advised by BrandExperts. An
          inspection of the product by the shipping company may and can be issued
          at their convenience.
        </p>
        <p className="my-4">
          We reserve the right to have you return the original order, at our cost,
          before reprinting or refunding your order.
        </p>
        <p className="my-4">
          We understand that RUSH items are usually time and date sensitive, but
          these items are still subject to our Terms and Conditions. Production
          errors will be corrected and shipped at the same method of the original
          order. If you need the items sooner you will need to place a new order
          with expedited options. Once we receive the damaged items you will be
          refunded the cost of the original order. All items must be returned to
          BrandExperts before a refund will be issued.
        </p>
      </div>
    );
  };
  
  export default TermsOfus;
  