"use client"

/* eslint-disable no-unused-vars */
import { useState, useEffect, useRef } from "react"
import { Link, useNavigate } from "react-router-dom"
import toast from "react-hot-toast"
import { Search, User, ChevronDown, Menu, X, ChevronRight, BadgeCheck } from "lucide-react"
import { motion, AnimatePresence } from "framer-motion"
import logo from "../assets/images/br_logo.png"
import { useAuth } from "../context/AuthContext"
import CategoryDropdown from "./CategoryDropdown"
import { useQuery } from "@tanstack/react-query"
import { getParentCategories } from "../services/categoryApi"
import { useCart } from "../context/CartContext"
import { useDebounce } from "../hooks/useDebounce"
import API from "../pages/loginSignin/Api"
import ComingSoonModal from "./ComingSoonModal"
import CartCount from "./CartCount"
import useNavigateToEditor from "../hooks/useNavigateToEditor"

const Navbar = () => {
  const { user, logout, isPartner } = useAuth()
  const navigate = useNavigate()
  const [currentBanner, setCurrentBanner] = useState(0)
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
  const userDropdownRef = useRef(null)
  const [isUserDropdownOpen, setIsUserDropdownOpen] = useState(false)
  const dropdownTimeout = useRef(null)
  const [isScrolled, setIsScrolled] = useState(false)
  const { cartState, cartDispatch } = useCart()
  const cartItemsCount = cartState?.cartItems?.length || 0
  const [searchTerm, setSearchTerm] = useState("")
  const debouncedSearchTerm = useDebounce(searchTerm, 500)
  const [showSearchResults, setShowSearchResults] = useState(false)
  const [isComingSoonModalOpen, setIsComingSoonModalOpen] = useState(false)
  const [navbarHeight, setNavbarHeight] = useState(0)
  const navbarRef = useRef(null)
  const searchInputRef = useRef(null)
  const [bannerMessages, setBannerMessages] = useState([
    "Loading offers...", // Default message while loading
  ])

  const { navigateToEditor } = useNavigateToEditor()

  // Fetch banner messages from the API
  const { data: offerData, isLoading: offerLoading } = useQuery({
    queryKey: ["offers"],
    queryFn: async () => {
      try {
        const response = await API.get("dash/offers/")
        return response.data
      } catch (error) {
        console.error("Error fetching offers:", error)
        return []
      }
    },
    staleTime: 300000, // 5 minutes
  })

  // Update banner messages when offer data changes
  useEffect(() => {
    if (offerData && offerData.length > 0) {
      const messages = offerData.map((offer) => offer.offer_details)
      setBannerMessages(messages)
    }
  }, [offerData])

  // Updated search query using React Query directly
  const { data: searchResults, isLoading: searchLoading } = useQuery({
    queryKey: ["productSearch", debouncedSearchTerm],
    queryFn: async () => {
      const trimmedSearch = debouncedSearchTerm.trim()
      if (!trimmedSearch || trimmedSearch.length < 2) return []

      try {
        const response = await API.get(`dash/search/`, {
          params: { q: trimmedSearch },
        })

        console.log("Search API response:", response)
        return response.data.map((product) => ({
          id: product.id,
          name: product.name,
          image: product.image_url || "/placeholder.svg",
          price: product.fixed_price,
          description: product.description,
          size: product.size,
        }))
      } catch (error) {
        console.error("Search error:", error)
        return []
      }
    },
    enabled: debouncedSearchTerm?.trim().length >= 2,
    staleTime: 60000,
  })

  const requiredOptions = [
    { title: "Warranty", path: "/warranty" },
    { title: "Products", path: "/products" },
    { title: "Templates", path: "/templates" },
    { title: "Corporate Offers", path: "/corporate-offers" },
  ]

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentBanner((prev) => (prev + 1) % bannerMessages.length)
    }, 5000)
    return () => clearInterval(timer)
  }, [bannerMessages.length])

  useEffect(() => {
    const scrollContainer = document.querySelector(".hide-scrollbar")
    if (!scrollContainer) return

    let isDown = false
    let startX
    let scrollLeft

    const mouseDownHandler = (e) => {
      isDown = true
      scrollContainer.classList.add("active")
      startX = e.pageX - scrollContainer.offsetLeft
      scrollLeft = scrollContainer.scrollLeft
    }

    const mouseLeaveHandler = () => {
      isDown = false
      scrollContainer.classList.remove("active")
    }

    const mouseUpHandler = () => {
      isDown = false
      scrollContainer.classList.remove("active")
    }

    const mouseMoveHandler = (e) => {
      if (!isDown) return
      e.preventDefault()
      const x = e.pageX - scrollContainer.offsetLeft
      const walk = (x - startX) * 2
      scrollContainer.scrollLeft = scrollLeft - walk
    }

    scrollContainer.addEventListener("mousedown", mouseDownHandler)
    scrollContainer.addEventListener("mouseleave", mouseLeaveHandler)
    scrollContainer.addEventListener("mouseup", mouseUpHandler)
    scrollContainer.addEventListener("mousemove", mouseMoveHandler)

    return () => {
      scrollContainer?.removeEventListener("mousedown", mouseDownHandler)
      scrollContainer?.removeEventListener("mouseleave", mouseLeaveHandler)
      scrollContainer?.removeEventListener("mouseup", mouseUpHandler)
      scrollContainer?.removeEventListener("mousemove", mouseMoveHandler)
    }
  }, [])

  // Close search results when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchInputRef.current && !searchInputRef.current.contains(event.target)) {
        setShowSearchResults(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 80)
    }

    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  useEffect(() => {
    const updateNavbarHeight = () => {
      if (navbarRef.current) {
        setNavbarHeight(navbarRef.current.offsetHeight)
      }
    }

    updateNavbarHeight()
    window.addEventListener("resize", updateNavbarHeight)
    return () => window.removeEventListener("resize", updateNavbarHeight)
  }, [])

  const handleDropdownEnter = () => {
    if (dropdownTimeout.current) clearTimeout(dropdownTimeout.current)
    setIsUserDropdownOpen(true)
  }

  const handleDropdownLeave = () => {
    dropdownTimeout.current = setTimeout(() => {
      setIsUserDropdownOpen(false)
    }, 200)
  }

  const handleLogout = async () => {
    try {
      cartDispatch && cartDispatch({ type: "CLEAR_CART" })
      await logout()
      setIsUserDropdownOpen(false)
      toast.success("Successfully logged out!")
      navigate("/", { replace: true })
    } catch (error) {
      toast.error("Error during logout. Please try again.")
      console.error("Logout error:", error)
    }
  }

  const { data: parentCategories } = useQuery({
    queryKey: ["parentCategories"],
    queryFn: getParentCategories,
  })

  const categories = parentCategories || []

  const handleSearchChange = (e) => {
    const value = e.target.value
    setSearchTerm(value)
    if (value.length >= 2) {
      setShowSearchResults(true)
    } else {
      setShowSearchResults(false)
    }
  }

  const handleSearchItemClick = (productId) => {
    setShowSearchResults(false)
    setSearchTerm("")
    navigate(`/product/${productId}`)
  }

  // Mobile search handler
  const handleMobileSearch = (e) => {
    e.preventDefault()
    if (searchTerm.trim().length > 0) {
      navigate(`/products?search=${encodeURIComponent(searchTerm)}`)
      setIsMobileMenuOpen(false)
    }
  }

  return (
    <>
      <div style={{ height: isScrolled ? `${navbarHeight}px` : "0px" }} />

      <motion.div
        ref={navbarRef}
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        className={`w-full z-50 bg-white ${
          isScrolled ? "fixed top-0 left-0 right-0" : "relative"
        } transition-all duration-300`}
      >
        {/* Top Banner */}
        <div
          className={`bg-gradient-to-r from-red-600 to-pink-600 text-white py-2 transition-all duration-300 ${
            isScrolled ? "hidden" : "block"
          }`}
        >
          <div className="container mx-auto px-4 max-w-[1920px]">
            <div className="flex items-center justify-between">
              <button
                className="text-white p-2"
                onClick={() => setCurrentBanner((prev) => (prev - 1 + bannerMessages.length) % bannerMessages.length)}
              >
                <ChevronDown className="w-5 h-5 transform rotate-90" />
              </button>
              <p className="text-sm font-medium text-center flex-1">
                {offerLoading ? "Loading offers..." : bannerMessages[currentBanner]}
              </p>
              <button
                className="text-white p-2"
                onClick={() => setCurrentBanner((prev) => (prev + 1) % bannerMessages.length)}
              >
                <ChevronDown className="w-5 h-5 transform -rotate-90" />
              </button>
            </div>
          </div>
        </div>

        {/* Secondary Navigation */}
        <div className={`bg-gray-50  transition-all duration-300 ${isScrolled ? "hidden" : "block"}`}>
          <div className="container mx-auto px-8 max-w-[1920px]">
            <div className="flex justify-end space-x-6 py-2 text-sm">
              <div className="relative group">
                <Link to="/amc-coverage" className="text-red-600 hover:text-red-700 flex items-center">
                  <BadgeCheck className="w-5 h-5 mr-1" />
                  AMC Coverage
                </Link>
              </div>
              <Link to="/products" className="text-gray-600 font-medium hover:text-gray-900">
                Products
              </Link>

              <Link to="" className="text-gray-600 font-medium hover:text-gray-900">
                Templates
              </Link>
            </div>
          </div>
        </div>

        {/* Main Navigation Section */}
        <div className="bg-white shadow-md">
          <div className="container mx-auto px-8 max-w-[1920px]">
            {/* Main Navigation */}
            <div className="flex items-center justify-between py-4">
              {/* Logo Section */}
              <Link to="/" className="flex-shrink-0">
                <img src={logo || "/placeholder.svg"} alt="Logo" className="h-12 " />
              </Link>

              {/* Search Bar */}
              <div ref={searchInputRef} className="hidden lg:flex flex-1 max-w-xl px-12 relative">
                <div className="relative w-full">
                  <input
                    type="text"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    onFocus={() => searchTerm.length >= 2 && setShowSearchResults(true)}
                    placeholder="Search for products..."
                    className="w-full px-4 py-2 pl-10 border border-gray-300 rounded-full focus:ring-2 focus:ring-red-500 focus:border-transparent"
                  />
                  <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />

                  {/* Search Results Dropdown */}
                  {showSearchResults && searchTerm.length >= 2 && (
                    <div className="absolute top-full left-0 right-0 mt-2 bg-white rounded-lg shadow-lg border border-gray-100 max-h-96 overflow-y-auto z-50">
                      {searchLoading ? (
                        <div className="p-4 text-center text-gray-500">
                          <div className="flex justify-center items-center">
                            <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-red-600"></div>
                          </div>
                          <p className="mt-2">Searching...</p>
                        </div>
                      ) : searchResults && searchResults.length > 0 ? (
                        <div className="py-2">
                          {searchResults.map((product) => (
                            <div
                              key={product.id}
                              onClick={() => handleSearchItemClick(product.id)}
                              className="flex items-center px-4 py-2 hover:bg-gray-50 cursor-pointer"
                            >
                              <img
                                src={product.image || "/placeholder.svg"}
                                alt={product.name}
                                className="w-12 h-12 object-cover rounded"
                                onError={(e) => {
                                  e.target.src = "/placeholder.svg"
                                }}
                              />
                              <div className="ml-3">
                                <div className="text-sm font-medium text-gray-900">{product.name}</div>
                                <div className="text-sm text-gray-500">{product.price} AED</div>
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div className="p-4 text-center text-gray-500">No products found</div>
                      )}
                    </div>
                  )}
                </div>
              </div>

              {/* Right Controls */}
              <div className="flex items-center space-x-6">
                <button
                  onClick={navigateToEditor}
                  className="hidden md:block px-4 py-2 text-red-600 border border-red-300 rounded-lg hover:bg-red-50 cursor-pointer"
                >
                  Design tool
                </button>

                {/* Cart with badge */}
                <CartCount />

                {/* User Menu - Fixed Version */}
                <div
                  className="relative hidden md:block"
                  ref={userDropdownRef}
                  onMouseEnter={handleDropdownEnter}
                  onMouseLeave={handleDropdownLeave}
                >
                  {user ? (
                    // For authenticated users
                    <button className="flex items-center space-x-2 cursor-pointer p-2 hover:text-red-600">
                      <User className="w-6 h-6" />
                      <span className="text-sm font-medium">{user.name}</span>
                      <ChevronDown className="w-4 h-4" />
                    </button>
                  ) : (
                    // For non-authenticated users
                    <Link to="/login" className="flex items-center space-x-2 cursor-pointer p-2 hover:text-red-600">
                      <User className="w-6 h-6" />
                      <span className="text-sm font-medium">Login</span>
                    </Link>
                  )}

                  {user && isUserDropdownOpen && (
                    <div className="absolute right-0 top-[calc(100%-8px)] w-48 pt-4" style={{ zIndex: 1000 }}>
                      <div className="bg-white rounded-lg shadow-lg py-1 border border-gray-100">
                        <div className="px-4 py-2 border-b border-gray-100">
                          <p className="text-sm font-medium text-gray-900">{user.name}</p>
                          <p className="text-xs text-gray-500">{user.email}</p>
                        </div>

                        <Link
                          to="/orders"
                          className="block px-4 py-2.5 text-sm text-gray-700 hover:bg-gray-50 hover:text-red-600"
                        >
                          My Orders
                        </Link>

                        <Link
                          to="/saved-rfqs"
                          className="block px-4 py-2.5 text-sm text-gray-700 hover:bg-gray-50 hover:text-red-600"
                        >
                          Estimates
                        </Link>

                        <Link
                          to="/user-dashboard"
                          className="block px-4 py-2.5 text-sm text-gray-700 hover:bg-gray-50 hover:text-red-600"
                        >
                          User Dashboard
                        </Link>

                        {isPartner && (
                          <Link
                            to="/partner-dashboard"
                            className="block px-4 py-2.5 text-sm text-gray-700 hover:bg-gray-50 hover:text-red-600"
                          >
                            Partner Dashboard
                          </Link>
                        )}

                        <button
                          onClick={handleLogout}
                          className="block w-full text-left px-4 py-2.5 text-sm text-red-600 hover:bg-gray-50"
                        >
                          Logout
                        </button>
                      </div>
                    </div>
                  )}
                </div>

                {/* Mobile Menu Button */}
                <button className="md:hidden" onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>
                  {isMobileMenuOpen ? (
                    <X className="w-6 h-6 text-gray-700" />
                  ) : (
                    <Menu className="w-6 h-6 text-gray-700" />
                  )}
                </button>
              </div>
            </div>

            {/* Category Navigation */}
            <div className="hidden md:block relative">
              <div className="hide-scrollbar ">
                <div className="flex items-center space-x-8 py-4 min-w-max">
                  {/* All Products Dropdown */}
                  <div className="relative group shrink-0 md:hidden lg:block">
                    <button className="flex items-center space-x-2 font-semibold text-gray-900 text-sm hover:text-red-600 whitespace-nowrap">
                      All products
                      <ChevronDown className="ml-1 w-4 h-4 transform transition-transform group-hover:rotate-180" />
                    </button>
                    {/* All Products Dropdown Menu */}
                    <div className="hidden group-hover:block absolute top-full left-0 pt-2 z-50">
                      <div className="w-64 bg-white shadow-lg rounded-lg py-2 relative">
                        {categories.map((category) => (
                          <div key={category.id} className="group/item">
                            <Link
                              to=""
                              className="flex items-center justify-between px-4 py-2 text-gray-800 hover:text-red-600 hover:bg-gray-50 w-full"
                            >
                              {category.name}
                              <ChevronRight className="w-4 h-4" />
                            </Link>
                            <div className="invisible group-hover/item:visible absolute left-full right-0  top-0 w-[1000px]">
                              {/* <div className="invisible group-hover/item:visible absolute left-full top-0"> */}
                              <CategoryDropdown
                                category={category}
                                parentCategoryImage={category.imageUrl} // Pass parent category image
                                onProductClick={() => {
                                  setIsMobileMenuOpen(false)
                                  document.activeElement.blur()
                                }}
                                onClose={() => {
                                  document.activeElement.blur()
                                }}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  {/* Individual Category Dropdowns */}
                  <div className="flex justify-between w-full relative">
                    {categories.map((category, index) => (
                      <div
                        key={category.id}
                        className=" group shrink-0"
                        // className="relative group shrink-0"
                      >
                        <Link
                          to=""
                          className="text-gray-800  hover:text-red-600 text-sm font-semibold flex items-center gap-1 py-2 whitespace-nowrap"
                        >
                          {category.name}
                        </Link>
                        {/* Add padding bridge to prevent gap */}
                        <div className="absolute h-4 -bottom-4 left-0  right-0"></div>

                        {/* Dropdown wrapper with position detection */}
                        <div className="hidden group-hover:block">
                          {/* Check if this is one of the last items in the navbar */}
                          {index >= categories.length - 3 ? (
                            <CategoryDropdown
                              category={category}
                              position="left"
                              parentCategoryImage={category.imageUrl} // Pass parent category image
                              onProductClick={() => {
                                setIsMobileMenuOpen(false)
                                document.activeElement.blur()
                              }}
                              onClose={() => {
                                document.activeElement.blur()
                              }}
                            />
                          ) : (
                            <CategoryDropdown
                              category={category}
                              position="top"
                              parentCategoryImage={category.imageUrl} // Pass parent category image
                              onProductClick={() => {
                                setIsMobileMenuOpen(false)
                                document.activeElement.blur()
                              }}
                              onClose={() => {
                                document.activeElement.blur()
                              }}
                            />
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Mobile Menu */}
        <AnimatePresence>
          {isMobileMenuOpen && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: "auto" }}
              exit={{ opacity: 0, height: 0 }}
              className="md:hidden bg-white border-t border-gray-100"
            >
              <div className="container mx-auto px-4 py-2 max-w-[1920px]">
                <form onSubmit={handleMobileSearch} className="mb-4">
                  <div className="relative">
                    <input
                      type="text"
                      value={searchTerm}
                      onChange={handleSearchChange}
                      placeholder="Search products..."
                      className="w-full px-4 py-2 pl-10 border border-gray-300 rounded-lg"
                    />
                    <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
                    <button
                      type="submit"
                      className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-red-600 text-white p-1 rounded-full"
                    >
                      <ChevronRight className="w-4 h-4" />
                    </button>
                  </div>
                </form>

                <div className="border-t border-gray-100 my-4"></div>
                <div className="block py-2 text-gray-700 font-semibold">All products</div>
                {categories.map((category) => (
                  <Link
                    key={category.id}
                    to={`/products?category=${category.id}`}
                    className="block py-2 pl-4 text-gray-700 text-sm hover:text-red-600 z-10"
                    onClick={() => setIsMobileMenuOpen(false)}
                  >
                    {category.name}
                  </Link>
                ))}
                <div className="border-t border-gray-100 my-4"></div>
                {user ? (
                  <>
                    <Link
                      to="/orders"
                      className="block py-2 text-sm text-gray-700 hover:text-red-600"
                      onClick={() => setIsMobileMenuOpen(false)}
                    >
                      My Orders
                    </Link>
                    <Link
                      to="/saved-rfqs"
                      className="block py-2 text-sm text-gray-700 hover:text-red-600"
                      onClick={() => setIsMobileMenuOpen(false)}
                    >
                      Estimates
                    </Link>

                    <Link
                      to="/user-dashboard"
                      className="block  py-2 text-sm text-gray-700 hover:bg-gray-50 hover:text-red-600"
                    >
                      User Dashboard
                    </Link>

                    {isPartner && (
                      <Link
                        to="/partner-dashboard"
                        className="block py-2 text-sm text-gray-700 hover:bg-gray-50 hover:text-red-600"
                      >
                        Partner Dashboard
                      </Link>
                    )}

                    <button
                      onClick={() => {
                        handleLogout()
                        setIsMobileMenuOpen(false)
                      }}
                      className="block w-full text-left py-2 text-red-600"
                    >
                      Logout
                    </button>
                  </>
                ) : (
                  <>
                    <Link
                      to="/login"
                      className="block py-2 text-gray-700 hover:text-red-600"
                      onClick={() => setIsMobileMenuOpen(false)}
                    >
                      Log In
                    </Link>
                    {/* <Link
                      to="/login"
                      className="block py-2 text-gray-700 hover:text-red-600"
                      onClick={() => setIsMobileMenuOpen(false)}
                    >
                      Register
                    </Link> */}
                  </>
                )}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
      <ComingSoonModal isOpen={isComingSoonModalOpen} onClose={() => setIsComingSoonModalOpen(false)} />
    </>
  )
}

export default Navbar

