"use client"
import { useEffect } from "react"
import { useQuery } from "@tanstack/react-query"
import { useParams, Link } from "react-router-dom"
import { Package, Clock, AlertCircle, Truck, ChevronLeft, MapPin, CreditCard, Calendar, User } from "lucide-react"
import API from "../loginSignin/Api"
import { Button } from "@/components/ui/button"
import { Card, CardContent } from "@/components/ui/card"
import { Separator } from "@/components/ui/separator"
import { Badge } from "@/components/ui/badge"
import { Skeleton } from "@/components/ui/skeleton"

const statusConfig = {
  ordered: {
    bg: "bg-amber-100",
    text: "text-amber-800",
    border: "border-amber-200",
    icon: Clock,
    label: "Order Placed",
  },
  delivered: {
    bg: "bg-green-100",
    text: "text-green-800",
    border: "border-green-200",
    icon: Package,
    label: "Delivered",
  },
  cancelled: {
    bg: "bg-red-100",
    text: "text-red-800",
    border: "border-red-200",
    icon: AlertCircle,
    label: "Cancelled",
  },
  shipped: {
    bg: "bg-blue-100",
    text: "text-blue-800",
    border: "border-blue-200",
    icon: Truck,
    label: "Shipped",
  },
}

const formatDate = (dateString) => {
  if (!dateString) return "Not available"
  return new Date(dateString).toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  })
}

const formatCurrency = (amount) => {
  return Number.parseFloat(amount).toFixed(2) + " AED"
}

const SectionHeading = ({ children }) => <h2 className="text-lg font-semibold mb-4 flex items-center">{children}</h2>

export default function OrderDetails() {
  const { orderId } = useParams()

  const fetchOrderDetails = async () => {
    const response = await API.get(`/orders_detail/${orderId}/`)
    return response.data
  }

  const {
    data: orderDetails,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["orderDetail", orderId],
    queryFn: fetchOrderDetails,
    enabled: !!orderId,
  })

  // Set the document title when order details are loaded
  useEffect(() => {
    if (orderDetails) {
      document.title = `Order #${orderId} | Brand Experts`
    }
  }, [orderDetails, orderId])

  if (isLoading)
    return (
      <div className="max-w-4xl mx-auto px-4 py-8">
        <div className="flex items-center mb-6">
          <Skeleton className="h-6 w-32 mr-2" />
          <Skeleton className="h-6 w-24 mr-2" />
          <Skeleton className="h-6 w-24" />
        </div>

        <Card className="mb-8">
          <div className="h-2 bg-gray-100" />
          <CardContent className="p-6">
            <div className="flex justify-between items-center">
              <div className="flex items-center">
                <Skeleton className="h-12 w-12 rounded-full mr-4" />
                <div>
                  <Skeleton className="h-6 w-32 mb-2" />
                  <Skeleton className="h-4 w-48" />
                </div>
              </div>
              <Skeleton className="h-8 w-24 rounded-full" />
            </div>
          </CardContent>
        </Card>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          <div className="lg:col-span-2">
            <Card className="mb-8">
              <CardContent className="p-6">
                <Skeleton className="h-6 w-32 mb-6" />
                <div className="space-y-6">
                  {[1, 2].map((i) => (
                    <div key={i} className="pt-6 first:pt-0">
                      <div className="flex">
                        <Skeleton className="w-24 h-24 rounded mr-6" />
                        <div className="flex-1">
                          <Skeleton className="h-5 w-full mb-2" />
                          <Skeleton className="h-4 w-1/2 mb-4" />
                          <div className="grid grid-cols-2 gap-2">
                            <Skeleton className="h-4 w-full" />
                            <Skeleton className="h-4 w-full" />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </CardContent>
            </Card>

            <Card>
              <CardContent className="p-6">
                <Skeleton className="h-6 w-40 mb-6" />
                <Skeleton className="h-32 w-full rounded" />
              </CardContent>
            </Card>
          </div>

          <div className="lg:col-span-1">
            <Card className="sticky top-4">
              <CardContent className="p-6">
                <Skeleton className="h-6 w-32 mb-6" />
                <div className="space-y-4">
                  {[1, 2, 3, 4].map((i) => (
                    <div key={i} className="flex justify-between">
                      <Skeleton className="h-4 w-24" />
                      <Skeleton className="h-4 w-20" />
                    </div>
                  ))}
                </div>

                <Separator className="my-4" />

                <div className="flex justify-between">
                  <Skeleton className="h-5 w-24" />
                  <Skeleton className="h-5 w-24" />
                </div>

                <div className="mt-8">
                  <Skeleton className="h-5 w-32 mb-4" />
                  <Skeleton className="h-24 w-full rounded" />
                </div>

                <div className="mt-8">
                  <Skeleton className="h-5 w-24 mb-4" />
                  <Skeleton className="h-16 w-full rounded" />
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    )

  if (error)
    return (
      <div className="max-w-4xl mx-auto px-4 py-12 text-center">
        <AlertCircle className="h-12 w-12 text-red-600 mx-auto mb-4" />
        <h2 className="text-xl font-semibold mb-2">Error Loading Order</h2>
        <p className="text-muted-foreground mb-6">{error.message}</p>
        <Button asChild>
          <Link to="/dashboard" className="inline-flex items-center">
            <ChevronLeft className="w-4 h-4 mr-2" />
            Return to Dashboard
          </Link>
        </Button>
      </div>
    )

  if (!orderDetails)
    return (
      <div className="max-w-4xl mx-auto px-4 py-12 text-center">
        <AlertCircle className="h-12 w-12 text-muted-foreground mx-auto mb-4" />
        <h2 className="text-xl font-semibold mb-2">Order Not Found</h2>
        <p className="text-muted-foreground mb-6">We couldn't find the order you're looking for.</p>
        <Button asChild>
          <Link to="/dashboard" className="inline-flex items-center">
            <ChevronLeft className="w-4 h-4 mr-2" />
            Return to Dashboard
          </Link>
        </Button>
      </div>
    )

  const status = orderDetails.status || "ordered"
  const StatusIcon = statusConfig[status]?.icon || AlertCircle
  const { bg, text, border } = statusConfig[status] || statusConfig.ordered

  const subtotal =
    Number.parseFloat(orderDetails.total_amount) -
    Number.parseFloat(orderDetails.vat) -
    Number.parseFloat(orderDetails.site_visit_charge || 0)

  return (
    <div className="max-w-6xl mx-auto px-4 py-8">
      {/* Breadcrumb */}
      <nav className="mb-6">
        <ol className="flex items-center space-x-2 text-sm">
          <li>
            <Link to="/dashboard" className="text-muted-foreground hover:text-red-600">
              Dashboard
            </Link>
          </li>
          <li className="text-muted-foreground">/</li>
          <li>
            <Link
              to="/dashboard"
              onClick={() => sessionStorage.setItem("activeTab", "My-Orders")}
              className="text-muted-foreground hover:text-red-600"
            >
              Orders
            </Link>
          </li>
          <li className="text-muted-foreground">/</li>
          <li className="font-medium">Order #{orderId}</li>
        </ol>
      </nav>

      {/* Order Header */}
      <Card className="mb-8">
        <div className={`h-2 ${bg}`} />
        <CardContent className="p-6">
          <div className="flex flex-col md:flex-row md:justify-between md:items-center gap-4">
            <div className="flex items-center">
              <span className={`inline-flex items-center justify-center w-12 h-12 rounded-full ${bg} mr-4`}>
                <StatusIcon className={`w-6 h-6 ${text}`} />
              </span>
              <div>
                <h1 className="text-2xl font-bold">Order #{orderDetails.id}</h1>
                <div className="flex items-center mt-1 text-muted-foreground">
                  <Calendar className="w-4 h-4 mr-1" />
                  <span>Placed on {formatDate(orderDetails.ordered_date)}</span>
                </div>
              </div>
            </div>
            <Badge variant="outline" className={`px-4 py-2 text-sm font-semibold ${bg} ${text} ${border}`}>
              {statusConfig[status]?.label || status.charAt(0).toUpperCase() + status.slice(1)}
            </Badge>
          </div>
        </CardContent>
      </Card>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
        <div className="lg:col-span-2">
          {/* Order Items */}
          <Card className="mb-8">
            <CardContent className="p-6">
              <SectionHeading>
                <Package className="w-5 h-5 mr-2 text-muted-foreground" />
                Order Items
              </SectionHeading>

              <div className="space-y-6 divide-y">
                {orderDetails.cart.items.map((item) => (
                  <div key={item.id} className="pt-6 first:pt-0">
                    <div className="flex flex-col sm:flex-row">
                      <div className="flex-shrink-0 w-full sm:w-24 h-24 bg-muted rounded overflow-hidden mb-4 sm:mb-0">
                        <img
                          src={
                            item.design_image !== "https://default-image-url.com"
                              ? item.design_image
                              : "/placeholder.svg?height=100&width=100"
                          }
                          alt={item.product_name}
                          className="w-full h-full object-cover"
                        />
                      </div>
                      <div className="sm:ml-6 flex-1">
                        <div className="flex flex-col sm:flex-row sm:justify-between">
                          <h3 className="text-base font-semibold mb-1">{item.product_name}</h3>
                          <p className="text-base font-semibold mb-2 sm:mb-0">{formatCurrency(item.total_price)}</p>
                        </div>
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-1 text-sm text-muted-foreground mt-2">
                          <p>
                            Dimensions: {item.custom_width} × {item.custom_height} {item.size_unit}
                          </p>
                          <p>Quantity: {item.quantity}</p>
                          {item.hire_designer && (
                            <p className={`col-span-2 mt-1 ${text} font-medium`}>
                              Designer service included (Level: {item.hire_designer})
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </CardContent>
          </Card>

          {/* Shipping Address */}
          {orderDetails.address && (
            <Card className="mb-8 lg:mb-0">
              <CardContent className="p-6">
                <SectionHeading>
                  <MapPin className="w-5 h-5 mr-2 text-muted-foreground" />
                  Shipping Address
                </SectionHeading>

                <div className="bg-muted/50 rounded-lg p-4 border">
                  <div className="flex items-start">
                    <div className="w-full">
                      <p className="font-medium mb-2">{orderDetails.customer_name}</p>
                      {orderDetails.address.company_name && <p>{orderDetails.address.company_name}</p>}
                      <p>{orderDetails.address.address_line1}</p>
                      {orderDetails.address.address_line2 && <p>{orderDetails.address.address_line2}</p>}
                      <p>
                        {orderDetails.address.city}, {orderDetails.address.state} {orderDetails.address.zip_code}
                      </p>
                      <p>{orderDetails.address.country}</p>
                      {orderDetails.address.ext && <p className="mt-2">Ext: {orderDetails.address.ext}</p>}
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          )}
        </div>

        <div className="lg:col-span-1">
          {/* Order Summary */}
          <Card className="mb-8 sticky top-4">
            <CardContent className="p-6">
              <SectionHeading>
                <CreditCard className="w-5 h-5 mr-2 text-muted-foreground" />
                Order Summary
              </SectionHeading>

              <div className="space-y-4">
                <div className="flex justify-between text-muted-foreground">
                  <p>Subtotal</p>
                  <p className="font-medium">{formatCurrency(subtotal)}</p>
                </div>

                {Number.parseFloat(orderDetails.site_visit_charge) > 0 && (
                  <div className="flex justify-between text-muted-foreground">
                    <p>Site Visit Charge</p>
                    <p className="font-medium">{formatCurrency(orderDetails.site_visit_charge)}</p>
                  </div>
                )}

                <div className="flex justify-between text-muted-foreground">
                  <p>VAT</p>
                  <p className="font-medium">{formatCurrency(orderDetails.vat)}</p>
                </div>

                <Separator />

                <div className="flex justify-between">
                  <p className="text-lg font-semibold">Total</p>
                  <p className="text-lg font-semibold text-green-500">{formatCurrency(orderDetails.total_amount)}</p>
                </div>
              </div>

              {/* Payment Information */}
              <div className="mt-8">
                <h3 className="text-sm font-semibold uppercase mb-4">Payment Details</h3>
                <div className="bg-muted/50 rounded-lg p-4 border">
                  <div className="flex items-center">
                    <CreditCard className="w-5 h-5 text-muted-foreground mr-3" />
                    <div>
                      <p className="font-medium capitalize">{orderDetails.payment.method}</p>
                      <p className="text-sm text-muted-foreground mt-1">
                        Transaction ID: <span className="font-mono">{orderDetails.payment.transaction_id}</span>
                      </p>
                      <p className="text-sm text-muted-foreground mt-1">
                        Status:{" "}
                        <Badge variant="outline" className={`${bg} ${text}`}>
                          {orderDetails.payment.status.charAt(0).toUpperCase() + orderDetails.payment.status.slice(1)}
                        </Badge>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* Customer Info */}
              <div className="mt-8">
                <h3 className="text-sm font-semibold uppercase mb-4">Customer</h3>
                <div className="bg-muted/50 rounded-lg p-4 border">
                  <div className="flex items-center">
                    <User className="w-5 h-5 text-muted-foreground mr-3" />
                    <div>
                      <p className="font-medium">{orderDetails.customer_name}</p>
                      {/* Add customer email or phone if available */}
                    </div>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>

      {/* Action Buttons */}
      <div className="flex justify-between mt-8">
        <Button variant="outline" asChild>
          <Link to="/orders" onClick={() => sessionStorage.setItem("activeTab", "My-Orders")}>
            <ChevronLeft className="w-4 h-4 mr-2" />
            Back to Orders
          </Link>
        </Button>

        {status !== "cancelled" && <Button variant="default">Download Invoice</Button>}
      </div>
    </div>
  )
}

