import { Outlet } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import Navbar from "./Navbar";
import Footer from "./Footer";

const Layout = () => {
  return (
    <>
      <Toaster
        position="top-right"
        toastOptions={{
          duration: 2000,
          style: {
            background: "#333",
            color: "#fff",
          },
          success: {
            iconTheme: {
              primary: "#4ade80",
              secondary: "#fff",
            },
          },
        }}
      />
      <Navbar />
      <div className="min-h-screen flex flex-col">
        <main className="flex-grow w-full max-w-[1920px] mx-auto my-auto">
          <Outlet />
        </main>
        <Footer />
      </div>
    </>
  );
};

export default Layout;
