"use client"
import  React from "react"
import { useState, useEffect } from "react"
import { toast } from "react-hot-toast"
import { useModal } from "../context/ModalContext"
import { useAuth } from "../context/AuthContext"
import { useNavigate } from "react-router-dom"
import Api from "../pages/loginSignin/Api"
import { Loader2, ArrowLeft, CheckCircle2, Eye, EyeOff } from "lucide-react"
import { UnifiedAuthInput } from "./PhoneInput.tsx"

// Step tracking enum
enum AuthStep {
  IDENTIFIER = "identifier",
  PASSWORD = "password",
  OTP_VERIFICATION = "otp_verification",
  MOBILE_VERIFICATION = "mobile_verification",
  MOBILE_OTP_VERIFICATION = "mobile_otp_verification",
  EMAIL_VERIFICATION = "email_verification",
  EMAIL_OTP_VERIFICATION = "email_otp_verification",
  FINAL_REGISTRATION = "final_registration",
  COMPLETE = "complete",
  FORGOT_PASSWORD_OTP = "forgot_password_otp",
  FORGOT_PASSWORD_RESET = "forgot_password_reset",
}

export default function ModalAuthFlow() {
  const { closeModal } = useModal()
  const { login } = useAuth()
  const navigate = useNavigate()

  // State management
  const [currentStep, setCurrentStep] = useState<AuthStep>(AuthStep.IDENTIFIER)
  const [identifier, setIdentifier] = useState("")
  const [isEmail, setIsEmail] = useState(false)
  const [countryCode, setCountryCode] = useState("+91")
  const [otp, setOtp] = useState("")
  const [newOtp, setNewOtp] = useState("")
  const [mobileOtp, setMobileOtp] = useState("")
  const [emailOtp, setEmailOtp] = useState("")
  const [password, setPassword] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [mobileNumber, setMobileNumber] = useState("")
  const [emailAddress, setEmailAddress] = useState("")
  const [showPassword, setShowPassword] = useState(false)
  const [rememberMe, setRememberMe] = useState(false)
  const [userExists, setUserExists] = useState(false)
  const [verificationData, setVerificationData] = useState({
    getMobile: false,
    getEmail: false,
    verifiedType: "",
    getPassword: false,
  })
  const [userDetails, setUserDetails] = useState({
    firstName: "",
    lastName: "",
    password: "",
    confirmPassword: "",
  })
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false)
  const [passwordError, setPasswordError] = useState("")
  const [confirmPasswordError, setConfirmPasswordError] = useState("")
  const [isFocused, setIsFocused] = useState({
    identifier: false,
    password: false,
    otp: false,
    mobileOtp: false,
    emailOtp: false,
  })

  // Add a new state for controlling the forgot password modal visibility
  const [forgotPasswordOtp, setForgotPasswordOtp] = useState("")
  const [forgotPasswordSessionToken, setForgotPasswordSessionToken] = useState("")
  const [forgotPasswordResetToken, setForgotPasswordResetToken] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [confirmNewPassword, setConfirmNewPassword] = useState("")
  const [newPasswordVisible, setNewPasswordVisible] = useState(false)
  const [confirmNewPasswordVisible, setConfirmNewPasswordVisible] = useState(false)
  const [newPasswordError, setNewPasswordError] = useState("")
  const [confirmNewPasswordError, setConfirmNewPasswordError] = useState("")
  const [selectedTab, setSelectedTab] = useState<"phone" | "email">("phone");
  

  // Load any saved state from session storage on component mount
  useEffect(() => {
    const savedIdentifier = sessionStorage.getItem("modal_identifier")
    const savedCountryCode = sessionStorage.getItem("modal_country_code")
    const savedOtp = sessionStorage.getItem("modal_otp")
    const savedNewOtp = sessionStorage.getItem("modal_new_otp")
    const savedStep = sessionStorage.getItem("modal_auth_step")
    const savedIsEmail = sessionStorage.getItem("modal_is_email") === "true"
    const savedUserExists = sessionStorage.getItem("modal_user_exists") === "true"

    if (savedIdentifier) {
      setIdentifier(savedIdentifier)
      setIsEmail(savedIsEmail)
    }
    if (savedCountryCode) setCountryCode(savedCountryCode)
    if (savedOtp) setOtp(savedOtp)
    if (savedNewOtp) setNewOtp(savedNewOtp)
    if (savedStep) setCurrentStep(savedStep as AuthStep)
    if (savedUserExists !== null) setUserExists(savedUserExists)

    // Set focused state based on current values
    setIsFocused({
      identifier: !!savedIdentifier,
      password: false,
      otp: !!savedOtp,
      mobileOtp: false,
      emailOtp: false,
    })
  }, [])

  // Handle unified input change
  const handleUnifiedInputChange = (value: string, isEmailInput: boolean, countryCodeValue?: string) => {
    setIdentifier(value)
    setIsEmail(isEmailInput)
    if (countryCodeValue) {
      setCountryCode(countryCodeValue)
    }

    // Update focused state
    setIsFocused((prev) => ({
      ...prev,
      identifier: !!value,
    }))
  }

  // Handle mobile input change
  const handleMobileInputChange = (value: string, isEmailInput: boolean, countryCodeValue?: string) => {
    setMobileNumber(value)
    if (countryCodeValue) {
      setCountryCode(countryCodeValue)
    }
  }

  // Handle identifier submission (email or phone)
  const handleIdentifierSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    if (!identifier) {
      toast.error("Please enter an email or phone number")
      return
    }

    setIsLoading(true)

    try {
      const formattedIdentifier = isEmail ? identifier : countryCode + identifier.replace(/\D/g, "")

      const response = await Api.post("auth/initiate/", {
        identifier: formattedIdentifier,
      })

      const data = response.data

      if (data.success) {
        sessionStorage.setItem("modal_identifier", data.identifier)
        sessionStorage.setItem("modal_is_email", isEmail.toString())
        setUserExists(data.get_password)
        sessionStorage.setItem("modal_user_exists", data.get_password.toString())

        if (data.get_password) {
          // Directly go to password step for existing users
          setCurrentStep(AuthStep.PASSWORD)
          sessionStorage.setItem("modal_auth_step", AuthStep.PASSWORD)
        } else {
          // For new users, proceed with OTP verification
          toast.success(data.message || "OTP sent successfully")
          setCurrentStep(AuthStep.OTP_VERIFICATION)
          sessionStorage.setItem("modal_auth_step", AuthStep.OTP_VERIFICATION)
        }
      } else {
        toast.error(data.message || data.error || "Failed to send OTP")
      }
    } catch (error) {
      console.error("Error initiating auth:", error)
      toast.error("Server error. Please try again later.")
    } finally {
      setIsLoading(false)
    }
  }

  // Handle method selection (password or OTP)
  const handleMethodSelection = (method: string) => {
    if (method === "password") {
      setCurrentStep(AuthStep.PASSWORD)
      sessionStorage.setItem("modal_auth_step", AuthStep.PASSWORD)
    } else if (method === "otp") {
      // Send OTP for login
      handleSendLoginOtp()
    }
  }

  // Handle forgot password
  const handleForgotPassword = async () => {
    setIsLoading(true)
    try {
      const savedIdentifier = sessionStorage.getItem("modal_identifier")

      if (!savedIdentifier) {
        toast.error("No identifier found. Please try again.")
        return
      }

      const response = await fetch("https://dash.brandexperts.ae/send-otp/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          identifier: savedIdentifier,
        }),
      })

      const data = await response.json()

      if (data.message === "OTP sent successfully") {
        toast.success("OTP sent successfully")
        setForgotPasswordSessionToken(data.session_token)
        setCurrentStep(AuthStep.FORGOT_PASSWORD_OTP)
        sessionStorage.setItem("modal_auth_step", AuthStep.FORGOT_PASSWORD_OTP)
      } else {
        toast.error(data.message || "Failed to send OTP")
      }
    } catch (error) {
      console.error("Error sending forgot password OTP:", error)
      toast.error("Server error. Please try again later.")
    } finally {
      setIsLoading(false)
    }
  }

  // Handle forgot password OTP verification
  const handleForgotPasswordOtpVerify = async (e: React.FormEvent) => {
    e.preventDefault()
    if (!forgotPasswordOtp) {
      toast.error("Please enter the OTP")
      return
    }

    setIsLoading(true)
    try {
      const response = await fetch("https://dash.brandexperts.ae/verify-otp2/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          session_token: forgotPasswordSessionToken,
          otp: forgotPasswordOtp,
        }),
      })

      const data = await response.json()

      if (data.success) {
        toast.success(data.message || "OTP verified successfully")
        setForgotPasswordResetToken(data.reset_token)
        setCurrentStep(AuthStep.FORGOT_PASSWORD_RESET)
        sessionStorage.setItem("modal_auth_step", AuthStep.FORGOT_PASSWORD_RESET)
      } else {
        toast.error(data.message || "Invalid OTP")
      }
    } catch (error) {
      console.error("Error verifying forgot password OTP:", error)
      toast.error("Server error. Please try again later.")
    } finally {
      setIsLoading(false)
    }
  }

  // Handle password reset
  const handlePasswordReset = async (e: React.FormEvent) => {
    e.preventDefault()

    // Validate passwords
    if (!validateNewPassword(newPassword)) return
    if (!validateConfirmNewPassword(newPassword, confirmNewPassword)) return

    setIsLoading(true)
    try {
      const response = await fetch("https://dash.brandexperts.ae/reset-password/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          reset_token: forgotPasswordResetToken,
          new_password: newPassword,
          confirm_password: confirmNewPassword,
        }),
      })

      const data = await response.json()

      if (data.success) {
        toast.success(data.message || "Password reset successfully")
        // Return to password step for login
        setCurrentStep(AuthStep.PASSWORD)
        sessionStorage.setItem("modal_auth_step", AuthStep.PASSWORD)
        // Clear reset form data
        setForgotPasswordOtp("")
        setForgotPasswordSessionToken("")
        setForgotPasswordResetToken("")
        setNewPassword("")
        setConfirmNewPassword("")
      } else {
        toast.error(data.message || "Failed to reset password")
      }
    } catch (error) {
      console.error("Error resetting password:", error)
      toast.error("Server error. Please try again later.")
    } finally {
      setIsLoading(false)
    }
  }

  // Validate new password
  const validateNewPassword = (password: string) => {
    if (password.length < 8) {
      setNewPasswordError("Password must be at least 8 characters")
      return false
    }
    setNewPasswordError("")
    return true
  }

  // Validate confirm new password
  const validateConfirmNewPassword = (password: string, confirmPassword: string) => {
    if (password !== confirmPassword) {
      setConfirmNewPasswordError("Passwords do not match")
      return false
    }
    setConfirmNewPasswordError("")
    return true
  }

  // Handle send login OTP
  const handleSendLoginOtp = async () => {
    setIsLoading(true)
    try {
      const savedIdentifier = sessionStorage.getItem("modal_identifier")

      const response = await Api.post("auth/login-with-otp/", {
        identifier: savedIdentifier,
      })

      const data = response.data

      if (data.success) {
        toast.success(data.message || "OTP sent successfully")
        setCurrentStep(AuthStep.OTP_VERIFICATION)
        sessionStorage.setItem("modal_auth_step", AuthStep.OTP_VERIFICATION)
      } else {
        toast.error(data.message || data.error || "Failed to send OTP")
      }
    } catch (error) {
      console.error("Error sending login OTP:", error)
      toast.error("Server error. Please try again later.")
    } finally {
      setIsLoading(false)
    }
  }

  // Handle password login
  const handlePasswordLogin = async (e: React.FormEvent) => {
    e.preventDefault()
    if (!password) {
      toast.error("Please enter your password")
      return
    }

    setIsLoading(true)
    try {
      const savedIdentifier = sessionStorage.getItem("modal_identifier")

      const response = await Api.post("/login/", {
        identifier: savedIdentifier,
        password: password,
      })

      const data = response.data

      if (data.success) {
        handleLoginSuccess(data)
      } else {
        toast.error(data.message || data.error || "Invalid credentials")
      }
    } catch (error) {
      console.error("Error logging in:", error)
      toast.error("Server error. Please try again later.")
    } finally {
      setIsLoading(false)
    }
  }

  // Handle OTP verification for login/registration
  const handleOtpVerify = async (e: React.FormEvent) => {
    e.preventDefault()
    if (!otp) {
      toast.error("Please enter the OTP")
      return
    }

    setIsLoading(true)
    try {
      // Save OTP to session storage
      sessionStorage.setItem("modal_otp", otp)

      // Determine which API to call based on whether user exists
      const apiUrl = userExists ? "auth/otp-verify/" : "auth/otp-register-verify/"

      const response = await Api.post(apiUrl, {
        identifier: sessionStorage.getItem("modal_identifier"),
        otp: otp,
      })

      const data = response.data

      if (data.success) {
        toast.success(data.message || "OTP verified successfully")

        if (userExists) {
          // Existing user - login successful
          handleLoginSuccess(data)
        } else {
          // New user - continue registration flow
          // Save the verified OTP
          sessionStorage.setItem("modal_otp", data.otp)

          // Update verification data
          setVerificationData({
            getMobile: data.get_mobile,
            getEmail: data.get_email,
            verifiedType: data.verified_type || "",
            getPassword: false,
          })

          // Determine next step based on verification requirements
          if (data.get_mobile) {
            setCurrentStep(AuthStep.MOBILE_VERIFICATION)
            sessionStorage.setItem("modal_auth_step", AuthStep.MOBILE_VERIFICATION)
          } else if (data.get_email) {
            setCurrentStep(AuthStep.EMAIL_VERIFICATION)
            sessionStorage.setItem("modal_auth_step", AuthStep.EMAIL_VERIFICATION)
          } else {
            // If no additional verification needed, go to final registration
            setCurrentStep(AuthStep.FINAL_REGISTRATION)
            sessionStorage.setItem("modal_auth_step", AuthStep.FINAL_REGISTRATION)
          }
        }
      } else {
        toast.error(data.message || data.error || "Invalid OTP")
      }
    } catch (error) {
      console.error("Error verifying OTP:", error)
      toast.error("Server error. Please try again later.")
    } finally {
      setIsLoading(false)
    }
  }

  // Handle mobile verification
  const handleMobileVerification = async (e: React.FormEvent) => {
    e.preventDefault()
    if (!mobileNumber) {
      toast.error("Please enter your mobile number")
      return
    }

    setIsLoading(true)
    try {
      const savedOtp = sessionStorage.getItem("modal_otp")

      // Format mobile number - remove any non-digit characters
      const cleanMobileNumber = mobileNumber.replace(/\D/g, "")

      // Call API to update and send OTP to mobile
      const response = await Api.post("/auth/update-otp/", {
        otp: savedOtp,
        identifier: cleanMobileNumber,
        country_code: countryCode,
      })

      const data = response.data

      if (data.success) {
        toast.success(data.message || "OTP sent successfully to WhatsApp")
        // Store mobile number and country code
        sessionStorage.setItem("modal_mobile_number", cleanMobileNumber)
        sessionStorage.setItem("modal_country_code", countryCode)

        // Move to mobile OTP verification step
        setCurrentStep(AuthStep.MOBILE_OTP_VERIFICATION)
        sessionStorage.setItem("modal_auth_step", AuthStep.MOBILE_OTP_VERIFICATION)
      } else {
        toast.error(data.message || data.error || "Failed to send OTP")
      }
    } catch (error) {
      console.error("Error sending mobile OTP:", error)
      toast.error("Server error. Please try again later.")
    } finally {
      setIsLoading(false)
    }
  }

  // Handle mobile OTP verification
  const handleMobileOtpVerify = async (e: React.FormEvent) => {
    e.preventDefault()
    if (!mobileOtp) {
      toast.error("Please enter the OTP")
      return
    }

    setIsLoading(true)
    try {
      // Call API to verify mobile OTP
      const response = await Api.post("auth/verify-otp-final/", {
        otp: mobileOtp,
      })

      const data = response.data

      if (data.success) {
        toast.success(data.message || "OTP verified successfully for mobile")

        // Save the new OTP
        sessionStorage.setItem("modal_new_otp", data.new_otp)
        setNewOtp(data.new_otp)

        // Check if email verification is needed
        if (verificationData.getEmail) {
          setCurrentStep(AuthStep.EMAIL_VERIFICATION)
          sessionStorage.setItem("modal_auth_step", AuthStep.EMAIL_VERIFICATION)
        } else {
          // If no email verification needed, go to final registration
          setCurrentStep(AuthStep.FINAL_REGISTRATION)
          sessionStorage.setItem("modal_auth_step", AuthStep.FINAL_REGISTRATION)
        }
      } else {
        toast.error(data.message || data.error || "Invalid OTP")
      }
    } catch (error) {
      console.error("Error verifying mobile OTP:", error)
      toast.error("Server error. Please try again later.")
    } finally {
      setIsLoading(false)
    }
  }

  // Handle email verification
  const handleEmailVerification = async (e: React.FormEvent) => {
    e.preventDefault()
    if (!emailAddress) {
      toast.error("Please enter your email address")
      return
    }

    setIsLoading(true)
    try {
      const savedOtp = sessionStorage.getItem("modal_otp") || sessionStorage.getItem("modal_new_otp")

      // Call API to update and send OTP to email
      const response = await Api.post("/auth/update-otp/", {
        otp: savedOtp,
        identifier: emailAddress,
      })

      const data = response.data

      if (data.success) {
        toast.success(data.message || "OTP sent successfully to email")
        sessionStorage.setItem("modal_email_address", emailAddress)

        if (data.verification) {
          // If verification is true, show email OTP verification form
          sessionStorage.setItem("modal_new_otp", data.new_otp)
          setNewOtp(data.new_otp)
          setCurrentStep(AuthStep.EMAIL_OTP_VERIFICATION)
          sessionStorage.setItem("modal_auth_step", AuthStep.EMAIL_OTP_VERIFICATION)
        } else {
          // If verification is false, skip to final registration
          setCurrentStep(AuthStep.FINAL_REGISTRATION)
          sessionStorage.setItem("modal_auth_step", AuthStep.FINAL_REGISTRATION)
        }
      } else {
        toast.error(data.message || data.error || "Failed to send OTP")
      }
    } catch (error) {
      console.error("Error sending email OTP:", error)
      toast.error("Server error. Please try again later.")
    } finally {
      setIsLoading(false)
    }
  }

  // Handle verify later for email
  const handleVerifyLater = async () => {
    setIsLoading(true)
    try {
      const savedOtp = sessionStorage.getItem("modal_otp") || sessionStorage.getItem("modal_new_otp")

      // Call API to skip email verification
      const response = await Api.post("/auth/update-otp/", {
        otp: savedOtp,
      })

      const data = response.data

      if (data.success && !data.verification) {
        // Changed toast.info to toast (or the correct method based on your toast library)
        toast.success(data.message || "Email verification skipped")
        sessionStorage.setItem("modal_new_otp", data.new_otp)
        setNewOtp(data.new_otp)

        // Move to final registration
        setCurrentStep(AuthStep.FINAL_REGISTRATION)
        sessionStorage.setItem("modal_auth_step", AuthStep.FINAL_REGISTRATION)
      } else {
        toast.error(data.message || data.error || "Failed to skip verification")
      }
    } catch (error) {
      console.error("Error skipping email verification:", error)
      toast.error("Server error. Please try again later.")
    } finally {
      setIsLoading(false)
    }
  }

  // Handle email OTP verification
  const handleEmailOtpVerify = async (e: React.FormEvent) => {
    e.preventDefault()
    if (!emailOtp) {
      toast.error("Please enter the OTP")
      return
    }

    setIsLoading(true)
    try {
      // Call API to verify email OTP
      const response = await Api.post("auth/verify-otp-final/", {
        otp: emailOtp,
      })

      const data = response.data

      if (data.success) {
        toast.success(data.message || "OTP verified successfully for email")

        // Save the new OTP
        sessionStorage.setItem("modal_new_otp", data.new_otp)
        setNewOtp(data.new_otp)

        // Move to final registration
        setCurrentStep(AuthStep.FINAL_REGISTRATION)
        sessionStorage.setItem("modal_auth_step", AuthStep.FINAL_REGISTRATION)
      } else {
        toast.error(data.message || data.error || "Invalid OTP")
      }
    } catch (error) {
      console.error("Error verifying email OTP:", error)
      toast.error("Server error. Please try again later.")
    } finally {
      setIsLoading(false)
    }
  }

  // Validate password
  const validatePassword = (password: string) => {
    if (password.length < 8) {
      setPasswordError("Password must be at least 8 characters")
      return false
    }
    setPasswordError("")
    return true
  }

  // Validate confirm password
  const validateConfirmPassword = (password: string, confirmPassword: string) => {
    if (password !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match")
      return false
    }
    setConfirmPasswordError("")
    return true
  }

  // Handle final registration
  const handleFinalRegistration = async (e: React.FormEvent) => {
    e.preventDefault()

    // Validate form
    if (!userDetails.firstName || !userDetails.lastName) {
      toast.error("Please enter your first and last name")
      return
    }

    if (!validatePassword(userDetails.password)) return
    if (!validateConfirmPassword(userDetails.password, userDetails.confirmPassword)) return

    setIsLoading(true)
    try {
      const finalOtp = sessionStorage.getItem("modal_new_otp") || sessionStorage.getItem("modal_otp")

      // Call API to complete registration
      const response = await Api.post("/auth/final-register/", {
        otp: finalOtp,
        first_name: userDetails.firstName,
        last_name: userDetails.lastName,
        password: userDetails.password,
        confirm_password: userDetails.confirmPassword,
      })

      const data = response.data

      if (data.success) {
        handleLoginSuccess(data)
      } else {
        toast.error(data.message || data.error || "Registration failed")
      }
    } catch (error) {
      console.error("Error completing registration:", error)
      toast.error("Server error. Please try again later.")
    } finally {
      setIsLoading(false)
    }
  }

  // Handle login success
  const handleLoginSuccess = (data: any) => {
    toast.success(data.message || "Authentication successful")

    // Save tokens and user details
    localStorage.setItem("access_token", data.access_token)
    localStorage.setItem("refresh_token", data.refresh_token)
    sessionStorage.setItem("user_id", data.user_id)
    sessionStorage.setItem("customer_id", data.user_details.customer_id)

    const userDetailsToStore = {
      ...data.user_details,
      user_id: data.user_id,
      customer_id: data.user_details.customer_id,
    }

    localStorage.setItem("user_details", JSON.stringify(userDetailsToStore))

    // Store user data in the format needed by AuthContext
    const userData = {
      id: data.user_id,
      customer_id: data.user_details.customer_id,
      name: data.user_details.first_name || data.user_details.username,
      email: data.user_details.email,
      mobile: data.user_details.mobile,
      is_partner: data.user_details.is_partner || false,
    }

    localStorage.setItem("user_data", JSON.stringify(userData))
    login(userData)

    // Move to complete step
    setCurrentStep(AuthStep.COMPLETE)
    sessionStorage.setItem("modal_auth_step", AuthStep.COMPLETE)

    // Clear session storage after successful login/registration
    setTimeout(() => {
      clearModalSessionStorage()

      // Close modal and redirect to checkout
      closeModal()
      navigate("/checkout")
    }, 2000)
  }

  // Clear all modal-related session storage
  const clearModalSessionStorage = () => {
    sessionStorage.removeItem("modal_identifier")
    sessionStorage.removeItem("modal_country_code")
    sessionStorage.removeItem("modal_otp")
    sessionStorage.removeItem("modal_new_otp")
    sessionStorage.removeItem("modal_auth_step")
    sessionStorage.removeItem("modal_is_email")
    sessionStorage.removeItem("modal_mobile_number")
    sessionStorage.removeItem("modal_email_address")
    sessionStorage.removeItem("modal_user_exists")
  }

  // Handle resend OTP
  const handleResendOtp = async () => {
    setIsLoading(true)
    const savedIdentifier = sessionStorage.getItem("modal_identifier")

    try {
      const response = await Api.post("auth/initiate/", {
        identifier: savedIdentifier,
      })

      const data = response.data

      if (data.success) {
        toast.success("OTP resent successfully")
      } else {
        toast.error(data.message || data.error || "Failed to resend OTP")
      }
    } catch (error) {
      console.error("Error resending OTP:", error)
      toast.error("Server error. Please try again later.")
    } finally {
      setIsLoading(false)
    }
  }

  // Reset auth flow
  const resetAuthFlow = () => {
    clearModalSessionStorage()
    setIdentifier("")
    setOtp("")
    setCurrentStep(AuthStep.IDENTIFIER)
  }

  // Get page title based on current step
  const getPageTitle = () => {
    if (userExists) {
      // Login flow titles
      switch (currentStep) {
        case AuthStep.IDENTIFIER:
          return "Sign In"
        case AuthStep.PASSWORD:
          return "Enter Password"
        case AuthStep.OTP_VERIFICATION:
          return "Verify OTP"
        case AuthStep.COMPLETE:
          return "Login Successful"
        case AuthStep.FORGOT_PASSWORD_OTP:
          return "Forgot Password"
        case AuthStep.FORGOT_PASSWORD_RESET:
          return "Reset Password"
        default:
          return "Sign In"
      }
    } else {
      // Registration flow titles
      switch (currentStep) {
        case AuthStep.IDENTIFIER:
          return "Login or Register"
        case AuthStep.OTP_VERIFICATION:
          return "Verify OTP"
        case AuthStep.MOBILE_VERIFICATION:
          return "Verify Mobile Number"
        case AuthStep.MOBILE_OTP_VERIFICATION:
          return "Verify Mobile OTP"
        case AuthStep.EMAIL_VERIFICATION:
          return "Verify Email Address"
        case AuthStep.EMAIL_OTP_VERIFICATION:
          return "Verify Email OTP"
        case AuthStep.FINAL_REGISTRATION:
          return "Complete Your Profile"
        case AuthStep.COMPLETE:
          return "Registration Complete"
        default:
          return "Create an Account"
      }
    }
  }

  // Get page subtitle based on current step
  const getPageSubtitle = () => {
    if (userExists) {
      // Login flow subtitles
      switch (currentStep) {
        case AuthStep.IDENTIFIER:
          return "Enter your email or WhatsApp number"
        case AuthStep.PASSWORD:
          return "Enter your password to continue"
        case AuthStep.OTP_VERIFICATION:
          return "Enter the verification code sent to your device"
        case AuthStep.COMPLETE:
          return "You are now logged in"
        case AuthStep.FORGOT_PASSWORD_OTP:
          return "Enter the verification code sent to your device"
        case AuthStep.FORGOT_PASSWORD_RESET:
          return "Create a new password for your account"
        default:
          return "Enter your credentials"
      }
    } else {
      // Registration flow subtitles
      switch (currentStep) {
        case AuthStep.IDENTIFIER:
          return "Enter your email or WhatsApp number to get started"
        case AuthStep.OTP_VERIFICATION:
          return "Enter the verification code sent to your device"
        case AuthStep.MOBILE_VERIFICATION:
          return "We need to verify your mobile number"
        case AuthStep.MOBILE_OTP_VERIFICATION:
          return "Enter the verification code sent to your WhatsApp"
        case AuthStep.EMAIL_VERIFICATION:
          return "Verify your email address or skip for later"
        case AuthStep.EMAIL_OTP_VERIFICATION:
          return "Enter the verification code sent to your email"
        case AuthStep.FINAL_REGISTRATION:
          return "Just a few more details to complete your registration"
        case AuthStep.COMPLETE:
          return "Your account has been created successfully"
        default:
          return "Follow the steps to create your account"
      }
    }
  }

  return (
    <div className="space-y-4">
      <div className="text-center mb-6">
        <h2 className="text-2xl font-bold text-gray-900">{getPageTitle()}</h2>
        <p className="mt-2 text-sm text-gray-600">{getPageSubtitle()}</p>
      </div>

      {/* Step 1: Identifier Input */}
      {currentStep === AuthStep.IDENTIFIER && (
        <form onSubmit={handleIdentifierSubmit} className="space-y-6">
              {/* Tab selector */}
              <div className="flex rounded-lg p-1 bg-gray-100 gap-1">
                <button
                  type="button"
                  onClick={() => setSelectedTab("phone")}
                  className={`flex-1 py-2 text-sm font-medium rounded-lg transition-colors ${
                    selectedTab === "phone"
                      ? "bg-white text-red-600 shadow border border-red-200"
                      : "text-gray-500 hover:bg-gray-50"
                  }`}
                >
                  WhatsApp Number
                </button>
                <button
                  type="button"
                  onClick={() => setSelectedTab("email")}
                  className={`flex-1 py-2 text-sm font-medium rounded-lg transition-colors ${
                    selectedTab === "email"
                      ? "bg-white text-red-600 shadow border border-red-200"
                      : "text-gray-500 hover:bg-gray-50"
                  }`}
                >
                  Email
                </button>
              </div>
        
              {/* Phone number input */}
              {selectedTab === "phone" && (
                <div>
                  <label
                    htmlFor="phone"
                    className="block text-sm font-medium text-gray-700"
                  >
                    WhatsApp Number
                  </label>
                  <div className="mt-1">
                    <UnifiedAuthInput
                      value={identifier}
                      onChange={(value, isEmail, countryCode) => {
                        setIdentifier(value);
                        if (countryCode) setCountryCode(countryCode);
                      }}
                      placeholder="Enter WhatsApp number"
                      className="w-full"
                      forcePhone // Prop to force phone input mode
                    />
                  </div>
                </div>
              )}
        
              {/* Email input */}
              {selectedTab === "email" && (
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Email Address
                  </label>
                  <div className="mt-1">
                    <input
                      type="email"
                      id="email"
                      value={identifier}
                      onChange={(e) => setIdentifier(e.target.value)}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
                      placeholder="Enter email address"
                    />
                  </div>
                </div>
              )}
        
                      <div>
                        <button
                          type="submit"
                          disabled={isLoading || !identifier}
                          className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-50 disabled:cursor-not-allowed"
                        >
                          {isLoading ? (
                            <div className="flex items-center">
                              <Loader2 className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" />
                              Processing...
                            </div>
                          ) : (
                            "Continue"
                          )}
                        </button>
                      </div>
                    </form>
      )}

      {/* Step 2: Password Login */}
      {currentStep === AuthStep.PASSWORD && (
        <form onSubmit={handlePasswordLogin} className="space-y-6">
          <p className="text-sm text-gray-500 mb-4">Welcome back! Please login to continue</p>

          <div className="relative">
            <input
              id="password"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => {
                setPassword(e.target.value)
                setIsFocused((prev) => ({
                  ...prev,
                  password: !!e.target.value,
                }))
              }}
              onFocus={() => setIsFocused((prev) => ({ ...prev, password: true }))}
              onBlur={(e) =>
                setIsFocused((prev) => ({
                  ...prev,
                  password: !!e.target.value,
                }))
              }
              className="w-full px-4 py-3 border border-gray-300 rounded-md bg-transparent focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-transparent transition-all"
              required
            />
            <label
              htmlFor="password"
              className={`absolute left-4 transition-all duration-200 pointer-events-none ${
                isFocused.password ? "text-xs text-red-600 -top-2.5 bg-white px-1" : "text-gray-500 top-3"
              }`}
            >
              Password
            </label>
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-700 transition-colors"
            >
              {showPassword ? <Eye className="h-5 w-5" /> : <EyeOff className="h-5 w-5" />}
            </button>
          </div>

          <div className="flex items-center justify-between">
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
                className="w-4 h-4 rounded border-gray-300 text-red-600 focus:ring-red-500"
              />
              <span className="text-sm text-gray-500">Remember me</span>
            </label>
            <button
              type="button"
              className="text-sm text-red-600 hover:text-red-500 text-center"
              onClick={handleForgotPassword}
            >
              Forgot password?
            </button>
          </div>

          <div>
            <button
              type="submit"
              disabled={isLoading}
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              {isLoading ? (
                <div className="flex items-center">
                  <Loader2 className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" />
                  Signing in...
                </div>
              ) : (
                "Sign in"
              )}
            </button>
          </div>

          <div className="flex flex-col space-y-3 mt-4">
            <button
              type="button"
              onClick={() => handleMethodSelection("otp")}
              className="text-sm text-red-600 rounded-lg border py-2 border-red-200 hover:text-red-500 text-center hover:bg-red-50 transition delay-150 ease-in-out  hover:shadow-sm cursor-pointer"
            >
              Login with OTP
            </button>
           
          </div>

          <div className="mt-4">
            <button
              type="button"
              onClick={() => {
                setCurrentStep(AuthStep.IDENTIFIER)
                sessionStorage.setItem("modal_auth_step", AuthStep.IDENTIFIER)
              }}
              className="inline-flex items-center text-sm font-medium text-red-600 hover:text-red-500"
            >
              <ArrowLeft className="w-4 h-4 mr-1" />
              Back
            </button>
          </div>
        </form>
      )}

      {/* Step 3: OTP Verification */}
      {currentStep === AuthStep.OTP_VERIFICATION && (
        <form onSubmit={handleOtpVerify} className="space-y-6">
          <div>
            <label htmlFor="otp" className="block text-sm font-medium text-gray-700">
              Enter OTP
            </label>
            <div className="mt-1">
              <input
                type="text"
                id="otp"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
                placeholder="Enter OTP"
                required
              />
            </div>
            <p className="mt-2 text-sm text-gray-500">
              We've sent a one-time password to your {isEmail ? "email" : "WhatsApp"}
            </p>
          </div>

          <div className="flex items-center justify-between">
            <button
              type="button"
              onClick={() => {
                const previousStep = userExists ? AuthStep.PASSWORD : AuthStep.IDENTIFIER
                setCurrentStep(previousStep)
                sessionStorage.setItem("modal_auth_step", previousStep)
              }}
              className="inline-flex items-center text-sm font-medium text-red-600 hover:text-red-500"
            >
              <ArrowLeft className="w-4 h-4 mr-1" />
              Back
            </button>
            <button
              type="button"
              onClick={handleResendOtp}
              disabled={isLoading}
              className="text-sm font-medium text-red-600 hover:text-red-500"
            >
              Resend OTP
            </button>
          </div>

          <div>
            <button
              type="submit"
              disabled={isLoading}
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              {isLoading ? (
                <div className="flex items-center">
                  <Loader2 className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" />
                  Verifying...
                </div>
              ) : (
                "Verify OTP"
              )}
            </button>
          </div>
        </form>
      )}

      {/* Step 4a: Mobile Verification Form */}
      {currentStep === AuthStep.MOBILE_VERIFICATION && (
        <form onSubmit={handleMobileVerification} className="space-y-6">
          <div>
            <label htmlFor="mobile" className="block text-sm font-medium text-gray-700">
              Mobile Number
            </label>
            <div className="mt-1">
              <UnifiedAuthInput
                value={mobileNumber}
                onChange={handleMobileInputChange}
                placeholder="Enter mobile number"
                className="w-full"
              />
            </div>
            <p className="mt-2 text-sm text-gray-500">We'll send a verification code to this number</p>
          </div>

          <div className="flex items-center justify-between">
            <button
              type="button"
              onClick={() => {
                setCurrentStep(AuthStep.OTP_VERIFICATION)
                sessionStorage.setItem("modal_auth_step", AuthStep.OTP_VERIFICATION)
              }}
              className="inline-flex items-center text-sm font-medium text-red-600 hover:text-red-500"
            >
              <ArrowLeft className="w-4 h-4 mr-1" />
              Back
            </button>
          </div>

          <div>
            <button
              type="submit"
              disabled={isLoading || !mobileNumber}
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {isLoading ? (
                <div className="flex items-center">
                  <Loader2 className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" />
                  Sending...
                </div>
              ) : (
                "Send Verification Code"
              )}
            </button>
          </div>
        </form>
      )}

      {/* Step 4b: Mobile OTP Verification */}
      {currentStep === AuthStep.MOBILE_OTP_VERIFICATION && (
        <form onSubmit={handleMobileOtpVerify} className="space-y-6">
          <div>
            <label htmlFor="mobileOtp" className="block text-sm font-medium text-gray-700">
              Enter OTP
            </label>
            <div className="mt-1">
              <input
                type="text"
                id="mobileOtp"
                value={mobileOtp}
                onChange={(e) => setMobileOtp(e.target.value)}
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
                placeholder="Enter OTP"
                required
              />
            </div>
            <p className="mt-2 text-sm text-gray-500">We've sent a verification code to your WhatsApp</p>
          </div>

          <div className="flex items-center justify-between">
            <button
              type="button"
              onClick={() => {
                setCurrentStep(AuthStep.MOBILE_VERIFICATION)
                sessionStorage.setItem("modal_auth_step", AuthStep.MOBILE_VERIFICATION)
              }}
              className="inline-flex items-center text-sm font-medium text-red-600 hover:text-red-500"
            >
              <ArrowLeft className="w-4 h-4 mr-1" />
              Back
            </button>
            <button
              type="button"
              onClick={handleResendOtp}
              disabled={isLoading}
              className="text-sm font-medium text-red-600 hover:text-red-500"
            >
              Resend OTP
            </button>
          </div>

          <div>
            <button
              type="submit"
              disabled={isLoading}
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              {isLoading ? (
                <div className="flex items-center">
                  <Loader2 className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" />
                  Verifying...
                </div>
              ) : (
                "Verify OTP"
              )}
            </button>
          </div>
        </form>
      )}

      {/* Step 5a: Email Verification Form */}
      {currentStep === AuthStep.EMAIL_VERIFICATION && (
        <form onSubmit={handleEmailVerification} className="space-y-6">
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              Email Address
            </label>
            <div className="mt-1">
              <input
                type="email"
                id="email"
                value={emailAddress}
                onChange={(e) => setEmailAddress(e.target.value)}
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
                placeholder="Enter email address"
                required
              />
            </div>
            <p className="mt-2 text-sm text-gray-500">We'll send a verification code to this email</p>
          </div>

          <div className="flex items-center justify-between">
            <button
              type="button"
              onClick={() => {
                const previousStep = verificationData.getMobile
                  ? AuthStep.MOBILE_OTP_VERIFICATION
                  : AuthStep.OTP_VERIFICATION
                setCurrentStep(previousStep)
                sessionStorage.setItem("modal_auth_step", previousStep)
              }}
              className="inline-flex items-center text-sm font-medium text-red-600 hover:text-red-500"
            >
              <ArrowLeft className="w-4 h-4 mr-1" />
              Back
            </button>
            <button
              type="button"
              onClick={handleVerifyLater}
              className="text-sm font-medium text-red-600 hover:text-red-500"
            >
              Verify Later
            </button>
          </div>

          <div>
            <button
              type="submit"
              disabled={isLoading || !emailAddress}
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {isLoading ? (
                <div className="flex items-center">
                  <Loader2 className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" />
                  Sending...
                </div>
              ) : (
                "Send Verification Code"
              )}
            </button>
          </div>
        </form>
      )}

      {/* Step 5b: Email OTP Verification */}
      {currentStep === AuthStep.EMAIL_OTP_VERIFICATION && (
        <form onSubmit={handleEmailOtpVerify} className="space-y-6">
          <div>
            <label htmlFor="emailOtp" className="block text-sm font-medium text-gray-700">
              Enter OTP
            </label>
            <div className="mt-1">
              <input
                type="text"
                id="emailOtp"
                value={emailOtp}
                onChange={(e) => setEmailOtp(e.target.value)}
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
                placeholder="Enter OTP"
                required
              />
            </div>
            <p className="mt-2 text-sm text-gray-500">We've sent a verification code to your email</p>
          </div>

          <div className="flex items-center justify-between">
            <button
              type="button"
              onClick={() => {
                setCurrentStep(AuthStep.EMAIL_VERIFICATION)
                sessionStorage.setItem("modal_auth_step", AuthStep.EMAIL_VERIFICATION)
              }}
              className="inline-flex items-center text-sm font-medium text-red-600 hover:text-red-500"
            >
              <ArrowLeft className="w-4 h-4 mr-1" />
              Back
            </button>
            <button
              type="button"
              onClick={handleResendOtp}
              disabled={isLoading}
              className="text-sm font-medium text-red-600 hover:text-red-500"
            >
              Resend OTP
            </button>
          </div>

          <div>
            <button
              type="submit"
              disabled={isLoading}
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              {isLoading ? (
                <div className="flex items-center">
                  <Loader2 className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" />
                  Verifying...
                </div>
              ) : (
                "Verify OTP"
              )}
            </button>
          </div>
        </form>
      )}

      {/* Step 6: Final Registration */}
      {currentStep === AuthStep.FINAL_REGISTRATION && (
        <form onSubmit={handleFinalRegistration} className="space-y-6">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">
                First Name
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  id="firstName"
                  value={userDetails.firstName}
                  onChange={(e) =>
                    setUserDetails({
                      ...userDetails,
                      firstName: e.target.value,
                    })
                  }
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
                  required
                />
              </div>
            </div>
            <div>
              <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">
                Last Name
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  id="lastName"
                  value={userDetails.lastName}
                  onChange={(e) =>
                    setUserDetails({
                      ...userDetails,
                      lastName: e.target.value,
                    })
                  }
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
                  required
                />
              </div>
            </div>
          </div>

          <div>
            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
              Password
            </label>
            <div className="mt-1 relative">
              <input
                type={passwordVisible ? "text" : "password"}
                id="password"
                value={userDetails.password}
                onChange={(e) => {
                  setUserDetails({
                    ...userDetails,
                    password: e.target.value,
                  })
                  validatePassword(e.target.value)
                }}
                className={`appearance-none block w-full px-3 py-2 border ${
                  passwordError ? "border-red-500" : "border-gray-300"
                } rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm`}
                required
              />
              <button
                type="button"
                className="absolute inset-y-0 right-0 pr-3 flex items-center"
                onClick={() => setPasswordVisible(!passwordVisible)}
              >
                {passwordVisible ? (
                  <EyeOff className="h-5 w-5 text-gray-400" />
                ) : (
                  <Eye className="h-5 w-5 text-gray-400" />
                )}
              </button>
            </div>
            {passwordError && <p className="mt-1 text-sm text-red-500">{passwordError}</p>}
            <p className="mt-1 text-xs text-gray-500">Password must be at least 8 characters long</p>
          </div>

          <div>
            <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">
              Confirm Password
            </label>
            <div className="mt-1 relative">
              <input
                type={confirmPasswordVisible ? "text" : "password"}
                id="confirmPassword"
                value={userDetails.confirmPassword}
                onChange={(e) => {
                  setUserDetails({
                    ...userDetails,
                    confirmPassword: e.target.value,
                  })
                  validateConfirmPassword(userDetails.password, e.target.value)
                }}
                className={`appearance-none block w-full px-3 py-2 border ${
                  confirmPasswordError ? "border-red-500" : "border-gray-300"
                } rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm`}
                required
              />
              <button
                type="button"
                className="absolute inset-y-0 right-0 pr-3 flex items-center"
                onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}
              >
                {confirmPasswordVisible ? (
                  <EyeOff className="h-5 w-5 text-gray-400" />
                ) : (
                  <Eye className="h-5 w-5 text-gray-400" />
                )}
              </button>
            </div>
            {confirmPasswordError && <p className="mt-1 text-sm text-red-500">{confirmPasswordError}</p>}
          </div>

          <div className="flex items-center justify-between">
            <button
              type="button"
              onClick={() => {
                const previousStep = verificationData.getEmail
                  ? AuthStep.EMAIL_OTP_VERIFICATION
                  : verificationData.getMobile
                    ? AuthStep.MOBILE_OTP_VERIFICATION
                    : AuthStep.OTP_VERIFICATION
                setCurrentStep(previousStep)
                sessionStorage.setItem("modal_auth_step", previousStep)
              }}
              className="inline-flex items-center text-sm font-medium text-red-600 hover:text-red-500"
            >
              <ArrowLeft className="w-4 h-4 mr-1" />
              Back
            </button>
          </div>

          <div>
            <button
              type="submit"
              disabled={isLoading}
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              {isLoading ? (
                <div className="flex items-center">
                  <Loader2 className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" />
                  Registering...
                </div>
              ) : (
                "Complete Registration"
              )}
            </button>
          </div>
        </form>
      )}

      {/* Step 7: Complete */}
      {currentStep === AuthStep.COMPLETE && (
        <div className="text-center">
          <CheckCircle2 className="mx-auto h-12 w-12 text-green-600" />
          <h3 className="mt-2 text-lg font-medium text-gray-900">
            {userExists ? "Login successful!" : "Registration successful!"}
          </h3>
          <p className="mt-1 text-sm text-gray-500">You are now logged in. Redirecting to checkout...</p>
        </div>
      )}
      {/* Forgot Password OTP Verification */}
      {currentStep === AuthStep.FORGOT_PASSWORD_OTP && (
        <form onSubmit={handleForgotPasswordOtpVerify} className="space-y-6">
          <div>
            <label htmlFor="forgotPasswordOtp" className="block text-sm font-medium text-gray-700">
              Enter OTP
            </label>
            <div className="mt-1">
              <input
                type="text"
                id="forgotPasswordOtp"
                value={forgotPasswordOtp}
                onChange={(e) => setForgotPasswordOtp(e.target.value)}
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
                placeholder="Enter OTP"
                required
              />
            </div>
            <p className="mt-2 text-sm text-gray-500">We've sent a one-time password to reset your password</p>
          </div>

          <div className="flex items-center justify-between">
            <button
              type="button"
              onClick={() => {
                setCurrentStep(AuthStep.PASSWORD)
                sessionStorage.setItem("modal_auth_step", AuthStep.PASSWORD)
              }}
              className="inline-flex items-center text-sm font-medium text-red-600 hover:text-red-500"
            >
              <ArrowLeft className="w-4 h-4 mr-1" />
              Back
            </button>
            <button
              type="button"
              onClick={handleForgotPassword}
              disabled={isLoading}
              className="text-sm font-medium text-red-600 hover:text-red-500"
            >
              Resend OTP
            </button>
          </div>

          <div>
            <button
              type="submit"
              disabled={isLoading}
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              {isLoading ? (
                <div className="flex items-center">
                  <Loader2 className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" />
                  Verifying...
                </div>
              ) : (
                "Verify OTP"
              )}
            </button>
          </div>
        </form>
      )}

      {/* Forgot Password Reset Form */}
      {currentStep === AuthStep.FORGOT_PASSWORD_RESET && (
        <form onSubmit={handlePasswordReset} className="space-y-6">
          <div>
            <label htmlFor="newPassword" className="block text-sm font-medium text-gray-700">
              New Password
            </label>
            <div className="mt-1 relative">
              <input
                type={newPasswordVisible ? "text" : "password"}
                id="newPassword"
                value={newPassword}
                onChange={(e) => {
                  setNewPassword(e.target.value)
                  validateNewPassword(e.target.value)
                }}
                className={`appearance-none block w-full px-3 py-2 border ${
                  newPasswordError ? "border-red-500" : "border-gray-300"
                } rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm`}
                required
              />
              <button
                type="button"
                className="absolute inset-y-0 right-0 pr-3 flex items-center"
                onClick={() => setNewPasswordVisible(!newPasswordVisible)}
              >
                {newPasswordVisible ? (
                  <EyeOff className="h-5 w-5 text-gray-400" />
                ) : (
                  <Eye className="h-5 w-5 text-gray-400" />
                )}
              </button>
            </div>
            {newPasswordError && <p className="mt-1 text-sm text-red-500">{newPasswordError}</p>}
            <p className="mt-1 text-xs text-gray-500">Password must be at least 8 characters long</p>
          </div>

          <div>
            <label htmlFor="confirmNewPassword" className="block text-sm font-medium text-gray-700">
              Confirm New Password
            </label>
            <div className="mt-1 relative">
              <input
                type={confirmNewPasswordVisible ? "text" : "password"}
                id="confirmNewPassword"
                value={confirmNewPassword}
                onChange={(e) => {
                  setConfirmNewPassword(e.target.value)
                  validateConfirmNewPassword(newPassword, e.target.value)
                }}
                className={`appearance-none block w-full px-3 py-2 border ${
                  confirmNewPasswordError ? "border-red-500" : "border-gray-300"
                } rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm`}
                required
              />
              <button
                type="button"
                className="absolute inset-y-0 right-0 pr-3 flex items-center"
                onClick={() => setConfirmNewPasswordVisible(!confirmNewPasswordVisible)}
              >
                {confirmNewPasswordVisible ? (
                  <EyeOff className="h-5 w-5 text-gray-400" />
                ) : (
                  <Eye className="h-5 w-5 text-gray-400" />
                )}
              </button>
            </div>
            {confirmNewPasswordError && <p className="mt-1 text-sm text-red-500">{confirmNewPasswordError}</p>}
          </div>

          <div className="flex items-center justify-between">
            <button
              type="button"
              onClick={() => {
                setCurrentStep(AuthStep.FORGOT_PASSWORD_OTP)
                sessionStorage.setItem("modal_auth_step", AuthStep.FORGOT_PASSWORD_OTP)
              }}
              className="inline-flex items-center text-sm font-medium text-red-600 hover:text-red-500"
            >
              <ArrowLeft className="w-4 h-4 mr-1" />
              Back
            </button>
          </div>

          <div>
            <button
              type="submit"
              disabled={isLoading}
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              {isLoading ? (
                <div className="flex items-center">
                  <Loader2 className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" />
                  Resetting Password...
                </div>
              ) : (
                "Reset Password"
              )}
            </button>
          </div>
        </form>
      )}
    </div>
  )
}

