import Hsection1 from "./Hsection1";
import Hsection10 from "./Hsection10";
import Hsection2 from "./Hsection2";
import Hsection3 from "./Hsection3";
import Hsection4 from "./Hsection4";
import Hsection5 from "./Hsection5";
import Hsection6 from "./Hsection6";
import Hsection7 from "./Hsection7";
import Hsection8 from "./Hsection8";
import Hsection9 from "./Hsection9";
import Hsection11 from "./Hsection11";
import Hsection12 from "./Hsection12";
import Hsection13 from "./Hsection13";

const Home = () => {
  return (
    <div>
      <Hsection1 />
      <Hsection2 />
      <Hsection3 />
      <Hsection4 />
      <Hsection5 />
      <Hsection6 />
      <Hsection7 />
      <Hsection8 />
      <Hsection9 />
      <Hsection10 />
      <Hsection11 />
      <Hsection12 />
      <Hsection13 />
    </div>
  );
};

export default Home;