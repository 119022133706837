import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { ShoppingCart, Clock, Star } from "lucide-react";
import API from "../../src/pages/loginSignin/Api";

// Function to fetch designer rates
const fetchDesignerRates = async () => {
  const { data } = await API.get("dash/designer-rates/");
  return data;
};

// Transform API data to match the UI requirements
const transformDesignerRates = (rates) => {
  return rates.map((rate) => ({
    id: rate.id,
    hours: rate.hours,
    rate: Number(rate.amount),
    title: rate.hours === 1 ? "Premium Design" : 
           rate.hours === 2 ? "Basic Design" : "Standard Design",
    description: rate.hours === 1 ? "Complete design solution with unlimited revisions" :
                rate.hours === 2 ? "Perfect for simple designs and minor customizations" :
                "Ideal for detailed designs with multiple revisions",
    deliveryTime: rate.hours === 1 ? "5-7 days" :
                 rate.hours === 2 ? "2-3 days" : "3-5 days",
    recommended: rate.id === 2, // Standard package is recommended
    rateType: rate.rate_type,
    rateValue: rate.rate_value
  }));
};


export default function HireDesigner() {
  const [designRequirements, setDesignRequirements] = useState("");
  const [selectedPackage, setSelectedPackage] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const productDetails = location.state?.productDetails;

  // Fetch designer rates using React Query
  const { data: designerRates, isLoading, isError } = useQuery({
    queryKey: ["designerRates"],
    queryFn: fetchDesignerRates,
    select: transformDesignerRates,
    onSuccess: (data) => {
      // Set the standard package as default if no package is selected
      if (!selectedPackage) {
        const standardPkg = data.find(pkg => pkg.hours === 4);
        if (standardPkg) {
          setSelectedPackage(standardPkg.id);
        }
      }
    }
  });

  if (!productDetails) {
    navigate("/products");
    return null;
  }

  if (isLoading) {
    return (
      <div className="max-w-5xl mx-auto px-4 py-8">
        <div className="bg-white rounded-xl shadow-lg p-6">
          <div className="animate-pulse space-y-4">
            <div className="h-8 bg-gray-200 rounded w-1/4"></div>
            <div className="h-32 bg-gray-200 rounded"></div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              {[1, 2, 3].map(i => (
                <div key={i} className="h-64 bg-gray-200 rounded"></div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (isError) {
    return (
      <div className="max-w-5xl mx-auto px-4 py-8">
        <div className="bg-white rounded-xl shadow-lg p-6">
          <div className="text-center text-red-600">
            <h2 className="text-xl font-semibold">Error loading designer packages</h2>
            <p className="mt-2">Please try again later</p>
          </div>
        </div>
      </div>
    );
  }

  const currentPackage = designerRates.find(
    (pkg) => pkg.id === selectedPackage
  );
  const finalTotal = currentPackage 
    ? Number(productDetails.total) + Number(currentPackage.rate)
    : Number(productDetails.total);

  const handleAddToCart = () => {
    if (!designRequirements.trim()) {
      alert("Please provide design details for the designer.");
      return;
    }

    const defaultDesignImage = "https://st4.depositphotos.com/17828278/24401/v/450/depositphotos_244011872-stock-illustration-image-vector-symbol-missing-available.jpg";

    const cartItem = {
      id: productDetails.id,
      name: productDetails.name,
      customSize: productDetails.customSize,
      measurementUnit: productDetails.measurementUnit,
      quantity: productDetails.quantity,
      standardSize: productDetails.standardSize,
      design_note: designRequirements,
      productTotal: productDetails.total,
      designerFee: currentPackage.rate,
      total: finalTotal,
      designer_package: {
        ...currentPackage,
        id: currentPackage.id,
        rate_type: currentPackage.rateType,
        rate_value: currentPackage.rateValue
      },
      timestamp: Date.now(),
      design_image: defaultDesignImage,
    };

    const existingCart = JSON.parse(
      sessionStorage.getItem("cart") || '{"customer_id": 2, "cart_items": []}'
    );
    existingCart.cart_items.push(cartItem);
    sessionStorage.setItem("cart", JSON.stringify(existingCart));
    window.dispatchEvent(new Event("cartUpdated"));
    navigate("/cart");
  };

  return (
    <div className="max-w-5xl mx-auto px-4 py-8">
      <div className="bg-white rounded-xl shadow-lg p-6 space-y-8">
        <h1 className="text-3xl font-bold text-gray-900">Hire a Designer</h1>

        {/* Product Details */}
        <div className="bg-gray-50 rounded-lg p-6">
          <h3 className="font-semibold text-xl text-gray-900">
            {productDetails.name}
          </h3>
          <div className="mt-4 space-y-2 text-gray-600">
            <p>
              Size:{" "}
              {productDetails.standardSize ||
                `${productDetails.customSize.width}x${productDetails.customSize.height} ${productDetails.measurementUnit}`}
            </p>
            <p>Quantity: {productDetails.quantity}</p>
            <p className="text-lg font-medium mt-3">
              Product Cost: {productDetails.total} AED
            </p>
          </div>
        </div>

        {/* Designer Packages */}
        <div className="space-y-4">
          <h2 className="text-xl font-semibold text-gray-900">
            Select Designer Package
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            {designerRates.map((pkg) => (
              <div
                key={pkg.id}
                onClick={() => setSelectedPackage(pkg.id)}
                className={`relative rounded-lg border-2 p-4 cursor-pointer transition-all
                  ${
                    selectedPackage === pkg.id
                      ? "border-red-600 bg-red-50"
                      : "border-gray-200"
                  }`}
              >
                <div className="space-y-3">
                  <div className="flex items-center justify-between">
                    <span className="font-semibold text-lg">{pkg.title}</span>
                    {pkg.recommended && (
                      <span className="bg-red-100 text-red-600 text-xs px-2 py-1 rounded-full">
                        Recommended
                      </span>
                    )}
                  </div>
                  <p className="text-2xl font-bold text-gray-900">
                    {pkg.rate} AED
                  </p>
                  <div className="space-y-2 text-sm text-gray-600">
                    <p className="flex items-center gap-2">
                      <Clock size={16} />
                      {pkg.rateType === 'day' ? '8 hours (1 day)' : `${pkg.hours} hours`} of design work
                    </p>
                    <p className="flex items-center gap-2">
                      <Star size={16} />
                      {pkg.deliveryTime} delivery
                    </p>
                  </div>
                  <p className="text-sm text-gray-500">{pkg.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Design Requirements */}
        <div className="space-y-4">
          <h2 className="text-xl font-semibold text-gray-900">
            Design Requirements
          </h2>
          <textarea
            className="w-full border border-gray-300 rounded-lg p-4 min-h-[120px] focus:outline-none focus:ring-2 focus:ring-red-600 focus:border-transparent"
            placeholder="Describe how you want your design to look... Share any specific requirements, preferences, or inspiration for the designer."
            value={designRequirements}
            onChange={(e) => setDesignRequirements(e.target.value)}
          />
        </div>

        {/* Total Cost */}
        <div className="bg-gray-50 p-6 rounded-lg space-y-2">
          <div className="flex justify-between text-lg">
            <span>Product Cost:</span>
            <span>{productDetails.total} AED</span>
          </div>
          <div className="flex justify-between text-lg text-red-600">
            <span>Designer Fee:</span>
            <span>{currentPackage?.rate || 0} AED</span>
          </div>
          <div className="flex justify-between text-xl font-bold pt-2 border-t">
            <span>Final Total:</span>
            <span>{finalTotal} AED</span>
          </div>
        </div>

        {/* Action Buttons */}
        <div className="flex gap-4">
          <button
            onClick={() => navigate(-1)}
            className="px-6 py-4 text-sm border border-gray-300 rounded-md hover:bg-gray-50 transition-colors cursor-pointer"
          >
            Back
          </button>
          <button
            onClick={handleAddToCart}
            className="px-8 py-4 bg-gradient-to-r from-red-600 to-red-800 text-white rounded-xl flex items-center justify-center gap-2 hover:-translate-y-1 transition-all duration-300 hover:shadow-lg cursor-pointer"
          >
            <ShoppingCart size={20} />
            Add to Cart
          </button>
        </div>
      </div>
    </div>
  );
}