import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import React, { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import TagManager from "react-gtm-module";
import { AuthProvider } from "./context/AuthContext";
import { CartProvider } from "./context/CartContext.jsx"; // Update extension
import { ModalProvider } from "./context/ModalContext";
import AuthRoute from "./context/AuthRoute.jsx"
import Layout from "./components/LayOut";
import Home from "./pages/home/Home";
import Templates from "./pages/betemplates/Templates";
import Cart from "./components/Cart";
import Register from "./pages/loginSignin/Register.tsx";
import Login from "./pages/loginSignin/Login.tsx";
import Warranty from "./pages/warranty/Warranty";
import Products from "./pages/products/Products";
import ProductDetail from "./components/ProductDetail";
import DesignUpload from "./components/DesignUpload";
import HireDesigner from "./components/HireDesigner.jsx";
import WarrantyClaim from "./pages/warranty/WarrantyClaim";
import CategoryPage from "./pages/CategoryPage"; // You'll need to create this
import { ErrorBoundary } from "./components/ErrorBoundary";
import AuthModal from "./components/AuthModal";
import ProtectedRoute from "./components/ProtectedRoute";
import Checkout from "./pages/checkout/Checkout";
import Orders from "./pages/orders/Orders";
import OrderDetails from "./pages/orders/OrderDetails";
import PaymentSuccess from "./pages/checkout/PaymentSuccess";
import { Elements } from "@stripe/react-stripe-js";
import stripePromise from "./utils/stripe";
import PaymentPage from "./pages/payment/PaymentPage";
import OrderConfirmation from "./pages/checkout/components/OrderConfirmation.tsx";
import WarrantyPayamentPage from "./pages/warranty/WarrantyPaymentPage";
import PartnerDashboard from "./pages/dashboard/PartnerDashboard.jsx";
import UserDashboard from "./pages/dashboard/UserDash"
import ScrollToTop from "./components/ScrollTop.jsx";
import Otp from "./pages/loginSignin/Otp.jsx";
import SavedRFQs from "./pages/cart/SavedRFQ.jsx";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TermsOfus from "./pages/footer/TermsOfus.jsx";
import PrivacyAndPolicies from "./pages/footer/PrivacyAndPolicies.jsx";
import ReturnPolicies from "./pages/footer/ReturnPolicies.jsx";
import ShippingPolicies from "./pages/footer/ShippingPolicies.jsx";
const queryClient = new QueryClient();

const App = () => {
  useEffect(() => {
    TagManager.initialize({ gtmId: "GTM-P9SJV6SG" }); 
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <CartProvider>
          <ModalProvider>
            <ErrorBoundary>
              <Router>
                <ScrollToTop />{" "}
                {/* this will automatically scroll to top when you navigate to a new page */}
                <Elements stripe={stripePromise}>
                  <div className="overflow-hidden">
                    <Routes>
                      <Route element={<Layout />}>
                        <Route path="/" element={<Home />} />
                        <Route path="/cart" element={<Cart />} />
                        
                        <Route path="/login" 
                        element= {
                        <AuthRoute>
                          <Register />
                        </AuthRoute>
                        } />


                        <Route path="/login1" 
                        element={
                        <AuthRoute>
                          <Login />
                        </AuthRoute>
                        } />

                        <Route path="/otp" element={<Otp />} />
                        <Route path="/templates" element={<Templates />} />
                        <Route path="/amc-coverage" element={<Warranty />} />
                        <Route path="/products" element={<Products />} />
                        <Route path="/terms-of-use" element={<TermsOfus />} />
                        <Route
                          path="/return-policy"
                          element={<ReturnPolicies />}
                        />
                        <Route
                          path="/shipping-policy"
                          element={<ShippingPolicies />}
                        />
                        <Route
                          path="/privacy-policy"
                          element={<PrivacyAndPolicies />}
                        />
                        <Route
                          path="/product/:id"
                          element={<ProductDetail />}
                        />
                        <Route
                          path="/profile"
                          element={<Navigate to="/profile/details" />}
                        />
                        <Route
                          path="/orders"
                          element={
                            <ProtectedRoute>
                              <Orders />
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path="/order-details/:orderId"
                          element={
                            <ProtectedRoute>
                              <OrderDetails />
                            </ProtectedRoute>
                          }
                        />

                        {/* <Route
                          path="/my-designs"
                          element={<h1>My Designs</h1>}
                        /> */}
                        {/* <Route
                          path="/address-book"
                          element={<h1>Address Book</h1>}
                        /> */}

                        <Route
                          path="/email-notifications"
                          element={<h1>Estimates</h1>}
                        />
                        <Route
                          path="/partner-dashboard"
                          element={<PartnerDashboard />}
                        />
                        <Route
                          path="/user-dashboard"
                          element={<UserDashboard />}
                        />

                        <Route
                          path="/design-upload"
                          element={<DesignUpload />}
                        />
                        <Route
                          path="/hire-designer"
                          element={<HireDesigner />}
                        />
                        <Route
                          path="/warranty-claim/:warrantyNumber"
                          element={<WarrantyClaim />}
                        />
                        <Route
                          path="/category/:categoryId"
                          element={<CategoryPage />}
                        />
                        <Route
                          path="/checkout"
                          element={
                            <ProtectedRoute>
                              <Checkout />
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path="/payment"
                          element={
                            <ErrorBoundary>
                              <PaymentPage />
                            </ErrorBoundary>
                          }
                        />
                        <Route
                          path="/order-confirmation"
                          element={
                            <ProtectedRoute>
                              <OrderConfirmation />
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path="/payment-success"
                          element={
                            <ProtectedRoute>
                              <PaymentSuccess />
                            </ProtectedRoute>
                          }
                        />

                        {/* warranty-payment page connection */}
                        <Route
                          path="/warranty-payment"
                          element={<WarrantyPayamentPage />}
                        />
                        <Route path="/saved-rfqs" element={<SavedRFQs />} />
                      </Route>
                    </Routes>
                    <ToastContainer position="top-right" autoClose={3000} />

                    <AuthModal />
                  </div>
                </Elements>
              </Router>
            </ErrorBoundary>
          </ModalProvider>
        </CartProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
};

export default App;